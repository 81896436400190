/*-----------------------------------------------------------------------------------

Template Name:Unice Theme
Template URI: http://themes.pixelstrap.com/Unice
Description: Sass Landing Page
Author: Pixelstrap
Author URI: https://themeforest.net/user/pixelstrap

-----------------------------------------------------------------------------------
1. general css start
2. brand css start
3. team css start
4. pricing css start
5. screenshot section start
6. download section start
7. about section start
8. format section start
9. service section start
10. subscribe section start
11. navigations section start
12. copyright section start
13.  section start
14. footer section start
15. video section start
16. booking section start
17. app2 common css start
18. event common css start
19. extra css start
20. gym common css start
21. music-theme css start
22. resume-theme css start
23. wedding-theme css start
24. yoga-theme css start
25. agency css start
26. gallery  css start
27. blog css start
28. counter css start
29. contact css start
30. schedule css start
31. sponsor css start
32. testimonial css start
33. tap to top css start
34. music player css start
35. saas1 css start
36. saas2 css start
37. wedding css start
38. event css start
39. gym css start
40. popup css start
41. trainer slider css start
42. breadcrumb-section css start
43. breadcrumb type css end
44. blog-section css start
45. error css start
46. Filter css start
47. Portfolio css start
48. Single-blog Sec start
49. compare start
50. Typography Start
51. register Start
52. footer start
53. compare start
54. Rtl css start
55. Responsive Start

*/
/**************************
1. general css start
*************************/
::selection {
  background-color: $primary;
  color: $white;
}
body {
  font-family: 'Poppins', sans-serif;
  position: relative;
  font-size: 14px;
  color: $secondary;
  overflow-x: hidden;
  &.event {
    font-family: 'Work Sans', sans-serif;
  }
}
h1, h2 {
  font-family: 'Capriola', sans-serif;
  text-transform: capitalize;
}
h4, h5, li {
  margin-top: 0;
  margin-bottom: 0;
}
h1, h2, h3, h4, h5, h6 {
  text-transform: capitalize;
}
h2, h3, h4, h5, h6, li, a {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  line-height: normal;
}
h2, h3, h4 {
  color: $primary;
}
h5, h6, p {
  color: $secondary;
}
h1 {
  font-size: calc(22px + (50 - 22) * ((100vw - 300px) / (1920 - 300)));
  color: $white;
  font-weight: 400;
  letter-spacing: 2px;
  margin-top: -7px;
  margin-bottom: -20px;
  line-height: 1.4;
  span {
    &.small-text {
      font-size: calc(18px + (40 - 18) * ((100vw - 300px) / (1920 - 300)));
      color: $white;
    }
    &.bold-text {
      font-weight: 600;
    }
    &.theme-color {
      color: $primary;
    }
  }
}
h2 {
  font-size: calc(20px + (36 - 20) * ((100vw - 300px) / (1920 - 300)));
  line-height: 0.9;
}
h3 {
  font-size: calc(18px + (26 - 18) * ((100vw - 300px) / (1920 - 300)));
  margin-top: -4px;
  margin-bottom: 0;
}
h4 {
  font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1920 - 300)));
}
h5 {
  font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1920 - 300)));
}
h6 {
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1920 - 300)));
  margin-top: -4px;
  margin-bottom: -5px;
}
p {
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1920 - 300)));
  color: $secondary;
  font-weight: 400;
  margin-top: -7px;
  margin-bottom: -4px;
  line-height: 1.8;
}
body{
  header.fixed {
    position: sticky;
    top: 0;
    left: 0;
    transition: all 0.5s ease;
    z-index: 9;
    nav{
      padding: 0px 0;
    }  
  }
}
a {
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1920 - 300)));
  color: $secondary;
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
}
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  li {
    font-size: 14px;
  }
}
.custom-scroll {
  ::-webkit-scrollbar {
    width: 8px;
    background-color: $gray13;
    border-radius: 25px;
  }
  ::-webkit-scrollbar-thumb {
    background-image: linear-gradient(rgba($light, 0.92) 0%, $dark 100%);
    border-radius: 25px;
  }
}
.lable-nav {
  background-color: $white;
  padding: 3px 9px;
  display: inline-block;
  position: absolute;
  top: -4px;
  letter-spacing: 1px;
  font-size: 10px;
  margin-left: 0;
  color: $secondary;
  line-height: 1;
  text-transform: capitalize;
  &:before{
    content: "";
    position: absolute;
    height: 5px;
    width: 5px;
    background-color: $white;
    z-index: -1;
    top: 14px;
    transform: rotate(45deg);
    left: 8px;
  }
}
@keyframes scaleDisappear {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  60% {
    opacity: 0;
    transform: scale(1.3);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.abs-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.img-50 {
  height: 50px;
}
.p-t-0 {
  padding-top: 0 !important;
}
.p-t-10 {
  padding-top: 10px;
}
.p-t-15 {
  padding-top: 15px;
}
.p-t-20 {
  padding-top: 20px;
}
.p-t-30 {
  padding-top: 30px;
}
.p-t-40 {
  padding-top: 40px;
}
.p-t-50 {
  padding-top: 50px;
}
.p-t-100 {
  padding-top: 100px !important;
}
.p-b-100 {
  padding-bottom: 100px !important;
}
.p-b-0 {
  padding-bottom: 0 !important;
}
.p-b-10 {
  padding-bottom: 10px;
}
.p-b-15 {
  padding-bottom: 15px;
}
.p-b-20 {
  padding-bottom: 20px;
}
.p-b-30 {
  padding-bottom: 30px;
}
.p-b-40 {
  padding-bottom: 40px;
}
.p-b-50 {
  padding-bottom: 50px;
}
.p-b-100 {
  padding-bottom: 100px;
}
.p-b-150 {
  padding-bottom: 150px;
}
.p-b-160{
  padding-bottom: 160px;
}
.p-l-0 {
  padding-left: 0;
}
.p-l-20 {
  padding-left: 20px;
}
.p-r-0 {
  padding-right: 0 !important;
}
.m-r-0 {
  margin-right: 0;
}
.m-r-5 {
  margin-right: 5px;
}
.m-r-10 {
  margin-right: 10px;
}
.m-r-12 {
  margin-right: 12px;
}
.m-r-15 {
  margin-right: 15px;
}
.m-r-25 {
  margin-right: 25px;
}
.m-r-auto {
  margin-right: auto !important;
}
.m-l-0 {
  margin-left: 0 !important;
}
.m-l-5 {
  margin-left: 5px;
}
.m-l-15 {
  margin-left: 15px;
}
.m-l-24 {
  margin-left: 24px;
}
.m-l-30 {
  margin-left: 30px;
}
.m-l-auto {
  margin-left: auto;
}
.m-t-0 {
  margin-top: 0;
}
.m-t-10 {
  margin-top: 10px;
}
.m-t-5 {
  margin-top: 5px;
}
.m-t-15 {
  margin-top: 15px;
}
.m-t-20 {
  margin-top: 20px !important;
}
.m-t-25 {
  margin-top: 25px;
}
.m-t-30 {
  margin-top: 30px;
}
.m-t-40 {
  margin-top: 40px;
}
.m-t-45 {
  margin-top: 45px;
}
.m-t-50 {
  margin-top: 50px;
}
.m-b-0 {
  margin-bottom: 0 !important;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-b-5 {
  margin-bottom: 5px;
}
.m-b-15 {
  margin-bottom: 15px;
}
.m-b-20 {
  margin-bottom: 20px !important;
}
.m-b-25 {
  margin-bottom: 25px;
}
.m-b-30 {
  margin-bottom: 30px;
}
.m-b-35 {
  margin-bottom: 35px;
}
.m-b-40 {
  margin-bottom: 40px;
}
.m-b-45 {
  margin-bottom: 45px;
}
.m-b-50 {
  margin-bottom: 50px;
}
.m-b-80 {
  margin-bottom: 80px;
}
.bottom-0 {
  bottom: 0;
}
.right-0 {
  right: 0;
}
.left-0 {
  left: 0;
}
.top-0 {
  top: 0;
}
.flex-none {
  flex: none;
}
.p-light {
  font-weight: 300;
  opacity: 0.9;
}
.form-group {
  input, textarea {
    font-size: 15px;
    background-color: $bg-light;
    border: none;
    width: 100%;
    &:focus {
      outline: none;
    }
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      font-weight: 500;
      color: rgba(41, 39, 49, 0.302);
    }
    &::-moz-placeholder { /* Firefox 19+ */
      font-weight: 500;
      color: rgba(41, 39, 49, 0.302);
    }
    &:-ms-input-placeholder { /* IE 10+ */
      font-weight: 500;
      color: rgba(41, 39, 49, 0.302);
    }
    &:-moz-placeholder { /* Firefox 18- */
      font-weight: 500;
      color: rgba(41, 39, 49, 0.302);
    }
    &.radius-0 {
      border-radius: 0;
    }
  }
  textarea {
    padding: 16px;
  }
}
.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
}
.text-black {
  color: $black;
}
.font-primary {
  color: $primary !important;
}
.font-secondary {
  color: $secondary !important;
}
.set-abs {
  position: absolute;
}
.set-relative {
  position: relative;
}
section {
  padding: 100px 0;
  position: relative;
  overflow: hidden;
}
.center-text {
  height: 100%;
  align-items: center;
  display: flex;
  position: relative;
  z-index: 2;
}
.text-as-shadow{
  position: absolute;
  font-size: 150px !important;
  opacity: 0.03;
  left: 250px;
  line-height: 1 !important;
  font-weight: 700 !important;
  top: 0;
}
.owl-carousel {
  &.owl-theme {
    .owl-dots {
      line-height: 0;
      .owl-dot {
        span {
          margin-bottom: 0;
        }
      }
    }
  }
}
.menu-on {
  header {
    background-color: $light;
  }
}
.header {
  .header-text {
    margin-bottom: 45px;
  }
  .header-sub-text {
    margin-bottom: 45px;
    p {
      font-size: calc(13px + (16 - 13) * ((100vw - 300px) / (1920 - 300)));
      font-weight: 300;
    }
  }
}
.title {
  text-align: center;
  margin-bottom: 50px;
  .title-img {
    margin-bottom: 30px;
  }
  .main-title {
    margin-bottom: 25px;
    h2 {
      line-height: 0.8;
    }
  }
  .sub-title {
    margin-bottom: 0;
    &.para {
      font-size: 120%;
    }
  }
  &.title2 {
    .sub-title {
    }
    h6 {
      &.main-text {
        text-transform: capitalize;
        text-align: center;
        margin-bottom: 20px;
      }
    }
    .para {
      font-size: 128%;
      padding: 0 20px;
    }
    .borders {
      span {
        display: inline-block;
        position: relative;
        &:before,
        &:after {
          content: "";
          position: absolute;
          border-top: 2px solid $border-light;
          width: 85px;
          transform: translate(0, -50%);
          top: 50%;
        }
        &:before {
          right: 100%;
          margin-right: 15px;
        }
        &:after {
          left: 100%;
          margin-left: 15px;
        }
      }
    }
  }
  &.title3 {
    .main-title {
      margin-bottom: 30px;
      font-weight: 700;
      h2 {
      }
      span {
        color: $primary;
      }
    }
    .sub-title {
      font-weight: 300;
      span {
        font-weight: 600;
      }
    }
  }
}
.borders-before {
  margin-left: 60px;
  margin-bottom: 25px;
  text-transform: uppercase;
  span {
    display: inline-block;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      border-top: 2px solid $border-light;
      width: 46px;
      transform: translate(0, -50%);
      top: 50%;
    }
    &:before {
      right: 100%;
      margin-right: 15px;
    }
    &.color-white {
      &:before {
        border-top: 2px solid $white;
      }
    }
  }
}
.bg-theme {
  background: linear-gradient($light, $dark);
}
.center-abs {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.shadow {
  box-shadow: 0 4px 8px 1px $shadow-light;
}
.link-horizontal {
  ul {
    display: flex;
    li {
      padding-right: 35px;
      &.borders-right {
        border-right: 1px solid $white;
        height: 52px;
        margin-right: 35px;
        box-shadow: 0 0 10px 0 white;
        opacity: 0.7;
      }
      &:last-child {
        padding-right: 0;
      }
      a {
        background-color: transparent;
        &.icon-btn {
          i {
            font-size: 27px;
            height: 58px;
            width: 58px;
            color: $primary;
            display: flex;
            border: 2px solid $white;
            background-color: $white;
            border-radius: 50%;
            margin-bottom: 10px;
            transition: all 500ms;
            background-image: linear-gradient(to bottom, transparent 0%, transparent 50%, $primary 50%, $primary 100%);
            background-repeat: no-repeat;
            background-position: 0 0;
            background-size: 90px 180px;
          }
          &:hover {
            i {
              color: $white;
              background-position: 0 -90px;
            }
          }
        }
      }
    }
  }
}
.btn-default {
  padding: 14px 35px;
  font-size: calc(13px + (16 - 13) * ((100vw - 300px) / (1920 - 300)));
  font-weight: 500;
  border: 2px solid;
  text-transform: capitalize;
  border-radius: 50px;
  color: $white !important;
  transition: 0.5s;
  letter-spacing: 0;
  &:hover, &:focus, &:active {
    transition: 0.5s;
    background-color: $white;
    border: 2px solid $primary;
    color: $primary !important;
  }
  &:focus {
    box-shadow: none;
  }
  &.primary-btn {
    border: 1px solid white;
    background-color: $primary;
    &.transparent {
      background-color: transparent;
    }
    &:hover, &:focus, &:active {
      background-color: $white;
      color: $primary !important;
      border: 1px solid $primary;
    }
  }
  &.btn-white {
    transition: 0.5s;
    background-color: $white;
    border: 2px solid $primary;
    color: $primary !important;
    &:hover, &:focus, &:active {
      transition: 0.5s;
      background-color: $primary;
      color: $white !important;
      border: 2px solid $white;
    }
    &.border-not {
      border: none;
    }
  }
  .btn-custom:hover {
    background-position: 100% 0;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out
  }
  &.btn-gradient {
    border: transparent;
    position: relative;
    background-color: $dark;
    background-image: linear-gradient($light, $dark);
    background-image: -webkit-gradient(linear, left top, right top, from($light), color-stop($dark), color-stop($dark), to($light));
    background-image: linear-gradient(to right, $light, $dark, $dark, $light);
    transition: all .4s ease-in-out;
    background-size: 300% 100%;
    &:hover, &:focus, &:active {
      transition: all .4s ease-in-out;
      color: $white !important;
      background-position: 100% 0;
    }
  }
}
.overflow-hide {
  overflow: hidden;
}
.swiper-container {
  width: 100%;
}
.img-container {
  position: relative;
  display: inline-block;
}
.overlay {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient($dark, $light);
  opacity: 0.5;
  &.reversae {
    background-image: linear-gradient($light, $dark);
  }
}
.list {
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      padding-right: 60px;
      // padding-bottom: 10px;
      text-transform: uppercase;
      a {
        &.active {
          color: $primary;
        }
      }
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
}
.socials-lists {
  ul {
    &.socials-horizontal {
      display: flex;
    }
    &.socials-verticle {
      display: inline-block;
    }
    li {
      padding: 0 4px;
      a {
        display: inline-block;
        i {
          height: 50px;
          width: 50px;
          background-color: $primary;
          border-radius: 50px;
          display: flex;
          font-size: 20px;
          color: $white;
          transition: all 800ms;
          border: 2px solid $white;
          background-image: linear-gradient(to bottom, transparent 0%, transparent 50%, $white 50%, $white 100%);
          background-repeat: no-repeat;
          background-position: 0 0;
          background-size: 90px 180px;
        }
        &:hover {
          i {
            background-position: 0 -90px;
            background-color: $white;
            color: $primary;
            border: 1px solid $primary
          }
        }
      }
    }
  }
}
.text-hash {
  color: $hash-color;
  letter-spacing: 5px;
}
/**************************
2. brand css start
*************************/
.brand-sliders {
  .brand-slider {
    .item {
      a {
        transition: 0.5s;
        img {
          filter: brightness(0);
          &.wedding-brand {
            filter: grayscale(100);
          }
        }
        &:hover {
          transition: 0.5s;
          img {
            filter: unset;
          }
        }
      }
    }
  }
}
/**************************
brand css end
*************************/

/**************************
3. team css start
*************************/
.team-section {
  .border-around {
    border: 13px solid $white;
    border-radius: 100%;
    box-shadow: 0 0 30px $shadow-light;
    height: 150px;
    width: 150px;
  }
  .team {
    height: 135px;
    width: 135px;
  }
  .teams {
    .our-team {
      .overlay-team {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        transition: all 1s;
        img {
          transition: all 1s;
          opacity: 0;
          transform: translateY(20px);
        }
      }
      .borders {
        border-right: 2px dashed black;
        height: 110px;
        left: 50%;
        transform: translate(-50%, 0%);
        transition: all 1s;
      }
      .set-relative {
        &.set-border {
          padding-bottom: 110px;
        }
      }
      .employee {
        margin-top: 15px;
        text-align: center;
        h6 {
          line-height: 30px;
          transition: all 1s;
          color: $secondary;
        }
        .e-name {
          color: $secondary;
          text-transform: uppercase;
          margin-bottom: 5px;
        }
      }
      &:hover {
        .overlay-team {
          transition: all 1s;
          bottom: 8px;
          img {
            transition: all 1s;
            opacity: 1;
            transform: none;
          }
        }
        .borders {
          transition: all 1s;
          border-right: 2px dashed $primary;
        }
        h6, .e-name {
          transition: all 1s;
          color: $primary;
        }
      }
    }
    &:nth-child(even) {
      margin-top: 30px;
    }
  }
}
/**************************
team css end
*************************/

/**************************
4. pricing css start
*************************/
.pricing {
  .pricing-slider{
    &.price-margin {
      .price-container{
        margin: 5px 5px 2px 5px;
      }
      margin-bottom: -2px;
    }
  }
  .btn-default {
    padding: 16px 40px;
    border-radius: 50px;
  }
  .set-border {
    background: $lighten-white2;
    margin: 30px 80px;
  }
  .price-container {

    &.bg-white {
      background: $white;
    }
    &.shadows {
      filter: drop-shadow(0 0 6px $shadow-light2);;
      border-radius: 20px;;
      background: $white;
      border: none;
      overflow: hidden;
    }
    .service-feature {
      padding: 50px 0 50px 0;
      background-image: linear-gradient($light, $dark);
      &.bg-white {
        background: $white;
      }
      .price-feature {
        text-transform: uppercase;
        color: $white;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .price-features {
      margin-bottom: 35px;
      .price-feature {
        color: $light-gray;
        margin-bottom: 15px;
        &.font-primary {
          color: $primary;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    .feature-icon {
      width: auto !important;
      color: $primary;;
      display: inline-block;
      margin-bottom: 10px;
      margin-top: -12px;
      &:before {
        font-size: 55px;
      }
    }
    .price-feature-container {
      padding: 30px 0 100px 0;
      .feature-text {
        padding: 0 30px;
        h3{
          font-weight: 700;
          text-transform: uppercase;
        }
        hr {
          margin-bottom: 2rem;
          margin-top: 1.6rem;
          border-top: 1px solid #f4f4f4;
        }
        .feature-text-heading {
          &.bold {
            font-weight: 700;
          }
        }
      }
      .price-value {
        margin-bottom: 30px;;
        .price {
          font-size: calc(22px + (55 - 22) * ((100vw - 300px) / (1920 - 300)));;
          margin: -22px 0 -15px;
          font-weight: 600;
          .large {
            color: $primary;
            font-size: 55px;
            font-weight: 400;
            &.font-primary {
              color: $primary;
            }
          }
        }
      }
      .set-abs {
        z-index: -1;
        width: 100%;
        img {
          &.pricing-img {
            width: 100% !important;
          }
        }
      }
    }
    &.hover-overlay {
      &:hover {
        background: url("../images/gym/price.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        .font-primary {
          color: $white;
        }
        .price-feature-container {
          .feature-text {
            .feature-icon {
              color: $white;
            }
            .feature-text-heading {
              color: $white;
            }
            .set-border {
              color: $white;
            }
          }
          .price-features {
            .price-feature {
              color: $white;
            }
          }
          .price-value {
            .price {
              color: $white;
            }
            .large {
              color: $white;
            }
          }
        }
      }
    }
  }
  &.yoga, &.saas2 {
    .owl-theme {
      .owl-dots {
        margin-top: 40px !important;
        line-height: 1;
        .owl-dot {
          span {
            background-image: linear-gradient($light, $dark);
          }
          &.active {
            span {
              transform: scale(1.4);
            }
          }
        }
      }
    }
    .owl-item {
      &.active {
        &.center {
          .price-container {
            filter: drop-shadow(0 0 6px $shadow-light2);
            opacity: 1;
            transform: scale(1.1);
            transition: 0.5s;
          }
        }
      }
    }
    .price-margin {
      .price-container{
        margin: 35px 25px;
      }
    }
    .price-container {
      filter: grayscale(100%) drop-shadow(0 0 6px $shadow-light2);
      opacity: 0.6;
      border-radius: 10px;
      .btn-default {
        padding: 14px 46px;
      }

      .service-feature {
        padding: 0;
        background-image: unset;
        .image-top {
          width: 80%;
          margin-left: auto;
        }
        .feature-text {
          position: relative;
          .feature-text-heading {
            position: absolute;
            top: 30%;
            left: 0;
            right: 0;
            transform: translate(0, -30%);
          }
        }
      }
      .price-feature-container {
        padding: 0 0 40px 0;
        .price-feature, .price {
          color: $gray2;
        }
        .price-value {
          .price {
            font-size: initial;
            .large {
              font-size: 42px;
              font-weight: 600;
              background: -webkit-linear-gradient($light, $dark);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
      }
    }
  }
  &.saas2 {
    .price-container {
      .price-feature-container {
        margin-top: -20px;
      }
    }
  }
  &.app2 {
    .price-container {
      .price-feature-container {
        padding: 50px 0 50px 0;
      }
      &.shadows {
        border-radius: 15px;
      }
    }
    .pricing-slider {
      margin-top: -5px;
    }
  }
  &.agency, &.event, &.gym, &.resume {
    .price-container {
      .price-feature-container {
        padding: 50px 0 50px 0;
        .price-value {
          .price {
            font-size: initial;
            font-weight: 500;
          }
        }
      }
      .price-features .price-feature {
        color: $gray3;
        margin-bottom: 15px;
      }
    }
    .set-border {
      margin: 20px 70px 55px 70px;
    }
  }
  &.event, &.resume {
    .price-container {
      &.shadows {
        filter: unset;
        border-radius: 15px;
      }
      .price-features {
        .price-feature {
          color: $light-gray;
        }
      }
    }
    .btn-default {
      padding: 13px 33px;
      background-color: $primary;
      &:hover{
        transition: all 0.3s ease;
        background-color: $white;
      }
    }
  }
  &.gym {
    .price-container {
      &.shadows {
        border-radius: 4px;
      }
      .price-features {
        .price-feature {
          color: $light-gray;
          margin-bottom: 20px;
        }
      }
    }
  }
  &.wedding {
    .price-container {
      &.shadows {
        border-radius: 0;
      }
      .price-feature-container {
        padding: 50px 0 50px 0;
        .price-value {
          .price {
            font-size: initial;
            margin: 0;
          }
        }
      }
    }
    .set-border {
      background: $lighten-yellow2;
      margin: 20px 0 40px 0;
    }
  }
  &.yoga{
    .animated-bg i{
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 50%;
    }
  }
}
/**************************
pricing css end
*************************/

/**************************
5. screenshot section start
*************************/
.screenshot {
  .container {
    margin-bottom: 10px;
  }
  .screen-container {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    top: -11%;
    img {
      height: 685px;
      width: 438px;
      overflow: hidden;
      z-index: 3;
      position: relative;
    }
  }
  .swiper-container {
    .swiper-wrapper {
      .swiper-slide {
        img {
          height: 537px;
          width: auto;
        }
      }
    }
  }
  .owl-theme {
    &.screenshot-slider {
      .owl-dots {
        margin-top: 60px;
        .owl-dot {
          span {
            width: 16px;
            height: 5px;
            margin-bottom: 0;
          }
          &.active {
            span {
              width: 39px;
              height: 5px;
              background: $primary;
              box-shadow: 0 0 4px 1px $shadow-light;
            }
          }
          &:hover {
            span {
              background: $primary;
              box-shadow: 0 0 4px 1px $shadow-light;
            }
          }
        }
      }
    }
  }
}
/**************************
screenshot section end
*************************/

/**************************
6. download section start
*************************/
.download {
  .center-mobile {
    margin-top: -52px;
    margin-bottom: -80px;
    min-height: 580px;
  }
  .download-img {
    display: flex;
    .download-icon {
      position: relative;
      z-index: 9;
      box-shadow: 5px 8.66px 30px 0 rgb(194, 194, 194);
    }
    .download-center {
      h5 {
        color: $primary;
        text-transform: capitalize;
        margin-bottom: 0;
      }
    }
    .elipse {
      img {
        animation: movebounce 5s linear infinite;
      }
    }
    .center-img-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .information {
    p {
      font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1920 - 300)));
      margin-top: 45px;
      margin-bottom: 50px;
      letter-spacing: 0;
    }
  }
}
/**************************
download section end
*************************/

/**************************
7. about section start
*************************/
.app1{
  &.fixed{
    background: linear-gradient($light, $dark);
    z-index: 9;
  }
}
.about {
  position: relative;
  overflow: hidden;
  &.app1 {
    h3 {
      font-size: calc(20px + (45 - 20) * ((100vw - 300px) / (1920 - 300)));
      line-height: 45px;
      margin-bottom: 14px;
    }
    .counters {
      border-right: 1px solid $white;
      border-bottom: 1px solid $white;
      &:nth-child(even) {
        border-right: none;
      }
      &:nth-child(n+3) {
        border-bottom: none;
      }
      img {
        margin-bottom: 36px;
      }
      .abouts {
        padding: 80px 0;
        text-align: center;
      }
    }
    .mobile {
      right: 10%;
      &.mobile-img {
        z-index: 3;
        .mobile-screen {
          height: 800px;
        }
      }
      &.rectangle {
        right: -4%;
        animation: movebounce 15s linear infinite;
        z-index: -1;
        &.r-2 {
          animation: movebounce 20s linear infinite;
          right: -2%;
        }
      }
      &.galaxy {
        top: 2%;
        right: -15%;
        z-index: 5;
      }
    }
  }
  &.event {
    h4 {
      color: $white;
    }
    p {
      color: $white;
    }
    .announcer-img {
      display: inline-block;
      position: relative;
      margin-left: 40px;
      margin-bottom: 40px;
      img {
        border-radius: 15px;
        overflow: hidden;
        &:nth-child(3) {
          position: relative;
          z-index: 3;
        }
        &:nth-child(2) {
          position: absolute;
          top: 0;
          transform: translate(-20px, 20px);
          opacity: 0.3;
        }
        &:nth-child(1) {
          z-index: 1;
          position: absolute;
          top: 0;
          -webkit-transform: translate(-20px, -20px);
          transform: translate(-40px, 40px);
          opacity: 0.2;
        }
      }
    }
  }
}
/**************************
about section end
*************************/

/**************************
8. format section start
*************************/
.format {
  .logo {
    padding-bottom: 28px;
  }
  .set-border {
    border-bottom: 2px solid $primary;
    width: 15px;
    margin-bottom: 30px;
    margin-left: 75px;
    display: inline-block;
    position: relative;
    &:before, &:after {
      content: '';
      border-bottom: 2px solid $primary;
      position: absolute;
    }
    &:before {
      width: 65px;
      right: 100%;
      margin-right: 10px;
    }
    &:after {
      left: 100%;
      width: 5px;
      margin-left: 10px;
    }
  }
  .about-icon {
    margin-bottom: 0;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    .quality {
      color: $secondary;
      margin-bottom: 10px;
    }
    .users {
      font-weight: 600;
      color: $primary;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 0;
    }
  }
  .format-small-text {
    margin-bottom: 0;
  }
  .format-head-text {
    margin-bottom: 50px;
    .about-font-header {
      font-size: calc(20px + (30 - 20) * ((100vw - 300px) / (1920 - 300)));
    }
  }
  .format-sub-text {
    margin-bottom: 35px;
    .sub-heading {
      margin-bottom: 20px;
    }
    .about-para {
      margin-bottom: 30px;
      letter-spacing: 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.icon-collection {
  display: flex;
  .about-icon {
    margin: 0 18px;
    filter: drop-shadow(0 0 10px $white4);
    border-radius: 20px;
    background-color: $white;
    transition: 0.5s;
    a {
      padding: 18px 20px;
    }
    &:hover {
      transition: 0.5s;
      background-color: $primary;
      a {
        h5, h6, h4 {
          color: $white;
          transition: 0.5s;
        }
      }
    }
  }
}
/**************************
format section end
*************************/

/**************************
9. service section start
*************************/
.services {

  .service {
    padding: 60px 25px;;
    border: 1px solid $light-white;
    box-shadow: 0 0 10px 5px rgba(253, 248, 247, 0.69);
    border-radius: 10px;
    transition: all 1s;
    text-align: center;
    &:hover {
      .img-block {
        animation: scaleDisappear 0.3s;
      }
    }
    &.service-overlay {
      &:hover {
        transition: all 1s;
        background: linear-gradient($light, $dark);
        border-radius: 10px;
        box-shadow: 0 5px 20px $shadow-light;
        img {
          filter: brightness(0) invert(1);
        }
        .service-feature {
          .feature-text, p {
            color: $white;
          }
        }
      }
    }
    .img-block {
      transition: all 0.5s;
      margin-bottom: 15px;
    }
    .service-feature {
      .feature-text {
        margin-bottom: 22px;
      }
    }
  }
  .service-border {
    position: relative;
    &:before, &:after {
      content: '';
      position: absolute;
    }
    &:first-child {
      &:before {
        border-bottom: 2px solid $light-inner;
        width: 210px;
        right: 0;
        bottom: 0;
      }
      &:after {
        border-right: 2px solid $light-inner;
        height: 210px;
        right: 0;
        bottom: 0;
      }
    }
    &:nth-child(2) {
      &:before {
        border-bottom: 2px solid $light-inner;
        width: 210px;
        left: 0;
        bottom: 0;
      }
    }
    &:nth-child(3) {
      &:after {
        border-right: 2px solid $light-inner;
        height: 210px;
        right: 0;
        top: 0;
      }
    }
  }
  &.app2 {
    .service-container {
      &:hover {
        .service {
          background-color: #fff6f6;
        }
      }
      &:nth-child(n+5) {
        .service {
          border: 1px solid $light-white;
          margin-bottom: 0;
        }
      }
      .service {
        margin-bottom: 30px;
        border-radius: 15px;
        .feature-text {
          color: $secondary;
          letter-spacing: 0;
        }
        p {
          letter-spacing: 0;
        }
      }
    }
  }
  &.resume {
    .container {
      margin-bottom: -25px;
      .service {
        margin-bottom: 25px;
      }
    }
  }
}
/**************************
service section end
*************************/

/**************************
10. subscribe section start
*************************/
.subscribe {
  .button-primary {
    top: 0;
    right: 2%;
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    button {
      font-size: calc(13px + (18 - 13) * ((100vw - 300px) / (1920 - 300)));
      padding: 20px 55px;
      font-weight: 200;
      border-radius: 34px;
      letter-spacing: 3px;
    }
  }
  .form-group {
    width: 100%;
    font-size: 18px;
    margin-bottom: 0;
    input, textarea {
      background-color: $white;
      padding: 30px 35% 30px 35px;
      border-radius: 50px;
      border: 1px solid $lighten-white2;
      font-size: 18px;
      box-shadow: none;
      margin: 0;
      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $gray4;
        opacity: 0.5;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: $gray4;
        opacity: 0.5;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: $gray4;
        opacity: 0.5;
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: $gray4;
        opacity: 0.5;
      }
    }
    .flex {
      position: relative;
    }
  }
  &.app1 {
    background: url("../images/app_landing1/subscribe/subscribe.png");
    .button-primary {
      right: 4%;
    }
    &.bg {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  &.subscribe-2 {
    .form-group {
      .icon {
        font-size: 30px;
        color: $primary;
        position: absolute;
        left: 50px;
        top: 50%;
        transform: translate(0, -50%);
      }
      input {
        padding-left: 110px;
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: $primary;
          opacity: 0.5;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          color: $primary;
          opacity: 0.5;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          color: $primary;
          opacity: 0.5;
        }
        &:-moz-placeholder { /* Firefox 18- */
          color: $primary;
          opacity: 0.5;
        }
      }
      .input-container {
        display: flex;
        width: 100%;
        margin-bottom: 15px;
      }
    }
  }
  &.music {
    .subscribe-title {
      padding: 0 40px;
      text-align: center;
      .subscribe-main {
        margin-bottom: 15px;
        text-transform: none;
        line-height: normal;
      }
      .subscribe-sub {
        margin-bottom: 25px;
      }
    }
  }
  &.yoga, &.resume {
    .subscribe-head {
      margin-top: -12px;
      margin-bottom: 20px;
      font-size: calc(18px + (35 - 18) * ((100vw - 300px) / (1920 - 300)));
      font-weight: 400;
    }
    .subscribe-sub-head {
      font-size: calc(13px + (16 - 12) * ((100vw - 300px) / (1920 - 300)));
      max-width: 560px;
      line-height: 1.8;
      margin: 0 auto;
    }
  }
  &.event, &.wedding, &.yoga {
    .form-group {
      input {
        font-size: calc(13px + (16 - 13) * ((100vw - 300px) / (1920 - 300)));
      }
    }
    .button-primary {
      button {
        letter-spacing: 0;
        font-weight: 500;
      }
    }
  }
  &.resume {
    .button-primary {
      button {
        letter-spacing: 0;
        font-weight: 500;
        border-radius: 34px;
      }
    }
  }
  &.yoga {
    .button-primary {
      right: 28px;
    }
  }
  &.wedding {
    .button-primary {
      button {
        margin-right: 10px;
      }
    }
  }
}
/**************************
subscribe section end
*************************/

/**************************
11. navigations section start
*************************/
body {
  &.menu-on {
    &:before {
      content: "";
      position: fixed;
      background-color: rgba(0, 0, 0, 0.2);
      height: 100vh;
      width: 100vw;
      z-index: 1;
    }
  }
}
.dropdown-sec {
  .dropdown, .dropdown-submenu {
    display: inline-block;
  }
  a {
    text-transform: capitalize;
  }
  .menu-head {
    font-weight: 600;
    text-transform: uppercase;
    padding: 10px 25px !important;
    &:hover {
      color: unset;
    }
  }
  .right-border {
    border-right: 1px solid $lighten-white;
  }
  ul {
    min-width: 230px;
    a {
      &.menu-item {
        transition: 0.6s;
        color: $gray;
        font-weight: 400;
      }
    }
  }
  .drop-down-design {
    margin-top: 0;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  }
  .dropdown-content {

    &.dropdown-size {
      min-width: 1020px;
    }
    ul {
      width: 100%;
    }
  }
  .dropdown-submenu {
    position: relative;
    .dropdown-menu {
      border: none;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
      top: 0;
      left: 100%;
      margin-top: -1px;
    }
  }
}
.dropbtn {
  > i {
    margin-left: 10px;
  }
}
/**************************
navigation section end
*************************/

/**************************
12. copyright section start
*************************/
.copyright {
  padding: 18px 0;
  background: $primary;
  .copyright-text {
    font-weight: 300;
    letter-spacing: 1px;
    margin-bottom: 0;
    margin-top: 0;
    line-height: 1.2;
    &.op-text {
      opacity: 0.5;
    }
    &.center-c-t {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }
  .fa-heart {
    color: $heart;
  }
  .link-horizontal {
    ul {
      li {
        padding-right: 30px;
        a {
          transition: 0.5s;
          color: $white;
          &.op-text {
            transition: 0.5s;
            &:hover {
              transition: 0.5s;
              opacity: 1;
            }
          }
          &:hover {
            transition: 0.5s;
            opacity: 0.5;
          }
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
  &.app2 {
    background: $copyright-app2;
    .copyright-text {
      font-weight: 300;
      letter-spacing: 1px;
    }
  }
  &.agency {
    background: $copyright-agency;
    &.inner-page {
      background: $copy-bg;
    }
  }
  &.event {
    background: rgba(0, 0, 0, 0.05);
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 5;
    padding: 15px;
    .link-horizontal {
      ul {
        li {
          a {
            i {
              font-size: 20px;
              color: $white;
            }
          }
        }
      }
    }
  }
  &.wedding {
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    .link-horizontal {
      a {
        i {
          font-size: 20px;
        }
      }
      ul {
        &.link {
          li {
            a {
              text-transform: uppercase;
              transition: 0.5s;
              &:hover {
                transition: 0.5s;
                opacity: 1;
                color: $light;
              }
            }
          }
        }
      }
    }
    .copyright-text {
      font-weight: 500;
    }
  }
  &.yoga, &.resume, &.creative {
    padding: 40px 0;
    hr {
      margin-top: 40px;
      margin-bottom: 40px;
      background: $white;
    }
    .link-horizontal {
      ul {
        li {
          text-transform: uppercase;
        }
      }
      &.social-link {
        margin-bottom: 20px;
        ul {
          li {
            padding-right: 45px;
            &:last-child {
              padding-right: 0;
            }
            a {
              i {
                font-size: 20px;
                color: $white;
              }
            }
          }
        }
      }
    }
  }
  &.resume {
    background: $bg-light;
    .link-horizontal {
      ul {
        li {
          text-transform: uppercase;
          a {
            color: $primary;
            font-weight: 500;
          }
        }
      }
      &.social-link {
        ul {
          li {
            a {
              i {
                color: $primary;
              }
            }
          }
        }
      }
    }
    .copyright-text {
      color: $copy-text-resume;
      font-weight: 500;
    }
  }
  &.gym {
    background: $copy-bg;
  }
  &.music {
    background: $copy-bg;
  }
  &.saas1 {
    background: $copyright-saas1;
  }
  &.wedding {
    padding: 38px 0;
    background: $white;
    .link-horizontal {
      ul {
        li {
          a {
            color: $primary;
          }
        }
      }
    }
  }
  &.light {
    &.event {
      background: rgba(0, 0, 0, 0.56);
    }
  }
}
/**************************
copyright section end
*************************/

/**************************
13. header section start
*************************/
.header {
  display: block;
  position: relative;
  padding: 0;
  overflow: hidden;
  .center-text {
    height: calc(100vh - 83px);
    margin-top: 83px;
  }
  &.wedding {
    .center-text {
      height: calc(100vh - 68px);
      margin-top: 68px;
    }
  }
  &.yoga {
    .center-text{
      height: calc(100vh - 63px);
      margin-top: 63px;
    }
  }
  &.overflow-unset {
    overflow: unset;
  }
  .header-top-line {
    font-size: 18px;
    color: $white;
    margin-bottom: 40px;
    text-transform: capitalize;
    font-weight: 300;
  }
  .bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    height: 100vh;
    &.app1-header {
      background-image: url("../images/app_landing1/header-1-bg.png");
      overflow: hidden;
    }
    &.app2-header {
      background-image: linear-gradient($dark, $light);
      .header-text {
        padding-bottom: 20px;
        position: relative;
        h1 {
          &:before {
            content: "";
            border-bottom: 3px solid $white;
            width: 90px;
            position: absolute;
            bottom: 0;
          }
        }
      }
      .transparent-img {
        background-image: url("../images/app_landing2/h2-bg.png");
      }
    }
    &.header3 {
      background-image: url("../images/event/event_slider.jpg");
      background-attachment: fixed;
      background-position: 0 0;
      background-repeat: repeat;
      animation: slide 50s linear infinite;
      will-change: background-position;
      background-size: auto;
      @-webkit-keyframes slide {
        from {
          background-position: 0 0; }
          to {
            background-position: -4000px 0; } }
            &::before {
              content: "";
              background-image: linear-gradient($light, $dark);
              opacity: 0.9;
              height: 100%;
              width: 100%;
              position: absolute;
            }
          }
          &.header4 {
            background-image: url("../images/hedaer-images/h3-bg.jpg");
          }
          &.gym-header {
            background-image: url("../images/gym/header.jpg");
            background-position: top;
            h1 {
              text-align: center;
              text-transform: uppercase;
              font-style: italic;
            }
            .header-sub-text {
              padding: 0 85px;
              p {
                padding: 0 95px;
              }
            }
          }
          &.music-bg {
            background-image: url("../images/music/slider.jpg");
            background-attachment: fixed;
          }
          &.resume {
            background-image: url("../images/resume/h7-bg-text.png");
            background-size: contain;
          }
          &.saas1-header {
            background-image: url("../images/saas1/slider-banner.jpg");
          }
          &.saas2-bg {
            background-image: url("../images/saas2/slider.png");
          }
          &.yogas {
            background-image: url("../images/yoga-img/Layer-8.png");
          }
          &.agency {
            background-image: url("../images/agency/slider/slider.png");
            background-position: right;
          }
        }
        .rotate {
          position: absolute;
          top: 20%;
          transform: rotate(45deg);
          right: 17%;
          z-index: 1;
          img {
            width: 450px;
          }
        }
        .squares {
          .img-1 {
            animation: changedir 10s infinite;
          }
          .img-2 {
            animation: changedir-rev 10s infinite;
          }
        }
        .animation-circle-inverse {
          opacity: 0.25;
          position: absolute;
          right: 100px;
          top: 0;
          i {
            background: $lighten-white;
            position: absolute;
            height: 200px;
            width: 200px;
            border-radius: 100%;
            opacity: 0.3;
            -webkit-animation: ripple1 3s linear infinite;
            animation: ripple1 3s linear infinite;
            &:nth-child(2) {
              -webkit-animation: ripple2 3s linear infinite;
              animation: ripple2 3s linear infinite;
            }
            &:nth-child(3) {
              -webkit-animation: ripple3 3s linear infinite;
              animation: ripple3 3s linear infinite;
            }
          }
        }
        .wave {
          position: absolute;
          bottom: 0;
          width: 100%;
          animation: move-img 10s linear infinite;
          background-image: url("../images/app_landing2/wave.png");
          height: 40px;
          background-size: contain;
          &:before {
            content: "";
            width: 100%;
            position: absolute;
            bottom: 0;
            animation: move-img-reverse 10s linear infinite;
            background-image: url("../images/app_landing2/wave.png");
            height: 40px;
            background-size: contain;
            opacity: 0.4;
          }
          &:after {
            content: "";
            position: absolute;
            width: 100%;
            bottom: 0;
            animation: move-img 20s linear infinite;
            background-image: url("../images/app_landing2/wave.png");
            height: 45px;
            background-size: contain;
            opacity: 0.4;
          }
        }
        .img-mobile {
          bottom: -17%;
          left: 50%;
          transform: translate(-50%, 0);
          z-index: 2;
        }
        .wave-orange {
          position: absolute;
          bottom: 0;
          width: 326px;
          -webkit-animation: move-img 10s linear infinite;
          animation: move-img 10s linear infinite;
          background-image: url('../images/app_landing2/wave-orange.png');
          height: 34px;
          background-size: contain;
          left: 50%;
          transform: translate(-50%, 0);
          animation-delay: 0.55s;
          z-index: 3;
          &:before {
            content: "";
            position: absolute;
            bottom: 0;
            width: 326px;
            -webkit-animation: move-img-reverse 10s linear infinite;
            animation: move-img-reverse 10s linear infinite;
            background-image: url('../images/app_landing2/wave-orange.png');
            height: 34px;
            opacity: 0.4;
            background-size: contain;
          }
          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            opacity: 0.4;
            width: 326px;
            -webkit-animation: move-img 40s linear infinite;
            animation: move-img 40s linear infinite;
            background-image: url('../images/app_landing2/wave-orange.png');
            height: 34px;
            background-size: contain;
          }
        }
        .event-content {
          .timer {
            display: flex;
            justify-content: center;
          }
          .h1-margin {
            margin: 0 0 50px 0;
          }
          .header-sub-text {
            font-weight: 300;
          }
          .link-horizontal {
            ul {
              li {
                a {
                  font-weight: 600;
                  padding: 13px 35px;
                }
              }
            }
          }
          h4 {
            margin-bottom: 20px;
          }
          ul {
            justify-content: center;
            display: flex;
            li {
              padding-right: 0;
            }
          }
          .counter-container {
            h2 {
              font-weight: 200;
              text-transform: none;
              margin-top: -12px;
              span {
                &.days-text {
                  font-weight: 700;
                  font-size: calc(30px + (45 - 30) * ((100vw - 300px) / (1920 - 300)));
                }
                &.days {
                  line-height: normal;
                  font-weight: 400;
                  font-size: calc(30px + (42 - 30) * ((100vw - 300px) / (1920 - 300)));
                }
              }
            }
          }
        }
        .set-bottom {
          bottom: 50px;
          display: flex;
          justify-content: center;
          width: 100%;
          z-index: 3;
          &:before {
            content: "";
            border: 1px solid $white;
            height: 48px;
            width: 48px;
            border-radius: 50px;
            opacity: 0.5;
          }
          .down {
            position: absolute;
            .scroll-down {
              animation: scroll 0.8s infinite;
            }
            @keyframes scroll {
              0% {
                transform: rotatey(0deg);
              }
              50% {
                transform: rotatey(90deg);
              }
              100% {
                transform: rotatey(0deg);
              }
            }
          }
        }
        .header5-content {
          .gym-slider {
            .item {
              img {
                display: block;
                width: 100%;
                height: auto;
              }
            }
          }
        }
        .music-content {
          .center-text {
            height: calc(100vh - 80px);
            margin-top: 78px;
          }
          .header-text, .header-sub-text {
            position: relative;
            z-index: 2;
          }
          .header-sub-text {
            h5 {
              font-weight: 300;
            }
          }
          .owl-theme {
            .item {
              position: relative;
            }
            .owl-nav {
              display: none;
            }
            .owl-dots {
              display: inline-block;
              position: absolute;
              justify-content: center;
              margin-top: 0;
              z-index: 3;
              top: 40%;
              right: 60px;
              .owl-dot {
                display: block;
                &.active {
                  span {
                    background: $dark;
                    width: 4px;
                    opacity: 1;
                  }
                }
                span {
                  height: 45px;
                  width: 2px;
                  border-radius: unset;
                  opacity: 0.4;
                  margin: 20px;
                }
              }
            }
          }
        }
        .resume {
          .font-large {
            font-size: 120%;
          }
        }
        .background-animate {
          animation: movebounce 150s linear infinite;
          bottom: 0;
          width: 100%;
        }
        .header8-content {
          .slider-banner {
            position: absolute;
          }
          .slider-1 {
            position: relative;
            z-index: 3;
            .owl-nav {
              display: none;
              position: absolute;
              left: -13%;
              bottom: -110px;
              [class*=owl-] {
                background: none;
              }
              .owl-next {
                margin-left: 40px;
              }
              button {
                &.owl-next {
                  position: absolute;
                  top: 15px;
                  left: 120px;
                }
              }
            }
          }
          .slider-logo {
            align-self: center;
          }
        }
        .saas2-content {
          .header-sub-text {
            .saas2-sub-text {
              font-size: calc(18px + (38 - 18) * ((100vw - 300px) / (1920 - 300)));
              color: $white;
              text-transform: uppercase;
              font-weight: 200;
              span {
                font-weight: 300;
              }
            }
            .sub-para {
              font-weight: 300;
            }
          }
          .set-abs {
            position: absolute;
            right: 2%;
          }
          .img1 {
            left: 5%;
            top: 5%;
          }
          .img2 {
            left: 35%;
            top: 20%;
          }
          .img3 {
            left: 0;
            bottom: 32%;
          }
          .img4 {
            left: 35%;
            bottom: 32%;
          }
          .img5 {
            right: 30%;
            bottom: 16%;
          }
          .img6 {
            right: 12%;
            bottom: 6%;
          }
          .img7 {
            right: 0;
            bottom: 28%;
          }
          .move-up-down {
            animation: movebounce 15s linear infinite;
          }
          .move-right-left {
            animation: moveright 3.9s linear infinite;
          }
          .round {
            position: absolute;
            right: 11%;
            top: 0;
            .img8 {
              position: relative;
            }
            .inner-circle {
              position: absolute;
              top: 30%;
              left: 30%;
            }
          }
          .bottom-content {
            z-index: 3;
            bottom: 6%;
            height: unset;
            width: 100%;
            .bottom {
              height: 86px;
              width: 66px;
              background-color: $white;
              border-radius: 50px;
              overflow: hidden;
              .down {
                img {
                  animation: btndown 3s linear infinite;
                }
              }
            }
          }
        }
        .wedding-content {
          .logo-h1 {
            margin-left: -170px;
          }
          .header-sub-text {
            animation: rightFadeInOut 2.5s ease-in-out;
          }
          .rightFadeInOut {
            animation: rightFadeInOut 1.5s ease-in-out;
          }
          .rightfadediv {
            animation: rightFadeInOut 2.5s ease-in-out;
          }
          .btn-default {
            letter-spacing: 2px;
          }
          .girl {
            left: 18%;
          }
        }
        .yoga-content {
          .right-way {
            margin-top: 160px;
          }
          h1 {
            span {
              font-weight: 200;
              text-transform: uppercase;
            }
          }
          .girl {
            width: 100%;
            .girl-img {
              width: auto;
            }
          }
          .animation-circle-inverse {
            right: 50%;
            bottom: 0;
            top: unset;
          }
          .owl-nav {
            margin-top: 0;
            .owl-next, .owl-prev {
              position: absolute;
              top: 50%;
              transform: translate(0, -50%);
              padding: 20px 26px;
              background: rgba(255, 255, 255, 0.1);
              border-radius: unset;
              &:hover {
                background: $lighten-yellow;
                background: rgba(255, 255, 255, 0.2);
              }
              i {
                font-size: 25px;
                display: flex;
                height: 100%;
                width: 100%;
                justify-content: center;
                align-items: center;
              }
            }
            .owl-next {
              right: 4%;
            }
            .owl-prev {
              left: 4%;
            }
          }
        }
      }
      /*************************
      header section end
      *************************/

      /*************************
      14.footer section start
      *************************/
      footer {
        padding: 100px 0;
        .footer-title {
          &.mobile-title {
            display: none;
            h3 {
              font-size: calc(15px + (24 - 15) * ((100vw - 300px) / (1920 - 300)));
            }
          }
        }
        .footer-titles {
          &.mobile-title {
            display: none;
            h3 {
              font-size: calc(15px + (24 - 15) * ((100vw - 300px) / (1920 - 300)));
            }
          }
        }
        .logo-sec {
          .footer-logo {
            margin-bottom: 36px;
          }
          .footer-para {
            margin-bottom: 30px;
            p {
              letter-spacing: 0;
            }
            .para-address {
              margin-bottom: 10px;
              font-weight: 200;
            }
            &.op-text {
              .para-address {
                opacity: 0.8;
              }
            }
          }
          .footer-social {
            display: flex;
            line-height: 0;
            .footer-social-list {
              padding: 0 15px;
              &:first-child {
                padding-left: 0;
              }
              &:last-child {
                padding-right: 0;
              }
              a {
                color: white;
                &:hover {
                  opacity: 0.6;
                }
              }
            }
          }
        }
        .footer-headings {
          text-transform: uppercase;
          margin-bottom: 40px;
          color: $white;
          font-weight: 500;
        }
        .footer-contant {
          .form-group {
            position: relative;
            margin-bottom: 50px;
            input {
              border-bottom: 1px solid white;
              border-radius: 0;
              background: transparent;
              padding-left: 0;
              color: $white;
              &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                text-transform: capitalize;
                font-size: 12px;
                opacity: 0.4;
                letter-spacing: 1px;
                color: $white;
              }
              &::-moz-placeholder { /* Firefox 19+ */
                text-transform: capitalize;
                font-size: 12px;
                opacity: 0.4;
                letter-spacing: 1px;
                color: $white;
              }
              &:-ms-input-placeholder { /* IE 10+ */
                text-transform: capitalize;
                font-size: 12px;
                opacity: 0.4;
                letter-spacing: 1px;
                color: $white;
              }
              &:-moz-placeholder { /* Firefox 18- */
                text-transform: capitalize;
                font-size: 12px;
                opacity: 0.4;
                letter-spacing: 1px;
                color: $white;
              }
            }

            .fa-paper-plane {
              position: absolute;
              color: $white;
              top: 10px;
              right: 10px;
            }
          }
          .Unice-text {
            margin-bottom: 10px;
          }
          .Unice-copyright-text {
            font-weight: 300;
          }
        }
        .link-btns {
          margin-bottom: -10px;
          ul {
            li {
              &.buttons {
                padding: 8px 20px;;
                border: 1px solid rgba(255, 255, 255, 0.34);;
                border-radius: 5px;
                opacity: 0.8;
                margin-right: 5px;
                display: inline-block;
                margin-bottom: 10px;
                &:last-child {
                  margin-right: 0;
                }
                a {
                  text-transform: capitalize;
                  color: white;
                }
                &:hover {
                  opacity: 1;
                }
              }

              &:nth-child(n+7) {
                margin-bottom: 0;
              }
            }
            &.links {
              li {
                margin-bottom: 10px;
              }
            }
            &.place {
              li {
                display: flex;
                margin-bottom: 40px;
              }
            }
          }
        }
        .footer-lists {
          &.op-text {
            li {
              opacity: 0.8;
              transition: 0.2s;
              &:hover {
                opacity: 1;
                transition: 0.2s;
              }
            }
          }
          li {
            margin-bottom: 10px;
            color: $white;
            text-transform: capitalize;
            a {
              font-weight: 300;
              color: $white;
              line-height: initial;
              &:hover{
                color: $white;
              }
            }
            &:hover{
              a{
                color: $white;
              }
            }
          }
          &.contacts {
            li {
              margin-bottom: 35px;
              display: flex;
              line-height: 1.2;
              i {
                margin-right: 18px;
                margin-top: 2px;
              }
            }
          }
        }
        i {
          &.fa {
            font-size: 16px;
          }
        }
        &.footer2 {
          &.bg {
            background: url("../images/app_landing2/l-2-footer.png") no-repeat top;
            background-size: cover;
          }
          &.saas2 {
            background: url("../images/saas2/footer.png") no-repeat top;
            background-size: cover;
            .footer-padding {
              padding-top: 245px;
            }
          }
          &.agency {
            background-image: linear-gradient($dark,$light);
            &.inner-page {
              .brand-container {
                background: $bg-container;

              }
            }
            .brand-container {
              margin-top: 50px;
              padding: 30px;
              background: $blue4;
              border-radius: 20px;
            }
            .list {
              ul {
                li {
                  padding-bottom: 0;
                }
              }
            }
            .link-horizontal {
              ul {
                li {
                  &.borders-right {
                    border-right: 3px solid $white;
                    height: 30px;
                    margin-right: 15px;
                    opacity: 0.051;
                  }
                }
              }
            }
          }
          &.gym {
            background-color: $black;
          }
        }
        &.dark {
          &.app2 {
            background: url('../images/app_landing2/l-2-footer-dark.png') no-repeat top !important;
          }
          background-image: linear-gradient($dark, $light) !important;
          h1, h2, h3, h4, h5, h6, p, a {
            color: $white !important;
            opacity: 0.8;
          }
          li {
            color: $white !important;
          }
          i {
            &.white {
              color: $white !important;
            }
          }
          .form-group {
            i {
              &.fa-envelope {
                color: $primary !important;
              }
            }
          }
          &.resume {
            i {
              color: $white !important;
            }
          }
        }
        &.light {
          background: $white;
          h1, h2, h3, h4, h5, h6, p, a, li {
            color: $black !important;
          }
          &.event {
            form {
              border: 1px solid black;
            }
          }
        }
        &.bg-light-inner {
          background: #f8f8f8;
        }
      }
      /*************************
      footer section end
      *************************/

      /*************************
      15. video section start
      *************************/
      .videos {
        .video {
          background-image: url("../images/app_landing1/video-pic.jpg");
          z-index: 1;
          padding: 125px 0;
          border-radius: 10px;
        }
        .frame {
          animation: movebounce 10s linear infinite;
        }
        .frame2 {
          animation: movebounce 10s linear infinite;
          animation-delay: 4s;
        }
        .reviews {
          padding: 20px;
          background-color: $white;
          img {
            &.reviewers {
              border: 5px solid white;
              border-radius: 100%;
              box-shadow: 0 0 0 4px $shadow-light;
              width: auto;
            }
          }
        }
        .video-description {
          z-index: 1;
          h3 {
            font-size: calc(20px + (42 - 20) * ((100vw - 300px) / (1920 - 300)));
            line-height: 1.1;
          }
          p {
            margin-bottom: 30px;
            color: $white;
            font-weight: 300;
            font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1920 - 300)));
            letter-spacing: 0;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .bg {
          background: url("../images/video/bg-videol-2.png");
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          float: right;
          .button2 {
            padding: 188px 265px;
          }
        }
        .review-video-box {
          border-radius: 10px;
          .clients-review {
            display: -webkit-box;
          }
          img {
            &.border-radius {
              border-radius: 10px;
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
              width: auto;
            }
          }
          .texture {
            background-color: $white;
            border-radius: 10px;
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            padding: 20px;
            .inner-text {
              max-width: 162px;
              i {
                font-size: 14px;
              }
            }
            .unset-width {
              display: flex;
              img {
                width: auto;
              }
            }
          }
        }
        &.saas1 {
          .video {
            background-image: url('../images/saas1/video-pic.jpg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
      }
      /*************************
      video section end
      *************************/

      /*************************
      16. booking section start
      *************************/
      .booking {
        .btn-default {
          font-weight: 500;
          padding: 18px 45px;
          text-transform: uppercase;
        }
        .form-group {
          input {
            padding: 18px 20px;
            border-radius: 35px;

          }
        }
        .btn-default {
          border-radius: 50px;
        }
      }
      /*************************
      booking section end
      *************************/

      /*************************
      17. app2 common css start
      *************************/
      .app2 {
        overflow: visible;
        h1, h2 {
          font-family: 'Poppins', sans-serif;
        }
        h3, h4, h5, li {
          margin-top: 0;
          margin-bottom: 0;
        }
        h1, h2, h3, h4, h5, h6 {
          text-transform: capitalize;
        }
        h2, h3, h4, h5, h6, a {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
        }
        li {
          font-weight: 400;
        }
        h2, h3, h4 {
          color: $primary;
        }
        h5, h6, p {
          color: $secondary;
        }
        .btn {
          text-transform: uppercase;
          letter-spacing: 1px;
          padding: 15px 40px;
        }
        h1 {
          font-size: calc(22px + (45 - 22) * ((100vw - 300px) / (1920 - 300)));
          margin-bottom: 0;
          line-height: 1.4;
        }
        h2 {
          font-size: calc(18px + (30 - 18) * ((100vw - 300px) / (1920 - 300)));
          color: $secondary;
          margin-top: -5px;
          margin-bottom: -17px;
        }
        h3 {
          font-size: calc(18px + (26 - 18) * ((100vw - 300px) / (1920 - 300)));
          margin-top: 0;
          line-height: 0.8;
        }
        h4 {
          letter-spacing: 2px;
          line-height: 18px;
        }
        h5 {
          letter-spacing: 1px;
          line-height: 20px;
        }
        h6 {
          letter-spacing: 1px;
        }
        p {
          margin-bottom: -3px;
          color: $gray5;
        }
        &.fixed{
          background-color: $primary; 
        }
        .title {
          .title-img {
            height: 50px;
          }
        }
        &.header {
          .img-mobile {
            .headaer-image {
              width: 415px;
            }
          }
        }
        &.about {
          padding-top: 170px;
        }
        &.about {
          .set-height {
            min-height: 632px;
            .mobile1 {
              position: absolute;
              width: 355px;
              right: 0;
            }
            .j-img {
              position: absolute;
              right: 20%;
              bottom: 27%;
              width: 56px;
              animation: movej 4s linear infinite;
            }
          }
        }
        &.quality {
          .set-height {
            min-height: 627px;
            .mobile2 {
              position: absolute;
              width: 460px;
              top: -36px;
            }
          }
        }
        &.team {
          .team-slider {
            .owl-stage-outer {
              margin: -5px;
            }
            .team-container {
              padding: 25px 30px;
              border: 1px solid $light-white;
              box-shadow: 0 0 10px 5px rgba(253, 248, 247, 0.69);
              border-radius: 10px;
              margin: 5px;
              background: $white;
              .members {
                width: 80px !important;
                margin: 0 auto;
                border: 5px solid $white;
                border-radius: 100%;
                filter: drop-shadow(0 0 5px $white2);
                margin-bottom: 20px;
              }
              .name {
                font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1920 - 300)));
                color: $primary;
                letter-spacing: 0;
                margin-bottom: 15px;
              }
              .post {
                margin-bottom: 15px;
              }
              .team-para {
                letter-spacing: 0;
              }
            }
          }
        }
        &.screenshot {
          .container {
            margin-bottom: 0;
          }
        }
        &.services ,&.screenshot{
          .animated-bg{
            i{
              right: 30%;
            }
          }
        }
        &.pricing {
          .price-container {
            .feature-icon {
              color: $white;
            }
            .price-features {
              .price-feature {
                color: $secondary;
                margin-bottom: 15px;
                font-weight: 400;
              }
            }
            .price-feature-container {
              .price-value {
                .price {
                  font-size: initial;
                  margin: -22px 0 -15px;
                }
              }
            }
          }
        }
        .subscribe {
          .button-primary {
            right: 2%;
            button {
              font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1920 - 300)));
              padding: 20px 30px;
              letter-spacing: 0;
              font-weight: 400;
            }
          }
          input {
            font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1920 - 300)));
          }
        }
        .animated-bg i {
          background: #fff6f6;
          box-shadow: 0 15px 30px 0 #f3c4c3;
        }
      }
      /*************************
      app2 common css start
      *************************/

      /*************************
      21. music css start
      **************************/
      .bg-black {
        background: $black;
      }
      .music {
       &.fixed{
        background-color: $black; 
        border-bottom: 1px solid #ddd;
      }
      .left-side, .right-side {
        position: absolute;
        top: 40%;
        align-items: center;
      }
      .left-side {
        left: 0;
        top: 40%;
        transform: rotate(-90deg) translateX(-40%);
        .follow-text {
          font-size: 14px;
          text-transform: uppercase;
          display: inline-block;
          letter-spacing: 5px;
          margin-right: 15px;
        }
        ul {
          display: inline-block;
          li {
            display: inline-block;
            img {
              width: 18px;
              margin-right: 9px;
            }
          }
        }
      }
      .right-side {
        right: 20px;
        top: 60%;
        transform: rotate(90deg) translateX(-60%);
        .circle {
          height: 30px;
          width: 30px;
          border-radius: 50%;
          box-shadow: 2px 3.464px 30px 0 rgb(208, 18, 43);
          background-color: rgb(208, 18, 43);
          display: inline-block;
          margin-right: 20px;
          position: relative;
          img {
            transform: rotate(-90deg) translate(-50%, 20%);
            position: absolute;
            top: -10%;
            left: 50%;
          }
        }
      }
      .img-height {
        height: 100vh;
        img {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }
      &.blog {
        .img-container {
          border-radius: 5px;
          overflow: hidden;
        }
      }
      .music-testimonial-slider {
        img {
          margin: 0 auto;
        }
      }
      .music-container {
        margin-top: -120px;
        margin-bottom: 50px;
        .play-bg {
          border-radius: 32px;
          background-image: linear-gradient(90deg, $music-dark 0%, $music-light 100%);
          height: 67px;
          margin: 0 auto;
          width: 276px;
          position: relative;
          z-index: 1;
          .song-text-container {
            border-radius: 32px;
            background-image: linear-gradient(90deg, $music-dark 0%, $music-light 100%);
            display: inline-block;

            .center-img {
              display: flex;
              align-items: center;
              height: 100%;
              padding-left: 10px;
              margin-right: 15px;
            }
            .song-text {
              display: flex;
              flex-direction: column;
              justify-content: center;
              margin-right: 25px;
              .song-head, .song-sub-head {
                font-family: 'PT Sans', sans-serif;
              }
              .song-head {
                font-size: calc(14px + (16 - 14) * (100vw - 330px) / (1920 - 330));
              }
              .song-sub-head {
                font-size: 13px;
                text-transform: none;
                opacity: 0.3;
              }
            }
          }
          .play-setting {
            margin-right: 15px;
            a {
              &:hover {
                color: $white;
                opacity: 0.7;
              }
            }
          }
          &:before, &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);
              /* right: 210%; */
              background-image: linear-gradient(90deg, $music-dark 0%, $music-light 100%);
              height: 100%;
              width: 180%;
              z-index: -1;
              opacity: 0.2;
              border-radius: 36px;
            }
            &:after {
              width: 260%;
            }
          }
        }
        &.bg-album {
          background: url("../images/music/album.jpg");
          background-attachment: fixed;
          background-position: center top;
        }
        &.bg-artist {
          background: url("../images/music/artist/bg.jpg");
          background-position: top;
          background-size: cover;
          background-repeat: no-repeat;
          background-attachment: fixed;
        }
        &.bg-gallery {
          background: url("../images/music/gallery/bg.jpg");
          background-position: top;
          background-size: cover;
          background-repeat: no-repeat;
          overflow: hidden;
          background-attachment: fixed;
        }
        &.bg-video {
          background: url("../images/music/video-bg.jpg");
          background-position: top;
          background-size: cover;
          background-repeat: no-repeat;
        }
        &.bg-footer {
          background: url("../images/music/footer-bg.jpg");
          background-position: top;
          background-size: cover;
          background-repeat: no-repeat;
        }
        &.sponsor {
          background: url("../images/music/sponser/bg.jpg");
          background-position: top;
          background-size: cover;
          background-repeat: no-repeat;
          .row {
            > div {
              &:last-child(1){
                .sponsor-img {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
        h4, h5, li {
          margin-top: 0;
          margin-bottom: 0;
        }
        h1, h2, h3, h4, h5, h6 {
          text-transform: capitalize;
        }
        h3, h4, h5, h6, li, a {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          line-height: normal;
        }
        h2, h3, h4, h5, h6, p {
          color: $secondary;
        }
        h1 {
          font-family: 'PT Sans', sans-serif;
          text-transform: uppercase;
          font-size: calc(22px + (40 - 22) * ((100vw - 300px) / (1920 - 300)));
          font-weight: 700;
          margin-top: -13px;
          margin-bottom: -20px;
          display: inline-block;
          letter-spacing: 0;
          span {
            font-size: 20px;
            opacity: 0.2;
            border-left: 3px solid $white;
            padding-right: 10px;
            padding-left: 8px;
            line-height: 16px;
            display: inline-block;
            vertical-align: middle;
          }
        }
        h2 {
          font-size: calc(20px + (40 - 20) * ((100vw - 300px) / (1920 - 300)));
          margin-top: -14px;
          margin-bottom: -16px;
          letter-spacing: 1px;
          font-weight: 700;
        }
        h3 {
          font-size: calc(18px + (35 - 18) * ((100vw - 300px) / (1920 - 300)));
        }
        h4 {
          font-size: calc(16px + (25 - 16) * ((100vw - 300px) / (1920 - 300)));
          margin-top: -3px;
          margin-bottom: -6px;
        }
        h5 {
          font-size: calc(14px + (20 - 14) * ((100vw - 300px) / (1920 - 300)));
          font-weight: 600;
          margin-top: -6px;
        }
        h6 {
          font-size: calc(13px + (18 - 13) * ((100vw - 300px) / (1920 - 300)));
          margin-top: 0;
          font-weight: 400;
        }
        p {
          font-size: calc(13px + (16 - 13) * ((100vw - 300px) / (1920 - 300)));
          margin-top: 0;
          font-weight: 200;
          margin-bottom: -7px;
          line-height: normal;
          color: rgba(255, 255, 255, 0.7);
        }
        &.header {
          .header-sub-text {
            margin-bottom: 0;
          }
        }
        .gradient-text {
          background: -webkit-linear-gradient($red3, $red3);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .progress {
          background: rgba(255, 255, 255, 0.1);
          border-radius: 0;
          height: 22px;
          .progress-bar {
            background-color: white;
          }
        }
        .title {
          &.title3 {
            .sub-title {
              p {
                letter-spacing: 1px;
              }
            }
          }
        }
        div {
          .album-list {
            padding: 55px 25px;
            border-radius: 8px;
            margin-bottom: 50px;
            margin-right: 25px;
            margin-left: 25px;
            .list-head {
              margin-bottom: 25px;
            }
            .item-sublist {
              margin-top: 18px;
              font-weight: 300;
              &:first-child {
                margin-top: 0;
              }
            }
          }
          &:nth-child(n+6) {
            .album-list {
              margin-bottom: 0;
            }
          }
        }
        .overlay-box {
          position: relative;
          overflow: hidden;
          background-color: $black2;
          border-radius: 8px;
          &:after {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            //background-color: $dark;
            background-image: linear-gradient($light, $dark);
            transition: opacity 0.6s ease-out;
            z-index: 2;
            opacity: 0;
          }
          h5, h6, img {
            position: relative;
            z-index: 3;
          }
          &:hover {
            &:after {
              transition: opacity 0.6s ease-out;
              opacity: 1;
            }
          }
        }
        &.format {
          .format-small-text {
            margin-bottom: 8px;
          }
          .format-head-text {
            margin-bottom: 10px;
          }
          .format-sub-text {
            margin-bottom: 55px;
            .sub-heading {
              margin-bottom: 35px;
            }
          }
          .link-horizontal {
            ul {
              li {
                a {
                  &.icon-btn {
                    i {
                      font-size: 16px;
                      height: 62px;
                      width: 62px;
                      color: $white;
                      border: 2px solid $primary;
                      background-color: $primary;
                    }
                    &:hover {
                      i {
                        color: $white;
                        background-color: $white;

                      }
                    }
                  }
                }
              }
            }
          }
        }
        &.artist {
          .album-artist {
            &:hover {
              .overlay-img {
                transition: 0.2s;
                filter: unset;
              }
              .cds {
                filter: unset;
                opacity: 1;
                .cd-animation {
                  animation: movebounce 20s linear infinite;
                  transition: all 0.3s ease;
                }
              }
            }
            position: relative;
            display: inline-block;
            margin-bottom: 150px;
            width: 100%;
            .overlay-img {
              z-index: 2;
              transition: 0.7s;
              filter: grayscale(100%);
              box-shadow: 2.5px 4.33px 76px 0 $black;
            }
            .singers {
              padding: 45px;
              .name {
                text-transform: uppercase;
              }
              .musician {
                font-weight: 300;
              }
            }
            .set-z-index {
              z-index: 2;
            }
            .cds {
              transition: all 0.3s ease;
              filter: grayscale(100%);
              z-index: 1;
              opacity: 0.2;
              top: 75%;
              left: 50%;
              transform: translate(-50%, -25%);
              .cd-animation {
                transition: all 0.3s ease;
              }
              .elipse {
                display: inline;
                width: auto;
                top: 50%;
                transform: translate(80%, -50%);
              }
              img {
                display: inline;
                width: auto;
              }
            }
          }
          .artist-text {
            padding: 0 15px;
            p {
              &.text-para {
                margin-bottom: 15px;
                font-size: 140%;
                font-weight: 300;
                letter-spacing: 0.5px;
                line-height: 1.8;
              }
            }
            .dummy-text {
              font-weight: 400;
            }
          }
        }
        .gallery-slider {
          &.owl-carousel {
            .overlay {
              background-image: unset;
              background-color: $black;
              opacity: 0;
              transition: 0.5s;
            }
            .text-container {
              opacity: 0;
              z-index: 2;
              .overlay-text {
                margin-bottom: -15px;
                font-weight: 300;
                span {
                  font-size: 200%;
                  display: block;
                }
              }
            }
            .owl-item {
              &.active {
                &.center {
                  .overlay {
                    transition: 0.5s;
                    opacity: 0.8;
                  }
                  .text-container {
                    opacity: 1;
                  }
                }
              }
            }
            .owl-nav {
              margin-top: 40px;
              .owl-prev, .owl-next {
                background: transparent;
                padding-bottom: 0;
                margin-bottom: 0;
              }
            }
          }
        }
        &.format {
          .hash-video {
            font-size: 14px;
            font-weight: 600;
          }
          .format-sub-text {
            .sub-heading {
              .sub-small-text {
                font-size: 16px;
                font-weight: 200;
              }
            }
            .sub-para {
              font-size: 14px;
              font-weight: 300;
            }
          }
          .video-text {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 10px;
          }
          .video-sub-text {
            font-size: 100%;
            font-weight: 400;
            margin-bottom: 35px;
          }
          .watch-now {
            height: unset;
            margin-left: 15px;
          }
          .watch {
            font-size: 110%;
          }
        }
        .contact-details {
          .contact-heading {
            font-weight: 400;
            margin-bottom: 15px;
            color: #591e1e;
          }
          .contact-sub-text {
            font-weight: 300;
            margin-bottom: 8px;
          }
        }
        .form-footer {
          .form-group {
            opacity: 0.7;
            margin-bottom: 30px;
            input, textarea {
              width: 100%;
              background-color: $black;
              padding: 15px 30px;
              border: 1px solid $light;
              border-radius: 25px;
              color: $white;
              &:focus {
                outline: none;
              }
              &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: $white;
                font-size: 16px;
                font-weight: 300;
                text-transform: capitalize;
              }
              &::-moz-placeholder { /* Firefox 19+ */
                color: $white;
                font-size: 16px;
                font-weight: 300;
                text-transform: capitalize;
              }
              &:-ms-input-placeholder { /* IE 10+ */
                color: $white;
                font-size: 16px;
                font-weight: 300;
                text-transform: capitalize;
              }
              &:-moz-placeholder { /* Firefox 18- */
                color: $white;
                font-size: 16px;
                font-weight: 300;
                text-transform: capitalize;
              }
            }
          }
          .booking {
            margin: 0 auto;
            .btn {
              margin-top: 5px;
              &:hover {
                background-color: $white;
                color: $music-dark-bg;
              }
            }
          }
        }
        &.blog{
          .img-container{
            .social-list{
              ul{
                li{
                  a{

                    i{
                      background-color: unset;
                      height: 28px;
                      width: 28px;
                      transition: all 0.3s;
                      &:hover{
                        background-color: white;
                        color: $primary;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .sponsor {
          &.bg-music {
            background: url("../images/music/sponser/bg.jpg");
            background-position: top;
            background-size: cover;
            background-repeat: no-repeat;
          }
          .set-margin {
            margin-bottom: -50px;
          }
          div {
            .sponsor-img {
              margin-bottom: 50px;
              .overlay-box {
                display: inline-block;
              }
            }
          }
        }
      }

      /*************************
      music css end
      *************************/

      /*************************
      22. resume-theme css start
      *************************/
      .bg-pink {
        background-color: $bg-light;
      }
      .resume {
        h1, h2 {
          font-family: 'Poppins', sans-serif;
          text-transform: capitalize;
        }

        h4, h5, li {
          margin-top: 0;
          margin-bottom: 0;
        }

        h1, h2, h3, h4, h5, h6 {
          text-transform: capitalize;
        }

        h2, h3, h4, h5, h6, li, a {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          line-height: normal;
        }

        h2, h3, h4 {
          color: $primary;
        }

        h6, p {
          color: $secondary;
        }

        h1 {
          font-size: calc(22px + (70 - 22) * ((100vw - 300px) / (1920 - 300)));
          color: $primary;
          margin-top: -20px;
          line-height: normal;
        }

        h2 {
          font-size: calc(20px + (35 - 20) * ((100vw - 300px) / (1920 - 300)));
          margin-top: -12px;
          margin-bottom: -4px;
        }

        h3 {
          font-size: calc(18px + (24 - 18) * ((100vw - 300px) / (1920 - 300)));
          margin-top: 0;
        }

        h4 {
          font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1920 - 300)));
        }

        h5 {
          font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1920 - 300)));
          margin-bottom: -2px;
          color: $primary;
        }

        h6 {
          font-size: calc(13px + (15 - 13) * ((100vw - 300px) / (1920 - 300)));
          margin-top: -6px;
          margin-bottom: -6px;
        }

        p {
          font-size: calc(13px + (15 - 13) * ((100vw - 300px) / (1920 - 300)));
          color: $gray8;
          font-weight: 400;
          margin-top: 0;
          margin-bottom: -6px;
        }
        &.fixed{
          background-color: $white; 
          border-bottom: 1px solid $black;
        }
        a {
          font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1920 - 300)));
          color: $primary;
          &:hover {
            text-decoration: none;
            cursor: pointer;
          }
          &:focus {
            outline: none;
          }
        }
        .center-text{
          > div{
            margin-top: -30px;
          }
        }
        &.pricing {
          margin-bottom: 0;
        }
        &.counter {
          .counters {
            .counter-img {
              opacity: 0.7;
            }
          }
        }
        &.header {
          .header-text {
            margin-bottom: 35px;
          }
          .header-sub-text {
            margin-bottom: 30px;
          }
        }
        &.about {
          .color-light {
            color: $gray5;
          }
          .border-container {
            margin: 15px 0;
            .borders-bottom {
              border-top: 2px solid $white7;
              width: 48px;
              margin: 0 auto;
            }
          }
        }
        .filter-section .filter-container ul.filter > li {
          border-radius: 25px;
        }
        .radial-bar {
          font-size: 20px;
          font-weight: 600;
          position: relative;
          display: inline-block;
          border-radius: 50%;
          width: 94px;
          height: 94px;
          &.radial-bar-primary {
            background-image: linear-gradient(432deg, $primary 50%, transparent 50%, transparent), linear-gradient(270deg, $primary 50%, $gray13 50%, $gray13);
          }
          &.radial-90 {
            background-image: linear-gradient(399deg, $primary 50%, transparent 50%, transparent), linear-gradient(270deg, $primary 50%, $gray13 50%, $gray13);
          }
          &.radial-80 {
            background-image: linear-gradient(350deg, $primary 50%, transparent 50%, transparent), linear-gradient(270deg, $primary 50%, $gray13 50%, $gray13);
          }
          &.radial-70 {
            background-image: linear-gradient(310deg, $primary 50%, transparent 50%, transparent), linear-gradient(270deg, $primary 50%, $gray13 50%, $gray13);
          }
          &:after {
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 50%;
            text-align: center;
            color: $primary;
            content: attr(data-label);
            background-color: $white;
            z-index: 1;
            width: 88px;
            height: 88px;
            margin-left: 3px;
            margin-top: 3px;
            line-height: 90px;
            font-weight: 600;
          }
        }
      }
      /*************************
      resume-theme css end
      *************************/

      /*************************
      23. wedding-theme css start
      *************************/
      .wedding {
        h1, h2, h3, h4, h5, h6, p, li, a {
          color: $primary
        }
        h2, h3, h4, h5, h6, li, a {
          font-family: 'Poppins', sans-serif;
        }
        h1 {
          font-size: calc(22px + (200 - 22) * ((100vw - 300px) / (1920 - 300)));
          font-family: 'Alex Brush', cursive;
          margin-top: -33px;
          margin-bottom: -35px;
          line-height: normal;
        }
        h2 {
          font-size: calc(20px + (35 - 20) * ((100vw - 300px) / (1920 - 300)));
          margin-top: -10px;
          margin-bottom: -7px;
          font-weight: 600;
        }
        h3 {
          margin-top: 0;
        }
        h5 {
          font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1920 - 300)));
        }
        h6 {
          font-size: calc(13px + (16 - 13) * ((100vw - 300px) / (1920 - 300)));
          margin-top: 0;
          margin-bottom: -3px;
        }
        p {
          font-size: calc(13px + (16 - 13) * ((100vw - 300px) / (1920 - 300)));
          margin-top: 0;
          margin-bottom: -2px;
        }
        .decore {
          img {
            position: absolute;
            &:nth-child(1) {
              top:0;
              right: 0;
            }
            &:nth-child(2) {
              bottom: 0;
            }
          }
        }
        &.format {
          .format-head-text {
            margin-bottom: 15px;
          }
          .format-sub-text {
            .about-para {
              margin-bottom: 15px;
              font-weight: 300;
            }
          }
        }
        .btn-default {
          padding: 16px 35px;
          font-size: calc(13px + (18 - 13) * ((100vw - 300px) / (1920 - 300)));
        }
        .header-sub-text {
          margin-bottom: 25px;
          letter-spacing: 1px;
        }
        .rightfadediv {
          width: 160px;
        }
        &.bg {
          background-size: cover;
          background-repeat: no-repeat;
          background-position: bottom;
          &.slider-bg {
            background-image: url("../images/wedding-img/slider/slider-bg.jpg");
            background-attachment: fixed;
          }
          &.wedding-bg {
            background-image: url("../images/wedding-img/bg2.jpg");
          }
          &.jenny-bg {
            background-image: url("../images/wedding-img/backgrounds/jenny-bg.png");
          }
          &.countdown-bg {
            background-image: url("../images/wedding-img/backgrounds/countdown-bg.png");
            .counter-container{
              .gradient-text{
                margin-top: 20px;
                line-height: 1.1;
              }
            }
          }
          &.when-where-bg {
            background-image: url("../images/wedding-img/backgrounds/when-where.png");
          }
          &.wishes-bg {
            background-image: url("../images/wedding-img/backgrounds/wishes.png");
          }
          &.attend-bg {
            background-image: url("../images/wedding-img/backgrounds/attend.png");
          }
        }
        .owl-theme {
          .owl-dots {
            margin-top: 45px !important;
          }
        }
        .gradient-text {
          background: -webkit-linear-gradient($light, $dark);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-weight: 600;
        }
        .wedding-banner {
          .frem {
            position: relative;
          }
          .text-container {
            .couple-text {
              .banner-text {
                font-family: 'Alex Brush', cursive;
                font-size: calc(20px + (80 - 20) * ((100vw - 300px) / (1920 - 300)));
                font-weight: 400;
                margin-bottom: 0;
                margin-top: 0;
                line-height: normal;
                &.small {
                  font-size: calc(18px + (65 - 18) * ((100vw - 300px) / (1920 - 300)));
                }
              }
            }
            .place {
              font-size: 35px;
              font-weight: 400;
            }
            .address {
              font-weight: 300;
            }
          }
        }
        .schedule {
          .zexson-text {
            font-weight: 400;
          }
          .schedule-date {
            font-weight: 400;
          }
        }
        .when-slider {
          &.owl-theme {
            .owl-nav {
              margin: 0;
              .owl-prev, .owl-next {
                background: transparent;
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
              }
              .owl-prev {
                left: -130px;
              }
              .owl-next {
                right: -130px;
              }
            }
          }
        }
        &.blog{
          .btn{
            &.btn-blog{
              &:hover{
                color: white;
                background-color: #c0882f;
              }
            }
          }
        }
        .title{
          .main-title{
            h2{
              line-height: normal;
            }
          }
        }
        .navbar-nav{
          padding-bottom: 0;
          &+.navbar-nav{
            padding-top: 0;
          }
        }
      }
      /*************************
      wedding-theme css end
      *************************/

      /*************************
      24. yoga-theme css start
      *************************/
      .yoga {
        h4, h5, li {
          margin-top: 0;
          margin-bottom: 0;
        }
        h1, h2, h3, h4, h5, h6 {
          text-transform: capitalize;
        }
        h1, h2, h3, h4, h5, h6, p, li, a {
          font-family: 'Poppins', sans-serif;
          line-height: normal;
        }
        h1 {
          font-size: calc(22px + (60 - 22) * ((100vw - 300px) / (1920 - 300)));
          font-weight: 600;
          margin-top: -18px;
          margin-bottom: 0;
          line-height: normal;
        }
        h2 {
          font-size: calc(20px + (40 - 20) * ((100vw - 300px) / (1920 - 300)));
          margin-top: -14px;
          margin-bottom: 0;
        }
        h3 {
          font-size: calc(18px + (30 - 18) * ((100vw - 300px) / (1920 - 300)));
          margin-top: 0;
        }
        h6 {
          font-size: calc(13px + (16 - 13) * ((100vw - 300px) / (1920 - 300)));
          margin-top: 0;
          margin-bottom: 0;
        }
        p {
          font-size: calc(13px + (16 - 13) * ((100vw - 300px) / (1920 - 300)));
          margin-top: 0;
          margin-bottom: -2px;
          letter-spacing: 1px;
        }
        &.fixed{
          background: -webkit-linear-gradient($light, $dark);
        }

        .gradient-text {
          background: -webkit-linear-gradient($light, $dark);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        &.header {
          .header-text {
            margin-bottom: 20px;
          }
          .header-sub-text {
            margin-bottom: 25px;
          }
        }
        &.format {
          .format-head-text {
            margin-bottom: 30px;
            .about-font-header {
              span {
                font-weight: 200;
              }
            }
          }
          .format-sub-text {
            margin-bottom: 50px;
          }
        }
        .trainers-slider {
          &.owl-carousel {
            .owl-item {
              &.active {
                &.center {
                  .item {
                    img {
                      box-shadow: 0 0 10px 0 $white10 !important;
                    }
                  }
                }
              }
            }
          }
        }
        &.brand-slider {
          padding: 50px 0;
        }
        &.img-1 {
          background: url("../images/yoga-img/schedule-bg.jpg");
          &.bg {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
        .owl-theme {
          .owl-dots {
            margin-top: 0 !important;
          }
        }
        .meter {
          position: relative;
          width: 100%;
          height: 42px;
          margin-top: 50px;
          border-radius: 20px;
          background: $light-inner;
        }
        .meter {
          .progress {
            display: block;
            height: 40px;
            border-radius: 25px;
            background-image: linear-gradient(96deg, $light 0%, $dark 100%);
            animation: grower 1s linear;
            position: relative;
            border: none;
            h6{
              margin-top: unset;
            }
            &.progress1, &.progress2, &.progress3, &.progress4 {
              position: relative;
            }
            &.progress1 {
              width: 90%;
            }
            &.progress2 {
              width: 80%;
            }
            &.progress3 {
              width: 85%;
            }
            &.progress4 {
              width: 70%;
            }
          }
          .lorem {
            color: $white;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            padding-left: 20px;
          }
          .percent {
            color: $white;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            right: 20px;
          }
        }
        .meter p {
          position: absolute;
          top: 0;
          margin: 0 10px;
          font-weight: bold;
          -webkit-font-smoothing: antialiased;
          font-size: 15px;
          color: $gray10;
          text-shadow: 0 1px rgba(255, 255, 255, 0.6);
        }
        @keyframes grower {
          0% {
            width: 0;
          }
        }
        &.event {
          h4{
            margin-top: unset;
          }
          h2{
            margin-bottom: unset;
          }
          .row {
            div {
              &:nth-child(n+5) {
                .event-container {
                  margin-bottom: 0;
                }
              }
            }
          }
          .event-container {
            margin-bottom: 80px;
            h6 {
              font-size: 14px;
            }
            .yoga-circle {
              height: 90px;
              width: 90px;
              background: $white;
              border-radius: 100%;
              padding: 18px 20px;
              text-align: center;
              position: relative;
              left:0;
              display: inline-block;
              .gradient-text {
                font-weight: 600;
                text-align: center;
                line-height: 1.3;
              }
              .month {
                color: $primary;
              }
            }
            .event-info {
              margin-left: 20px;
              height: unset;
              align-items: unset;
              .address {
                font-weight: 400;
              }
              .time, .address {
                margin-bottom: 15px;
              }
              .time, .city {
                font-weight: 300;
              }
            }
          }
        }
        &.format {
          .experience-container {
            min-height: 455px;
            .exp-img {
              width: 630px;
              position: absolute;
            }
          }
          .girl-img-container {
            min-height: 543px;
            .girl-yoga {
              width: 600px;
              position: absolute;
              left: 50%;
              transform: translate(-50%, 0);
            }
          }
          .icon-collection {
            li {
              a {
                img {
                  filter: brightness(0) invert(1);
                  transition: 0.5s;
                }
              }
              &:hover {
                a {
                  img {
                    filter: unset;
                    transition: 0.5s;
                  }
                }
              }
            }
          }
        }
        &.blog{
          .img-container{
            .social-list{
              ul{
                li{
                  a{

                    i{
                      background-color: unset;
                      height: 28px;
                      width: 28px;
                      transition: all 0.2s;
                      &:hover{
                        background-color: white;
                        color: #BA38D2;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .yoga-gradiant{
          background-image: linear-gradient(96deg, #BA38D2 0%, #6851DF 100%)!important;
        }
        .navbar-nav{
          padding-bottom: 0;
          &+.navbar-nav{
            padding-top: 0;
          }
        }
      }
      /*************************
      yoga-theme css end
      *************************/

      /*************************
      25. agency css start
      *************************/
      .agency {
        h1, h2 {
          font-family: 'Poppins', sans-serif;
          text-transform: capitalize;
        }
        h4, h5, li {
          margin-top: 0;
          margin-bottom: 0;
        }
        h1, h2, h3, h4, h5, h6 {
          font-family: 'Poppins', sans-serif;
          text-transform: capitalize;
        }
        h2, h3, h4, h5, h6, li, a {
          font-weight: 500;
        }
        h1, h2, h3, h4, h5, h6 {
          color: $primary;
        }
        p {
          color: $secondary;
        }
        h1 {
          font-size: calc(22px + (45 - 22) * ((100vw - 300px) / (1920 - 300)));
          letter-spacing: 2px;
          margin-top: -17px;
          font-weight: 200;
          span {
            &.bold-text {
              font-weight: 600;
            }
          }
        }
        h2 {
          font-size: calc(20px + (30 - 20) * ((100vw - 300px) / (1920 - 300)));
          margin-top: -3px;
          margin-bottom: -12px;
          font-weight: 700;
          line-height: 1.2;
        }
        h3 {
          font-size: calc(18px + (24 - 18) * ((100vw - 300px) / (1920 - 300)));
          line-height: 1.4;
        }
        h5 {
          font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1920 - 300)));
          font-weight: 300;
          letter-spacing: 0;
          padding-top: 5px;
        }
        h6 {
          font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1920 - 300)));
          margin-bottom: -5px;
          font-weight: 300;
          line-height: normal;
          &.borders-before {
            margin-left: 40px;
            margin-bottom: 25px;
            text-transform: uppercase;
            span {
              &:before {
                border-top: 2px solid $primary;
                width: 24px;
              }
            }
          }
        }
        &.fixed{
          background-color: $primary;
          .logo-light{
            img{
              filter: brightness(0) invert(1);
            }
          }
        }
        p {
          font-size: calc(13px + (15 - 13) * ((100vw - 300px) / (1920 - 300)));
          font-weight: 400;
        }
        .btn {
          text-transform: uppercase;
        }
        a {
          text-transform: capitalize;
          letter-spacing: 1px;
        }
        &.about-bg {
          background-position: top;
          background-repeat: no-repeat;
          background-size: cover;
          position: relative;
        }
        &.service-bg {
          background: url("../images/agency/service/services-backgrondpng.png");
          background-position: top;
          background-repeat: no-repeat;
          background-size: cover;
          position: relative;
          overflow: hidden;
          padding-bottom: 0;
        }
        &.video-bg {
          background: url("../images/agency/videos/video-background.png");
          background-position: top;
          background-repeat: no-repeat;
          background-size: cover;
          position: relative;
        }
        &.counter-bg {
          background: url("../images/agency/counter/counter-background.png");
          background-position: top;
          background-repeat: no-repeat;
          background-size: cover;
          position: relative;
        }
        &.price-bg {
          background: url("../images/agency/price/bg.png");
          background-position: top;
          background-repeat: no-repeat;
          background-size: cover;
          position: relative;
        }
        &.expert-bg {
          background: url("../images/agency/team/expert-background.png");
          background-position: top;
          background-repeat: no-repeat;
          background-size: cover;
          position: relative;
        }
        &.blog-bg {
          background: url("../images/agency/blog/background.png");
          background-position: top;
          background-repeat: no-repeat;
          background-size: cover;
          position: relative;
        }
        &.testimonial-bg {
          background: url("../images/agency/testimonial/bg.png");
          background-position: top;
          background-repeat: no-repeat;
          background-size: cover;
          position: relative;
          span{
            color: rgba($white, 0.7);
          }
          .borders-before{
            span:before{
              border-color: rgba($white, 0.7);
            }
          }
          .testimonial-container{
            &.testimonial-container2{
              box-shadow: inset 0 0 5px 0 rgba(0,0,0,0.15);
            }
          }
        }
        .font-600 {
          font-weight: 600;
        }
        &.header {
          .header-top-line {
            font-weight: 400;
          }
          .header-sub-text {
            p {
              font-size: calc(13px + (18 - 13) * ((100vw - 300px) / (1920 - 300)));
              letter-spacing: 0;
            }
          }
          .btn-default {
            border-radius: 50px;
            font-weight: 600;
            letter-spacing: 1px;
          }
        }
        .girl-boy {
          right: 13%;
          top: 50%;
          transform: translate(0, -50%);
        }
        .circle-container {
          position: absolute;
          bottom: 33%;
          right: -15%;
          &.small {
            position: absolute;
            top: 3%;
            left: -6%;
          }
          .gradient-circle-big {
            height: 110px;
            width: 110px;
            border-radius: 100%;
            background-image: linear-gradient(90deg, $light, $dark);
          }
          .gradient-circle-small {
            height: 68px;
            width: 68px;
            border-radius: 100%;
            background-image: linear-gradient(90deg, $light, $primary);
          }
        }
        .plus-container {
          position: absolute;
          top: 15%;
          left: 5%;
          animation: star 1s infinite;
          &.plus-container2 {
            top: 25%;
            left: 25%;
          }
          &.plus-container3 {
            top: 50%;
            left: 2%;
          }
          &.plus-container4 {
            top: 65%;
            left: 32%;
          }
          &.plus-container5 {
            top: 80%;
            left: 10%;
          }
          &.plus-container6 {
            top: 80%;
            left: 65%;
          }
          &.plus-container7 {
            top: 40%;
            left: 35%;
          }
          &.plus-container8 {
            top: 40%;
            left: 98%;
          }
          &.plus-container9 {
            top: 45%;
            left: 70%;
          }
          &.plus-container10 {
            top: 0;
            left: 8%;
          }
          &.plus-container11 {
            top: 70%;
            left: 4%;
          }
          .plus {
            position: relative;
            &:before, &:after {
              content: '';
              position: absolute;
              border-radius: 2px;
              color: $primary;
            }
            &:before {
              border-left: 6px solid;
              height: 19px;
              left: 50%;
              transform: translate(-50%, -50%);
            }
            &:after {
              border-top: 6px solid;
              width: 19px;
              top: 50%;
              transform: translate(-50%, -50%);
            }
            &.white {
              &:before, &:after {
                color: $white;
              }
            }
            &.plus-medium {
              &:after {
                width: 16px;
                border-top: 5px solid;
              }
              &:before {
                height: 16px;
                border-left: 5px solid;
              }
            }
            &.plus-small {
              &:after {
                width: 9px;
                border-top: 3px solid;
              }
              &:before {
                height: 9px;
                border-left: 3px solid;
              }
            }
          }
        }
        @keyframes star {
          0% {
            transform: scale(1);
          }
          50% {
            transform: scale(0.8);
          }
          100% {
            transform: scale(1);
          }
        }
        .center-content {
          .icon-collection{
            text-align: center;
          }
          &.unset-height {
            height: unset;
          }
        }
        .icon-collection {
          display: inline-block;
          .about-icon {
            padding: 0;
            margin-bottom: 60px;

            &:last-child {
              margin-bottom: 0;
            }
            &:hover {
              a {
                img {
                  filter: brightness(0) invert(1);
                }
              }
            }
          }
        }
        &.pricing {
          .price-container {
            &.shadows {
              box-shadow: 2px 1px 10px 0 rgba(178, 178, 178,0.5);
              border: 1px solid $lighten-white2;
              border-radius: 5px;
              text-align: center;
              filter: none;
            }
          }
        }
        &.format {
          .format-head-text {
            margin-bottom: 25px;
          }
          .format-sub-text {
            margin-bottom: 30px;
            .about-para {
              letter-spacing: 1px;
            }
          }
          .borders-before {
            span {
              font-size: 16px;
              font-weight: 500;
            }
          }
          .about-icon {
            margin-left: 18px;
            margin-right: 18px;
          }
        }
        .icon-collection {
          .about-icon {
            a {
              padding: 45px 25px;
            }
          }
        }
        .block-span {
          display: block;
        }
        &.video {
          .d-inline-block {
            .bg-video {
              width: 70%;
            }
          }
          .video-text {
            letter-spacing: 1px;
            margin-bottom: -5px;
            text-transform: uppercase;
          }
          .video-heading {
            font-size: calc(18px + (35 - 18) * ((100vw - 300px) / (1920 - 300)));
            margin-bottom: 25px;
          }
        }
        &.counter {
          .counter-text {
            .count-number {
              h2 {
                font-weight: 500;
              }
            }
            .count-desc {
              h6 {
                font-weight: 400;
              }
            }
          }
        }
        &.testimonial {
          .testimonial-container {
            &.testimonial-container2 {
              background: white;
              border-radius: 20px;
              padding: 40px 40px;
              .center-content {
                height: unset;
                align-items: unset;
                .name {
                  font-size: 16px;
                  font-weight: 500;
                  color: $secondary;
                  margin-bottom: 5px;
                }
                .post {
                  font-weight: 500;
                }
              }
            }
            .primary-border {
              border-bottom: 4px solid $primary;
              border-radius: 1px;
              width: 33px;
            }
          }
          .testimonial-slider {
            .owl-nav {
              position: absolute;
              top: -80px;
              right: 0;
              .owl-prev, .owl-next {
                background: transparent;
              }
            }

          }
        }
        &.blog {
          .blog-agency {
            .blog-contain {
              border: 1px solid $border-blog;
              background: $white;
              border-radius: 10px;
              overflow: hidden;
              filter: drop-shadow(0 0 10px #eee);
              transition: all 0.3s;
              &:hover{
                box-shadow: 1px 1px 10px 1px #eee;
              }
              .img-container {
                .blog-head {
                  color: $primary;
                  text-transform: none;
                  font-weight: 600;
                  margin-bottom: 10px;
                  padding-top: 0;
                }
                i {
                  font-size: 16px;
                  margin-right: 10px;
                  color: $gray6;
                }
                .blog-info {
                  padding: 28px;
                  .font-blog {
                    color: $gray6;
                  }
                }
              }
              .btn-bottom {
                a {
                  transition: 0.5s;
                  &:hover {
                    opacity: 0.8;
                    transition: 0.5s;
                  }
                }
              }
            }
          }
        }
        &.footer2{
          .footer-contant{
            .footer-headings{
              color: white;
              padding-bottom: 45px;
            }
          }
        }
        .para2 {
          padding: 0;
          margin-bottom: 30px;
          letter-spacing: 0;
          color: $gray7;
        }
        &footer{
          .footer-lists {
            li {
              a {
                &:hover {
                  color: $color-blue;
                }
              }
            }
          }
          .footer-social {

            .footer-social-list {
              a {
                &:hover {
                  color: $color-blue;
                }
              }
            }
          }
        }
        &.speaker{
          .team-img{
            .social{
              ul{
                li{
                  a{
                    i{
                      transition: all 0.4s;
                      &:hover{
                        background-color: white;
                        color: #061a5e;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .agency-content {
        .agency-header-center-container {
          display: flex;
          align-items: center;
          margin-bottom: 40px;
          .agency-head {
            display: flex;
            justify-content: center;
            align-items: center;
            .agency-head-text {
              font-size: 308%;
              font-weight: 600;
              margin-top: -16px;
              margin-bottom: -16px;
            }
          }
          .borders {
            margin-right: 30px;
            color: $primary;
            border-right: 2px solid;
            height: 80px;
          }
        }
        .agency-para {
          font-size: calc(13px + (17 - 13) * ((100vw - 300px) / (1920 - 300)));
          letter-spacing: 0;
          margin-left: 30px;
          margin-bottom: 30px;
        }
        .btn-default {
          margin-left: 30px;
        }
        .side-img {
          z-index: 2;
        }
        .blue-img {
          position: absolute;
          left: -14%;
          bottom: 0;
          z-index: 1;
        }

      }
      /*************************
      agency css end
      *************************/

      /*************************
      27. blog css start
      *************************/
      .blog {
        .img-container {
          display: block;
          .blog-info {
            padding: 28px;
            bottom: 0;
            p {
              margin-bottom: unset;
            }
            .blog-text {
              letter-spacing: 0;
              margin-bottom: 15px;
            }
            .font-blog, .blog-text, .borders-before, .small-font, i {
              color: $white;
            }
            .font-blog {
              font-weight: 400;
              text-transform: none;
            }
            .borders-before, .small-font {
              font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1920 - 300)));
              text-transform: none;
              font-weight: 300;
            }
            .borders-before {
              margin-left: 16px;
              margin-bottom: 10px;
              span {
                &:before {
                  margin-right: 7px;
                  width: 8px;
                }
              }
            }
            .small-font {
              margin-bottom: 18px;
              line-height: 20px;
            }
          }
          .social-list {
            ul {
              display: flex;
              li {
                padding: 0 5px;
                text-transform: uppercase;
                a {
                  display: inline-block;
                  background: linear-gradient(to top, $light, $dark);
                  height: 28px;
                  width: 28px;
                  border-radius: 100%;
                  i {
                    background-color: $red2;
                    font-size: 13px;
                    height: 25px;
                    width: 25px;
                    border-radius: 100%;
                    display: flex;
                  }
                }
              }
            }
          }
        }
        &.wedding {
          .blog-container {
            .set-skew {
              clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
            }
            .blog-text {
              margin-bottom: 5px;
              font-weight: 700;
            }
            .blog-date {
              font-weight: 600;
              margin-bottom: 05px;
              margin-left: -10px;
            }
            .blog-place {
              font-weight: 600;
              margin-bottom: 20px;
              margin-left: -20px;
            }
            .blog-para {
              text-indent: 10px;
              margin-bottom: 20px;
              margin-left: -40px;
            }
          }
          .owl-theme {
            .owl-dots {
              .owl-dot {
                &.active {
                  span {
                    width: 40px;
                  }
                }
                span {
                  margin-top: 0;
                  margin-bottom: 0;
                  background-image: linear-gradient($light, $dark);
                }
              }

            }
          }
          .btn {
            &.btn-blog {
              background: white;
              border: 1px solid $primary;
              color: $primary;
              font-size: 12px;
              margin-left: -60px;
              border-radius: 0;
              text-transform: capitalize;
            }
          }
        }
      }
      /*************************
      blog  css end
      *************************/

      /*************************
      28. counter css start
      *************************/
      .counter {
        .counters {
          text-align: center;
          .counter-img {
            margin-bottom: 20px;;
          }
          .count-number {
            margin-bottom: 20px;
          }
          .counter-text {
            color: $white;
          }
          &.hover-counter {
            padding: 35px 0;
            transition: 0.8s;
            &:hover, &.active {
              background-color: $white;
              filter: drop-shadow(0 0 5px $white4);
              cursor: pointer;
              transition: 0.8s;
            }
          }
        }
        &.event {
          .counters {
            .counter-text {
              .count-desc {
                text-transform: uppercase;
              }
            }
          }
        }
        &.resume {
          .counters {
            .counter-text {
              .count-text {
                font-size: 230%;
              }
            }
          }
        }
      }
      /*************************
      counter css end
      *************************/

      /*************************
      29. contact css start
      *************************/
      .contact {
        .iframe-container {
          width: 100%;
          height: 100%;
          iframe {
            height: 100%;
            width: 100%;
          }
        }
        .set-z-index {
          z-index: 3;
        }
        .bg-white {
          background-color: $white;
          form {
            padding: 60px;
            .form-group {
              margin-bottom: 60px;
              .form-control {
                border: none;
                border-radius: 0;
                border-bottom: 1px solid $lighten-white2;
                padding: 0;
                background: transparent;
                &:focus {
                  box-shadow: none;
                }
              }
              label {
                color: $primary;
                margin-bottom: 0;
                font-weight: 500;
              }
            }
            .btn-default {
              padding: 14px 30px;
              letter-spacing: 1px;
            }
          }
        }
        .plane {
          right: 18%;
        }
        .plane2 {
          opacity: 0.5;
          z-index: 1;
          top: 0;
          right: 0;
        }
        &.event {
          .title {
            z-index: 2;
            position: relative;
          }
        }
      }
      /*************************
      contact css end
      *************************/

      /*************************
      30. schedule css start
      *************************/
      .schedule {
        .event-container {
          padding-right: 60px;
          max-height: 750px;
          overflow-y: scroll;
          overflow-x: hidden;
        }
        .cal-time {
          background-color: $bg-light;
          border-radius: 15px;
          padding: 24px;
          width: 100%;
          position: relative;
          margin-bottom: 35px;
          &:last-child {
            margin-bottom: 0;
          }
          .time {
            height: 100%;
            justify-content: center;
            display: flex;
            align-items: center;
            .monday-text {
              font-weight: 600;
            }
          }
          i {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            color: $gray9;
            font-size: 25px;
            &.fa-angle-left {
              left: 35px;
            }
            &.fa-angle-right {
              right: 35px;
            }
          }

          &.events {
            height: unset;
            background-color: $bg-light;
            padding: 40px 80px 40px 40px;
            &:last-child {
              .timing {
                .event-time {
                  &:last-child {
                    &:before {
                      border-left: none;
                    }
                  }
                }
              }
            }
            .schedule-event {
              .names {
                font-weight: 600;
                margin-bottom: 20px;
                text-transform: uppercase;
                letter-spacing: 0;
              }
              .schedule-img {
                margin-bottom: 25px;
              }
              .schedule-description {
                color: $gray8;
                font-size: 150%;
                letter-spacing: 0;
                margin-bottom: -4px;
              }
            }
            .timing {
              text-align: center;
              .event-time {
                color: $gray9;
                position: relative;
                padding: 8px;
                &.active {
                  margin: 0 auto;
                  height: 30px;
                  width: 72px;
                  border-radius: 14px;
                  background-color: $primary;
                  color: $white;
                }
                &:last-child {
                  &:before {
                    content: '';
                    margin: 18px 0;
                    border-left: 2px dashed $gray9;
                    height: 92px;
                    display: inline-block;
                    position: absolute;
                    top: 20px;
                    left: 50%;
                    z-index: 9;
                  }
                }
              }
              .border-container {
                .border-dashed {
                  margin: 8px 0;
                  border-left: 2px dashed $gray9;
                  height: 55px;
                  display: inline-block;
                  &.active {
                    border-left: 2px dashed $secondary;
                  }
                }
              }
            }
          }
        }
        .scrollbar {
          overflow-y: scroll;
          &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px $lighten-white2;
            border-radius: 10px;
            background-color: $lighten-white2;
          }

          &::-webkit-scrollbar {
            width: 10px;
            background-color: $lighten-white2;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: $primary;
            padding: 5px;
          }
        }

        .force-overflow {
          min-height: 450px;
        }
      }
      /*************************
      schedule css end
      *************************/

      /*************************
      32. testimonial css start
      *************************/
      .testimonial {
        &.event, &.saas2 {
          .testimonial-msg {
            z-index: 2;
            display: inline-block;
            .msg-box {
              height: 65px;
              width: 65px;
              position: absolute;
              right: -22px;
              top: -30px;
              background-color: $white;
              border-radius: 100%;
              z-index: -1;
            }
          }
          .quotes {
            .left-quote {
              left: -33px;
              top: -29px;
            }
            .right-quote {
              right: 80px;
              top: 60px;
            }
            .quote-text {
              h5 {
                font-weight: 400;
                color: $white;
              }
            }
          }
          .rating {
            .stars {
              display: inline-block;
              margin-right: 25px;
              i {
                color: $white;
                font-size: 20px;
                &.yellow {
                  color: $yellow3;
                }
              }
            }
            .name {
              color: $white;
              display: inline-block;
              text-transform: uppercase;
              span {
                font-weight: 300;
              }
            }
          }
          img {
            width: auto !important;
          }
          .owl-carousel {
            .owl-stage-outer {
              height: auto !important;
            }
          }
          .testimonial {
            &.owl-theme {
              .owl-next, .owl-prev {
                background: transparent;
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
              }
              .owl-prev {
                left: 0;
              }
              .owl-next {
                right: 0;
              }
            }
          }
        }
        &.event {
          .owl-nav {
            margin: 0;
          }
        }
        &.gym {
          background: url('../images/gym/testimonial/testimonial-bg.jpg');
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
        }
        &.music {
          background-position: center;
          .testimonial-info {
            .font-large {
              font-weight: 600;
            }
            .font-small {
              font-weight: 300;
            }
          }
        }
        &.music {
          .owl-nav {
            margin: 0;
          }
        }
        &.gym, &.music, &.wedding, &.yoga, &.agency, &.creative {

          .testimonial-info {
            margin-top: 50px;
            padding: 0 30px;
            .testimonial-para {
              color: $white;
              margin-bottom: 40px;
              font-weight: 300;
              font-size: 140%;
              line-height: 1.8;
              letter-spacing: 1px;
            }
            .font-large {
              font-size: 130%;
            }
            .font-small {
              font-size: 95%;
              margin-bottom: -5px;
            }
          }
          .testimonial-container {
            padding: 30px;
            background-color: white;
            .border-around {
              padding: 25px 0;
              border-left: 1px solid $dark;
              border-right: 1px solid $light;
              border-image: linear-gradient(to right, $dark, $light);
              border-image-slice: 1;
              border-top: 1px solid;
              border-bottom: 1px solid;
            }
          }
          .testimonial-slider {
            &.owl-theme {
              .owl-dots {
                line-height: 0;
                .owl-dot {
                  span {
                    width: 12px;
                    height: 12px;
                    opacity: 1;
                    background: transparent;
                    border: 2px solid $white;
                    margin-bottom: 0;
                  }
                  &.active {
                    span {
                      border: none;
                      background-image: linear-gradient($white, $white);
                    }
                  }
                }
              }
            }
          }
          .music-testimonial-slider {
            &.owl-theme {
              .owl-nav {
                .owl-prev, .owl-next {
                  background-color: transparent;
                  position: absolute;
                  top: 50%;
                  transform: translate(-50%, 0);
                  font-size: 18px;
                }
                .owl-prev {
                  left: -12%;
                }
                .owl-next {
                  right: -12%;
                }
              }
              .item {
                img {
                  width: auto !important;
                }
              }
            }
          }
          img {
            width: auto !important;
          }
        }
        &.agency {
          .testimonial-info {
            padding: 0;
            margin-top: 20px;
          }
        }
        &.yoga {
          .testimonial-info .testimonial-para {
            font-weight: 300;
            padding: 0 100px;
            line-height: 2;
          }
        }
        &.wedding {
          .testimonial-info {
            .testimonial-para {
              color: $primary;
              font-weight: 200;
            }
          }
        }
      }
      /*************************
      testimonial css end
      *************************/

      /*************************
      33. tap to top css start
      *************************/
      .tap-top {
        width: 50px;
        height: 50px;
        position: fixed;
        bottom: 50px;
        right: 30px;
        z-index: 99;
        color: $white;
        text-align: center;
        background: $dark;
        border-radius: 100%;
        font-size: 22px;
        cursor: pointer;
        line-height: 2.2;
        display: none;
        border: 1px solid $white;
      }
      /*************************
      tap to top css end
      *************************/

      /*************************
      35. saas1 css start
      *************************/
      .saas1 {
        h1, h2, h3, h4, h5, h6, li, a {
          font-family: 'Poppins', sans-serif;
        }
        h2, h3, h4, h5, h6, p {
          color: $secondary;
        }
        h1 {
          font-size: calc(22px + (62 - 22) * ((100vw - 300px) / (1920 - 300)));
          font-weight: 600;
          margin-top: -17px;
          margin-bottom: -8px;
          line-height: normal;
        }
        h2 {
          font-size: calc(20px + (40 - 20) * ((100vw - 300px) / (1920 - 300)));
          font-weight: 600;
        }
        h3 {
          font-size: calc(18px + (24 - 18) * ((100vw - 300px) / (1920 - 300)));
          margin-top: 0;
          margin-bottom: 0;
        }
        h5 {
          font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1920 - 300)));
          letter-spacing: 0.085em;
          margin-top: -12px;
          margin-bottom: -12px;
        }
        h6 {
          font-size: calc(13px + (16 - 13) * ((100vw - 300px) / (1920 - 300)));
          letter-spacing: 0.025em;
          margin-top: 0;
          margin-bottom: 0;
        }
        p {
          font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1920 - 300)));
          margin-top: 0;
          letter-spacing: 0.05em;
          margin-bottom: -7px;
        }
        span {
          text-transform: none;
          &.theme-color {
            color: $primary;
          }
        }
        .title {
          img {
            display: inline-block;
            margin-right: 7px;
            vertical-align: baseline;
          }
          .main-title {
            display: inline-block;
            margin-bottom: 0;
          }
          hr {
            width: 116px;
            height: 2px;
            background-color: $primary;
            margin-top: 20px;
            margin-bottom: 20px;
          }
          .sub-title {
            p {
              margin-bottom: 0;
            }
            .p-padding {
              padding: 0 30px;
            }
          }
        }
        .center-text{
          > div{
            margin-top: -25px;
          }
        }
        &.service {
          .chat-box {
            img {
              height: 80px;
            }
          }
        }
        &.header {
          h1 {
            span {
              text-transform: none;
            }
          }
          .slider-logo {
            margin-left: 15px;
            align-self: center;
          }
          .header-text {
            margin-bottom: 30px;
          }
          .header-sub-text {
            margin-bottom: 30px;
            h3 {
              color: $white;
              font-weight: 200;
              span {
                font-size: 30px;
                font-weight: 600;
                color: $primary;
              }
            }
          }
          h6 {
            color: $white;
            font-weight: 200;
            text-transform: none;
            span {
              font-size: 18px;
              font-weight: 600;
              color: $primary;
            }
          }
          .btn-default {
            background-color: $primary;
            color: $white !important;
            padding: 10px 15px;
            text-transform: uppercase;
            border: 1px solid $primary;
            font-weight: 300;
            &:hover {
              background-color: transparent !important;
              border: 1px solid $white;
            }
          }
          .dot {
            bottom: 4%;
            right: 55px;
          }
          .background {
            animation: movebounce 150s linear infinite;
            bottom: 0;
            width: 100%;
          }
        }
        &.service {
          .about-chat {
            margin-bottom: -50px;
            .service-container {
              margin-bottom: 50px;
              .chat-box:hover{
                hr{
                  width: 40%;
                  background-color: $heart;
                }
              }
              .feature-content {
                padding-left: 20px;
                .service-heading {
                  margin-top: 20px;
                  margin-bottom: 15px;
                  letter-spacing: unset;
                }
                hr {
                  height: 1px;
                  width: 75px;
                  background-color: $secondary;
                  text-align: left;
                  margin: 0;
                  margin-bottom: 15px;
                  transition: all 0.5s;
                }
              }
            }
          }
        }
        &.build-bg {
          background-image: url('../images/saas1/banner-2.jpg');
          background-size: cover;
          background-repeat: no-repeat;
          position: relative;
          counter-reset: section;
          min-height: 740px;
          .container {
            margin-bottom: 5%;
            margin-top: 5%;
          }
          .build-right-slider {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 45%;
            display: block;
            .owl-carousel {
              &.owl-loaded {
                transform: translate(0, -50%);
                top: 50%;
              }
            }
          }
          .owl-dots {
            .owl-dot {
              &:before {
                position: absolute;
                counter-increment: section;
                content: counter(section);
                color: $white;
                left: -5px;
              }
            }
          }
          .owl-carousel {
            .owl-item {
              img {
                width: auto;
                display: inline-block;
              }
            }
          }
          .owl-dots {
            display: inline-block;
            position: absolute;
            margin: 0;
            top: 50%;
            margin-right: -50%;
            transform: translate(-35%, -50%);
            counter-reset: my-sec-counter;
          }
          .owl-dot {
            counter-increment: my-sec-counter;
            content: counter(my-sec-counter);
            display: block;
            margin-bottom: 20px;
          }
          .owl-theme .owl-dots .owl-dot {
            display: block;
            margin-bottom: 10px;
          }
          .owl-theme {
            .owl-dots {
              .owl-dot {
                &.active {
                  span {
                    background-color: $primary;
                    width: 95px;
                  }
                  &:before {
                    color: $primary;
                  }
                }
              }
            }
          }
          .owl-theme {
            .owl-dots {
              .owl-dot {
                span {
                  width: 75px;
                  height: 4px;
                  margin-bottom: 25px;
                }
              }
            }
          }
          .sync1 {
            .item {
              padding: 0;
              margin: 5px;
              color: $white;
              -webkit-border-radius: 3px;
              -moz-border-radius: 3px;
              border-radius: 3px;
              text-align: center;
            }
          }
          .sync2 {
            .item {
              color: $white;
              border-radius: 3px;
              text-align: center;
              cursor: pointer;
              h1 {
                font-size: 18px;
              }
            }

          }
          .build-box {
            text-align: left;
            .build-head {
              font-size: calc(18px + (45 - 18) * ((100vw - 300px) / (1920 - 300)));
              margin-bottom: 40px;
              font-weight: 600;
              letter-spacing: 0.04em;
            }
            p {
              color: $white;
              font-weight: 300;
            }
            ul {
              margin-top: 45px;
              li {
                margin-bottom: 18px;
                font-size: 16px;
                img {
                  margin-right: 15px;
                }
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
        &.howitwork {
          background-image: url("../images/saas1/work-bg.png");
          background-size: cover;
          .work-tab {

            .nav-pills {
              .nav-link {
                padding: 0 55px;
                position: relative;
                color: $secondary;

                img {
                  filter: brightness(0) invert(0);
                  opacity: 0.4;
                }
                h6 {
                  margin-top: 15px;
                  color: $secondary;
                  opacity: 0.5;
                  letter-spacing: unset;
                }
                &.active {
                  background-color: transparent;
                  img {
                    filter: grayscale(0%);
                    opacity: 1;
                  }
                  h6 {
                    color: $secondary;
                    margin-bottom: 0;
                    opacity: 1;

                  }
                  span {
                    display: block;
                  }
                }
                span {
                  display: none;
                  width: 40px;
                  height: 5px;
                  background-color: $secondary;
                  position: absolute;
                  bottom: -20px;
                  left: 0;
                  margin: auto;
                  right: 30px;
                  border-radius: 15px;
                  &:after, &:before {
                    position: absolute;
                    height: 5px;
                    width: 10px;
                    content: "";
                    background-color: $secondary;
                    border-radius: 15px;
                  }
                  &:after {
                    right: -25px;
                    width: 5px;

                  }
                  &:before {
                    left: 45px;
                  }
                }
              }
            }
          }
        }
        &.pricing {
          background-color: $secondary;
          background-image: url('../images/saas1/banner-2.jpg');
          background-size: cover;
          background-repeat: no-repeat;
          .container {
            margin-bottom: 0;
          }
          .pricing__switcher {
            width: 300px;
            height: 60px;
            margin: 0 auto 50px auto;
            position: relative;
            background-color: $light-white;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            border-radius: 100px;
            span {
              font-weight: 600;
              color: $primary;
              margin-left: 5px;
              text-transform: uppercase;
              transition: all 0.3s;
            }
          }
          .switcher__buttons {
            width: 100%;
          }
          .switcher__button {
            cursor: pointer;
            width: 50%;
            float: left;
            height: 60px;
            line-height: 60px;
            position: relative;
            z-index: 575;
            transition: .3s ease-in-out;
            font-size: 18px;
          }
          .switcher__border {
            height: 60px;
            width: 50%;
            background-color: $primary;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 555;
            margin: 0;
            border: none;
            transition: .3s ease-in-out;
            border-radius: 100px;
          }
          .btn-default.btn-white {
            border: 2px solid $secondary;
            color: $secondary !important;
            &:hover {
              background-color: $secondary;
              color: $white !important;
            }
          }
          .switcher__button--enabled {
            color: $white;
            span {
              color: $white;
            }
          }
          .switcher__button:nth-child(1).switcher__button--enabled ~ .switcher__border {
            left: 0;
          }
          .switcher__button:nth-child(2).switcher__button--enabled ~ .switcher__border {
            left: 50%;
          }
          .plan-box {
            > div {
              &:nth-child(2) {
                .price-box {
                  ul {
                    li {
                      &:last-child {
                        -webkit-text-decoration-line: line-through;
                        text-decoration-line: line-through;
                        margin-left: 30px;
                        margin-bottom: 0;
                      }
                    }
                  }
                }
              }
              &:first-child {
                .price-box {
                  ul {
                    li {
                      &:nth-last-child(-n + 3) {
                        -webkit-text-decoration-line: line-through;
                        text-decoration-line: line-through;
                        margin-left: 30px;
                      }
                    }
                  }
                }
              }
            }
            .price-box {
              background-color: $white;
              padding: 40px;
              border-radius: 25px;
              transition: all 0.6s;
              opacity: .5;
              margin: 16px 9px;
              img {
                width: auto !important;
                display: inline-block;
              }
              .price-heading {
                text-transform: uppercase;
                font-size: calc(18px + (30 - 18) * ((100vw - 300px) / (1920 - 300)));
                font-weight: 700;
              }
              h4 {
                &.no-weight {
                  font-weight: unset;
                  margin: 45px 0 40px 0;
                  text-transform: capitalize;
                  span {
                    font-weight: 700;
                  }
                }
              }
              span {
                font-size: 50px;
              }
              ul {
                text-align: left;
                margin-top: 40px;
                margin-bottom: 40px;
                li {
                  font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1920 - 300)));
                  margin-bottom: 11px;
                  img {
                    margin-right: 15px;
                  }
                  span {
                    font-size: 16px;
                    font-weight: 600;
                  }
                  &:nth-last-child(1) {
                    margin-bottom: 30px;
                  }
                }
              }
              .btn-theme {
                background-color: $secondary;
                border: 1px solid $secondary;
                padding: 12px 25px;
                &:hover {
                  border: 1px solid $secondary;
                  color: $secondary !important;
                  background-color: $white;
                }
              }
            }
          }
          .owl-item {
            &.active {
              &.center {
                .price-box {
                  transform: scale(1.05);
                  opacity: 1;
                }
              }
            }
          }
        }
        .testimonial-bg {
          background-image: url('../images/saas1/work-bg.png');
          background-size: cover;
          background-repeat: no-repeat;
        }
        &.testimonial {
          .testimonial {
            padding: 20px 0 20px 0;
            margin-right: 40px;
            margin-left: 20px;
          }

          .testimonial-box {
            background-color: $white;
            width: 100%;
            height: 100%;
            padding: 35px 50px;
            border-radius: 10px;
            -webkit-box-shadow: -5px 2px 22px 0 rgba(0, 0, 0, 0.1);
            box-shadow: -5px 2px 22px 0 rgba(0, 0, 0, 0.1);
            position: relative;
            h6 {
              letter-spacing: 0.05em;
              text-transform: none;
              line-height: 28px;
            }
            &:before {
              content: "";
              position: absolute;
              left: 12%;
              bottom: -14px;
              height: 30px;
              width: 30px;
              display: inline-block;
              margin-top: -137px;
              margin-left: -15px;
              background: $white;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
              -webkit-box-shadow: 7px 7px 12px rgba(0, 0, 0, 0.07);
              box-shadow: 7px 7px 12px rgba(0, 0, 0, 0.07);

            }
            & img {
              position: absolute;
              top: -20px;
            }
          }

          .testi-profile {
            img {
              padding-top: 25px;
              padding-left: 25px;
              margin-right: 20px;
            }
            .media-body {
              margin-top: 45px;
            }
            h5 {
              font-weight: 600;
              font-size: 18px;
              margin-bottom: 0;
            }
            h6 {
              margin: 0;
              letter-spacing: 0.05em;
              font-size: 14px;
              line-height: unset;
            }

          }

          .testimonial-slider {
            .owl-carousel {
              .owl-item {
                img {
                  width: auto;
                }
              }
            }
            .owl-theme {
              .owl-dots {
                .owl-dot {
                  span {
                    background-color: $secondary;
                    width: 6px;
                    height: 7px;
                  }
                  display: block;
                  &.active {

                    span {
                      height: 16px;
                      background-color: $secondary;
                    }
                  }
                }
              }
              .owl-dots {
                text-align: center;
                -webkit-tap-highlight-color: transparent;
                position: absolute;
                display: block;
                right: 0;
                margin: 0;
                top: 30%;
                /* left: 50%; */
                margin-right: -2%;
                transform: translate(-50%, -50%);

              }
            }
          }
          .video-box {
            background-image: url('../images/saas1/video-pic.jpg');
            background-size: cover;
            background-repeat: no-repeat;
            height: 100%;
            margin-right: 50px;
            position: relative;
            border-radius: 10px;
            img {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
              cursor: pointer;
            }
          }
        }
        &.subscribe {
          background-color: $secondary;
          background-image: url('../images/saas1/banner-2.jpg');
          background-size: cover;
          background-repeat: no-repeat;
          h6 {
            margin-top: 15px;
            margin-bottom: 0;
            font-weight: 200;
            line-height: 1.6;
          }
          input[type="email"], input[type="submit"] {
            border-radius: 45px;
            padding-left: 15px;
            padding-right: 15px;
            font-size: 14px;
          }
          input[type="email"] {
            background-color: $white8;
            border: solid $white8;
            color: $white7;
            width: 310px;
            height: 57px;
          }
          input[type="submit"] {
            background-color: $primary;
            border: solid $primary;
            color: $white;
            margin-left: -50px;
            height: 57px;
            width: 158px;

          }
          .media {
            img {
              margin-right: 20px;
            }
          }
          form {
            display: flex;
          }
          .subscribe-input {
            h6 {
              font-weight: 300;
              text-transform: capitalize;
              margin-right: 16px;
            }
            .display-flex {
              h6 {
                padding: 0 12px;
                &:first-child {
                  padding-left: 0;
                }
                &:last-child {
                  padding-right: 0;
                  text-align: left;
                }
              }
            }
          }
        }
        &.faq {
          .faq-block {
            .frequent-text {
              font-size: calc(18px + (48 - 18) * ((100vw - 300px) / (1920 - 300)));
              margin-bottom: 20px;
              margin-top: -15px;
            }
            h6 {
              font-weight: normal;
              color: $secondary;
              line-height: 1.8;
              &.link {
                font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1920 - 300)));
                font-weight: 400;
                margin-top: 50px;
                margin-bottom: -5px;
                a {
                  color: $blue2;
                }
              }
            }
            .card {
              border: none;
              background-color: transparent;
              cursor: pointer;
            }
            .card-header {
              background-color: transparent;
              font-size: calc(15px + (18 - 15) * ((100vw - 300px) / (1920 - 300)));
              font-weight: 500;
            }
            .faq {
              margin-top: calc(14px + (16 - 14) * ((100vw - 300px) / (1920 - 300)));
            }
            .card-body {
              font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1920 - 300)));
              padding-left: 15px;
            }
            a {
              color: $secondary;
            }
            h5 {
              margin-top: 50px;
            }
          }

          .rotate {
            -moz-transition: all .5s linear;
            -webkit-transition: all .5s linear;
            transition: all .5s linear;
          }

          .rotate.down {
            -moz-transform: rotate(-90deg);
            -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
          }

          a {
            div {
              transform: rotate(270deg);
              margin-right: 20px;
              position: absolute;
              left: 0;
              margin-top: 3px;
            }
          }

          .collapsed {
            a {
              div {
                transform: rotate(0deg);
                margin-right: 15px;
              }
            }

          }

        }
        &.blog {
          background-color: $secondary;
          background-image: url('../images/saas1/banner-2.jpg');
          background-size: cover;
          background-repeat: no-repeat;
          .blog-post-slider {
            .slick-slide {
              margin: 0 15px;
            }
            .post-item {
              box-shadow: -2px 2px 6px 1px rgba(0, 0, 0, .05);
              background-color: $white;
              overflow: hidden;
              border-radius: 5px;
              -webkit-border-radius: 5px;
              -moz-border-radius: 5px;
              img {
                width: 45%;
                float: left;
              }
            }
            .blog-post-info {
              float: right;
              width: 55%;
              padding: 15px;
              h3 {
                font-size: 18px;
                color: $secondary;
              }
              .dte {
                font-size: 12px;
                color: $gray5;
              }
              p {
                color: $secondary;
              }
            }
            .slick-prev {
              left: -60px;
            }
            .slick-prev, .slick-next {
              display: inline-block;
              background-color: transparent;
              border: none;
              font-size: 32px;
              position: absolute;
              top: 40%;
              color: $secondary;
              cursor: pointer;
            }
          }
        }
        &.footer2 {
          background-color: $secondary;
          .logo-sec {
            .footer-social {
              .footer-social-list {
                padding: 0 5px;
              }
            }
            li {
              a {
                i {
                  height: 30px;
                  width: 30px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background: $primary;
                  border-radius: 50px;
                }
              }
            }
          }
          h5 {
            &.footer-headings {
              margin-top: 0;
            }
          }
        }
        &.client {
          background: $white8;
        }
      }
      header{
        &.saas1{
          nav{
            ul{
              li{
                & > a{
                  &:hover{
                    color: $heart;
                  }
                }
              }
            }
          }
        }
        &.header-absolute {
          position: absolute;
          width: 100%;
        }
        &.ecommerce{
          .responsive-btn{
            i{
              color: $black !important;
              cursor: pointer;
              line-height: 2;
            }
          }
        }
      }
      /*************************
      saas1 css end
      *************************/

      /*************************
      36. saas2 css start
      *************************/
      .saas2 {
        h1, h2, h3, h4, h5, h6, li, a {
          font-family: 'Poppins', sans-serif;
        }
        h2, h3, h4, h5 {
          color: $primary;
        }
        h1 {
          margin-top: -14px;
          line-height: 1;
        }
        h2 {
          font-size: calc(20px + (33 - 20) * ((100vw - 300px) / (1920 - 300)));
        }
        h3 {
          font-size: calc(18px + (28 - 18) * ((100vw - 300px) / (1920 - 300)));
        }
        h4 {
          letter-spacing: 1px;
        }
        h5 {
          font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1920 - 300)));
        }
        h6 {
          font-size: calc(13px + (15 - 13) * ((100vw - 300px) / (1920 - 300)));
        }
        p {
          font-size: calc(13px + (16 - 13) * ((100vw - 300px) / (1920 - 300)));
          margin-top: 0;
          margin-bottom: -3px;
          color: $gray14;
        }
         &.fixed{
          background-color: linear-gradient($light, $dark);
        }
        &.header {
          .center-text {
            margin-top: 0;
          }
        }
        &.brand {
          .brand-slider {
            img {
              filter: brightness(5) invert(0.5);
              transition: all 0.3s ease;
              max-width: 150px;
              margin: 0 auto;
              &:hover {
                filter: none;
                transition: all 0.3s ease;
              }
            }
          }
        }
        .link-horizontal {
          margin-top: 40px;
        }
        .saas2-content {
          .header-sub-text {
            h3 {
              line-height: 1;
              margin-bottom: 10px;
            }
          }
        }
        &.bg-gradient {
          background-image: linear-gradient($light, $dark);
        }
        .title {
          .main-title {
            h2 {
              line-height: 0.9;
            }
          }
          .sub-title {
            .sub-title-para {
              color: $secondary;
            }
          }
        }
        &.laptop-slider {
          .owl-item {
            img {
              padding: 50px;
              transition: all 0.3s ease;
            }
            &.center {
              img {
                padding: 0;
                margin-top: 18px;
                transition: all 0.3s ease;
              }
            }
          }
        }
        &.services .service {
          padding: 30px;
          border: none;
          &:hover {
            img {
              animation: scaleDisappear 0.3s;
            }
          }
        }
        &.feature {
          position: relative;
          overflow: hidden;
          background-image: url("../images/saas2/background.png");
          background-size: contain;
          background-position: right;
          .center-content {
            flex-direction: unset;
            margin-bottom: 50px;
            .feature-content {
              margin-left: 25px;
              .feature-head {
                margin-bottom: 8px;
              }
              .feature-para {
                font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1920 - 300)));
              }
            }
          }
          .image-container {
            .left-one, .right-one {
              position: absolute;
            }
            .advance-feature-img {
              position: relative;
              z-index: 1;
              margin-bottom: -20px;
            }
            .left-one {
              left: -20%;
            }
            .right-one {
              right: -19%;
              bottom: 0;
            }
          }
        }
        &.quick-sol {
          background: url("../images/saas2/background/quick-sol.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          overflow: hidden;
          .set-center-div {
            min-height: 555px;
            position: relative;
            .side-img {
              position: absolute;
              right: -14%;
              height: unset;
              top: 50%;
              transform: translate(0, -50%);
            }
          }
        }
        .offers-container {
          .offer-heading-text {
            font-size: calc(20px + (33 - 20) * ((100vw - 300px) / (1920 - 300)));;
            margin-bottom: 25px;
            font-weight: 400;
            margin-top: -11px;
          }
          .bottom-border {
            border: 2px solid $white;
            width: 83px;
            margin-bottom: 70px;
          }
          .offers-content {
            margin-bottom: 40px;
            &:last-child {
              margin-bottom: 0;
            }
            .offer-main-text {
              font-weight: 300;
              margin-bottom: 15px;
            }
            .offer-sub-text {
              font-weight: 300;
            }
            .fa-check-circle {
              color: $white;
              font-size: 20px;
              margin-right: 20px;
              margin-top: 5px;
            }
          }
        }
        &.services {
          .saas2-services {
            .row {
              .service-container {
                &:nth-child(n+5) {
                  margin-top: 30px;
                }
              }
            }
          }
        }
        &.laptop-slider {
          background: url("../images/saas2/background/screenshot-bg.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          .slider-container {
            margin-top: 50px;
          }
          .owl-theme {
            .owl-dots {
              position: absolute;
              margin: 0 auto;
              width: 100%;
              top: -70px;
              .owl-dot {
                span {
                  background: $white !important;
                }
                &.active {
                  span {
                    background: $white;
                    transform: scale(1.4);
                  }
                }
              }
            }
          }
          .slider-container {
            position: relative;
            .trainers-slider {
              &.owl-carousel {
                .owl-item {
                  &.active {
                    &.center {
                      .item {
                        img {
                          border: 20px solid #ffffff;
                          border-radius: 15px;
                          background-color: #f3f6fd;
                          margin-bottom: 0;
                        }
                        &:before {
                          content: "";
                          position: absolute;
                          border-radius: 0 0 20px 20px;
                          background: $white;
                          z-index: 999;
                          width: calc(100% + 100px);
                          bottom: -5px;
                          left: -50px;
                          height: 12px;
                        }
                        &:after {
                          content: "";
                          position: absolute;
                          width: 140px;
                          left: 50%;
                          margin-left: -70px;
                          border-radius: 5px;
                          background: #e4eaf6;
                          z-index: 999;
                          height: 4px;
                          bottom: 6px;
                        }
                      }
                    }
                  }
                }
              }
            }
            .center-img {
              position: absolute;
              top: 0;
              transform: translate(-50%, 0);
              left: 50%;
              z-index: 2;
              img {
                max-width: 790px;
              }
            }
          }
        }
        &.testimonial {
          background: url("../images/saas2/background/bg-testimonial.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          .container {
            margin-bottom: -15px;
          }
          .quote-margin {
            margin-left: 50px;
          }
          .quote-text {
            h6 {
              text-transform: none;
              font-weight: 300;
              letter-spacing: 1px;
              line-height: normal;
            }
          }
        }
        &.footer2 {
          h5{
            color: $white;
          }
          .footer-lists {
            li {
              a {
                font-weight: 200;
              }
            }
          }
        }
        &.pricing{
          H6{
            margin-bottom: 8px;
          }
        }
      }
      /*************************
      saas2 css end
      *************************/
      /*************************
      wedding css start
      *************************/
      .wedding-time {
        margin-bottom: 60px;
        .count {
          position: relative;
          padding-left: 15px;
          padding-bottom: 15px;
          .days {
            font-family: 'Alex Brush', cursive;
            display: block;
            .days-text {
              font-family: 'Alex Brush', cursive;
            }
          }
          .set-border-left {
            position: absolute;
            border-left: 5px solid $dark;
            height: calc(100% - 20px);
            left: 0;
            bottom: 0;
          }
          &:before, &:after {
            content: '';
            position: absolute;
          }
          &:before {
            border-top: 5px solid $dark;
            left: 0;
            width: 15px;
            bottom: calc(100% - 20px);
          }
          &:after {
            border-right: 5px solid $light;
            top: unset;
            left: calc(100% - 20px);
            right: unset;
            height: 15px;
            bottom: 0;
          }
          .set-border-bottom {
            position: absolute;
            border-image: linear-gradient(to right, $dark, $light);
            border-image-slice: 1;
            border-bottom: 5px solid;
            width: calc(100% - 20px);
            bottom: 0;
            left: 5px;

          }
          .counter-container {
            background-color: $white;
            padding: 37px 0;
          }
        }
      }
      /*************************
      wedding css end
      *************************/

      /*************************
      event css start
      *************************/
      .event {
        h4, h5, li {
          margin-top: 0;
          margin-bottom: 0;
        }
        h1, h2, h3, h4, h5, h6 {
          text-transform: capitalize;
        }
        h2, h3, h4, h5, h6, li, p, a {
          font-family: 'Work Sans', sans-serif;
          font-weight: 500;
        }
        h2, h3, h4, h5, h6, p {
          color: $secondary;
        }
        h1 {
          font-family: 'Satisfy', cursive;
          font-size: calc(22px + (60 - 22) * ((100vw - 300px) / (1920 - 300)));
          margin-bottom: -12px;
          margin-top: -9px;
        }
        h2 {
          font-size: calc(20px + (40 - 30) * ((100vw - 300px) / (1920 - 300)));
          margin-top: -2px;
          margin-bottom: -6px;
          letter-spacing: 1px;
          font-weight: 700;
          line-height: 1.4;
        }
        h3 {
          font-size: calc(18px + (30 - 18) * ((100vw - 300px) / (1920 - 300)));
        }
        h4 {
          font-size: calc(16px + (25 - 16) * ((100vw - 300px) / (1920 - 300)));
          margin-top: -6px;
        }
        h5 {
          font-size: calc(16px + (20 - 15) * ((100vw - 300px) / (1920 - 300)));
          margin-bottom: -10px;
        }
        h6 {
          font-size: calc(13px + (16 - 13) * ((100vw - 300px) / (1920 - 300)));
          margin-bottom: 0;
        }
        p {
          font-size: calc(13px + (18 - 13) * ((100vw - 300px) / (1920 - 300)));
          margin-bottom: -10px;
          letter-spacing: 0.5px;
          font-weight: 400;
        }
        .animated-bg i {
          left: unset;
          right: 0;
        }
        &.fixed{
          background-color: $primary; 
          padding: 0;
        }
        ul {
          list-style-type: none;
          padding: 0;
          margin: 0;
          li {
            font-size: 14px;
          }
        }
        .circle {
          position: absolute;
          top: 60px;
          height: 26px;
          width: 26px;
          background-color: $white;
          border: 3px solid $primary;
          border-radius: 100%;
          left: 0;
          right: 0;
          margin: 0 auto;
          &:after {
            content: '';
            position: absolute;
            height: 60px;
            width: 4px;
            background: $primary;
            top: -63px;
            left: 0;
            right: 0;
            margin: 0 auto;
          }
          &:before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            padding: 8px;
            margin: 2px;
            border-radius: 100%;
            background-color: $primary;
          }
        }
        &.bg-img {
          background-image: url("../images/event/bg1.jpg");
        }
        &.bg-img2 {
          background-image: url("../images/event/h3-bg.jpg");
        }
        &.bg-img3 {
          background-image: url("../images/event/counter-bg.jpg");
          background-size: cover;
          background-position: center center;
        }
        &.bg-about {
          position: relative;
          background-attachment: fixed;
          background-size: cover;
          background-repeat: no-repeat;
          .testiminial{
            margin-bottom: -10px;
          }
          &::before {
            content: "";
            background-image: linear-gradient($light, $dark);
            top: 0;
            left: 0 ;
            opacity: 0.9;
            height: 100%;
            width: 100%;
            position: absolute;
          }
        }
        .wedding-time {
          margin-bottom: 50px;
        }
        .count {
          margin: 0 5px;
          .days {
            font-family: 'Satisfy', cursive;
            .days-text {
              font-family: 'Satisfy', cursive;
            }
          }
          .counter-container {
            background-color: transparent;
            padding: 0 0 20px 0;
          }
          .set-border-left {
            border-left: 5px solid $white;
          }
          .set-border-bottom {
            border-image: unset;
            border-bottom: 5px solid $white;
          }
          &:before {
            width: 25px;
            border-top: 5px solid $white;
          }
          &:after {
            border-right: 5px solid $white;
            height: 25px;
          }
        }
        .borders-before {
          margin-bottom: 20px;
        }
        .format {
          .format-head-text {
            margin-bottom: 40px;
          }
          .format-sub-text {
            p {
              font-weight: 300;
            }
          }
        }
        &.speaker{
          .team-img{
            .social{
              ul{
                li{
                  a{
                    i{
                      transition: all 0.4s;
                      &:hover{
                        background-color: white;
                        color: $primary;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &.blog{
          .img-container{
            border-radius: 10px;
            overflow: hidden;
            .social-list{
              ul{
                li{
                  a{
                    i{
                      background-color: unset;
                      height: 28px;
                      width: 28px;
                      &:hover{
                        background-color: white;
                        color: #BA38D2;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &.testimonial{
          h5{
            line-height: 1.8;
          }
        }
        &.sponsor{
          .sponsor-img{
            margin-bottom: 50px;
            &:nth-last-child(-n + 4) {
              margin-bottom: 0;
            }
          }
        }
        &.pricing{
          .price-container{
            .price{
              margin-bottom: 30px;
              letter-spacing: 1px;
              color: $gray3;
            }
          }
        }
        .filter-section .filter-container ul.filter > li.active{
          border-radius: 25px;
        }
        &.portfolio-section .isotopeSelector .overlay{
          border-radius: 15px;
          overflow: hidden;
          &:hover{
            .overlay-background i{
              border-radius: 10px;
            }
          }
        }
        &.contact {
          .iframe-container{
            border-radius: 15px 0 0 15px;
            overflow: hidden;
          }
          .bg-white{
            border-radius: 0 15px 15px 0;
            overflow: hidden;
          }
        }
      }
      /*************************
      event css end
      *************************/

      /*************************
      gym css start
      *************************/
      .gym {
        h1, h2 {
          font-family: 'Poppins', sans-serif;
          text-transform: capitalize;
        }
        h4, h5, li {
          margin-top: 0;
          margin-bottom: 0;
        }
        h1, h2, h3, h4, h5, h6 {
          text-transform: capitalize;
        }
        h2, h3, h4, h5, h6, li, a {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
        }
        h2, h3, h4 {
          color: $primary;
        }
        h5, h6, p {
          color: $secondary;
        }
        h1 {
          font-size: calc(22px + (60 - 22) * ((100vw - 300px) / (1920 - 300)));
          color: $white;
          font-weight: 200;
          margin-top: -5px;
          margin-bottom: -10px;
        }
        h2 {
          font-size: calc(20px + (40 - 20) * ((100vw - 300px) / (1920 - 300)));
          margin-top: -5px;
          margin-bottom: -5px;
        }
        h3 {
          letter-spacing: 1px;
        }
        h4 {
          font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1920 - 300)));
          font-weight: 600;
          margin-top: -8px;
          margin-bottom: -8px;
        }
        h6 {
          font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1920 - 300)));
          margin-top: 0;
          margin-bottom: 0;
        }
        &.fixed{
          background-color: $primary; 
          padding: 0;
          border-bottom: 1px solid #ddd;
        }
        .borders-before{
          span:before{
            border-top: 2px solid $secondary;
          }
        }
        &.pricing{
          .btn-default{
            background-color: $secondary;
            &:hover{
              background-color: $white;
              transition: all 0.3s ease;
            }
          }
        }
        &.brand-slider {
          padding: 50px 0;
        }
        .btn {
          padding: 14px 35px;
        }
        .borders-before {
          text-transform: uppercase;
          margin-bottom: 20px;
        }
        .owl-theme {
          .owl-dots {
            margin-top: 20px !important;
          }
        }
        .title {
          .borders-before {
            margin-left: 0;
          }
        }
        .about-img {
          position: relative;
          margin: 35px 0;
          .format-img {
            width: 65%;
          }
          .border-size {
            position: absolute;
            width: 284px;
            height: 284px;
            &.set-border-top {
              top: 0;
              left: 0;
              border-top: 3px solid $black;
              border-left: 3px solid $black;
              margin: 8px;
            }
            &.set-border-bottom {
              bottom: 0;
              right: 0;
              border-bottom: 3px solid $black;
              border-right: 3px solid $black;
              margin: 8px;
            }
          }
          .borders-around {
            .bg-black {
              width: 60%;
              &:before, &:after {
                width: 184px;
                height: 184px;
              }
            }
          }
        }
        &.format {
          .format-head-text {
            margin-bottom: 30px;
            .about-font-header {
              font-weight: 600;
              font-size: calc(25px + (40 - 25) * ((100vw - 300px) / (1920 - 300)));;
            }
          }
          .format-sub-text {
            margin-bottom: 45px;
            .about-para {
              font-size: calc(13px + (16 - 13) * ((100vw - 300px) / (1920 - 300)));
              letter-spacing: 0;
            }
          }
          .icon-collection {
            .about-icon {
              .icons {
                filter: brightness(0) invert(1);
              }
              &:hover {
                .icons {
                  filter: unset;
                }
              }
            }
          }
        }
        input {
          margin-right: 12px;
          padding: 22px;
        }
        &.bg-schedule {
          background-image: url('../images/gym/bg.png');
          background-repeat: no-repeat;
          background-position: right;
          background-size: 1150px;
          background-color: $black;
        }
        &.bg-cal-bmi {
          background-image: url('../images/gym/calculat-bg.jpg');
          background-repeat: no-repeat;
          background-position: right;
          background-size: cover;
          background-color: $black;
        }
        &.bg-img3 {
          background-image: url('../images/gym/counter/bg.jpg');
          background-size: cover;
          background-position: center center;
        }
        .owl-carousel {
          .owl-dots {
            line-height: 0.5;
            .owl-dot {
              span {
                margin-bottom: 0;
              }
            }
          }
        }
        .owl-theme {
          &.gym-slider {
            .owl-dots {
              position: absolute;
            }
          }
          &.default-dots {
            .item {
              position: relative;
            }
            .owl-nav {
              display: none;
            }
            .owl-dots {
              bottom: 70px;
              display: flex;
              justify-content: center;
              width: 100%;
              z-index: 3;
              .owl-dot {
                &.active {
                  span {
                    background: $white;
                    opacity: 1;
                    box-shadow: 0 0 10px 1px $white3;
                  }
                }
                span {
                  background: $white;
                  width: 34px;
                  height: 3px;
                  border-radius: unset;
                  opacity: 1;
                }
              }
            }
          }
        }
        .gym-about-slider {
          &.owl-theme {
            &.default-dots {
              .owl-dots {
                bottom: -40px;
              }
            }
          }
        }
        .borders-around {
          .bg-black {
            height: 100%;
            width: 80%;
            position: absolute;
            background-color: $black;
            z-index: -1;
            left: 50%;
            transform: translate(-50%, 0);
            &:before, &:after {
              content: '';
              position: absolute;
              width: 125px;
              height: 125px;
            }
            &:before {
              top: -35px;
              left: -35px;
              border-top: 3px solid $black;
              border-left: 3px solid $black;
            }
            &:after {
              bottom: -35px;
              right: -35px;
              border-bottom: 3px solid $black;
              border-right: 3px solid $black;
            }
          }
        }
        .calculate-bmi {
          border-radius: 40px;
          background: rgba(255, 255, 255, 0.1);
          width: 100%;
          overflow: hidden;
          tr {
            &:last-child {
              td {
                border-bottom: none;
              }
            }
            td {
              &:last-child {
                border-right: none;
              }
            }

            td {
              font-size: 16px;
              color: $white;
              padding: 25px 0 25px 60px;
              font-weight: 500;
              border-right: 2px solid $black;
              border-bottom: 2px solid $black;
              transition: all 0.2s;
              &:hover {
                background: rgba(255, 255, 255, 0.2);
              }
            }
          }
        }
        &.testimonial {
          .testimonial-slider {
            img {
              width: auto !important;
            }
            .testimonial-info {
              .testimonial-para {
                font-weight: 200;
                letter-spacing: 0;
                font-size: 138%;
              }
            }
            .testimonial-name {
              margin-bottom: 10px;
              .name {
                font-size: 20px;
              }
            }
            .testimonial-post {
              .post {
                font-weight: 200;
              }
            }
          }
        }
        &.brand-slider{
          .item{
            img{
              width: 70%;
              margin: 0 auto;
            }
          }
        }
      }
      .gym, .yoga {
        .icon-collection {
          .about-icon {
            background-color: transparent;
            border: 1px solid $white;
            filter: none;
            &:hover {
              background-color: white;
              box-shadow: none;

            }
          }
        }
      }
      /*************************
      gym css end
      *************************/

      /*************************
      popup css start
      *************************/
      .login-popup {
        h2 {
          font-weight: 300;
        }
      }
      .login-modal {
        p {
          color: $gray9;
        }
        .btn-default {
          padding: 10px 30px;
        }
        .modal-content {
          border-radius: 0;
        }
        .modal-header {
          background: $light;
          padding: 0;
        }
        .modal-body {
          padding: 40px;
        }
        .form-group {
          margin-bottom: 25px;
          input {
            border: 1px solid #eaeaea;
            background-color: #efefef;
            border-radius: 50px;
            padding: 12px;
            padding-left: 30px;
            font-size: 12px;
            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
              color: $primary;
              opacity: 0.7;
            }
            &::-moz-placeholder { /* Firefox 19+ */
              color: $primary;
              opacity: 0.7;
            }
            &:-ms-input-placeholder { /* IE 10+ */
              color: $primary;
              opacity: 0.7;
            }
            &:-moz-placeholder { /* Firefox 18- */
              color: $primary;
              opacity: 0.7;
            }
          }
          label {
            font-weight: 500;
            color: $gray15;
          }
        }
        .btn {
          margin-top: 20px;
          z-index: 2;
        }
        .nav-item {
          margin-right: 20px;
        }
        .nav-pills {
          .nav-link {
            background-color: transparent;
            font-size: 16px;
            font-weight: 600;
            color: $gray8;
            &.active {
              color: $primary;
              border-bottom: 2px solid;
              border-radius: 0;
            }
          }
        }
        .close {
          position: absolute;
          right: -30px;
          top: 16px;
          background: $white9;
          opacity: 1;
          color: $primary;
          z-index: 2;
        }
      }
      .loader-wrapper {
        height: 100vh;
        width: 100vw;
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        z-index: 9999;
        top: 0;

        .loader {
          position: relative;
          display: grid;
          grid-template-columns: 33% 33% 33%;
          grid-gap: 2px;
          width: 100px;
          height: 100px;

          > div {
            position: relative;
            display: inline-block;
            width: 100%;
            height: 100%;
            background: $primary;
            transform: scale(0.0);
            transform-origin: center center;
            animation: loader 2s infinite linear;
            &:nth-of-type(1),
            &:nth-of-type(5),
            &:nth-of-type(9) {
              animation-delay: 0.4s;
            }

            &:nth-of-type(4),
            &:nth-of-type(8) {
              animation-delay: 0.2s;
            }

            &:nth-of-type(2),
            &:nth-of-type(6) {
              animation-delay: 0.6s;
            }

            &:nth-of-type(3) {
              animation-delay: 0.8s;
            }
          }
        }

        @keyframes loader {
          0% {
            transform: scale(0.0);
          }
          40% {
            transform: scale(1.0);
          }
          80% {
            transform: scale(1.0);
          }
          100% {
            transform: scale(0.0);
          }
        }
      }
      .speaker {
        &.team-sec {
          .team-img {
            margin-bottom: 8px;
          }
        }
        .team-img {
          text-align: center;
          display: inline-block;
          position: relative;
          overflow: hidden;
          border-radius: 13px;
          transition: 0.6s;
          margin-bottom: 10px;
          img {
            opacity: 1;
            transition: 0.5s;
          }
          .overlay {
            opacity: 0;
            transition: 0.6s;
          }
          .social {
            transition: 0.6s;
            opacity: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            ul {
              display: flex;
              li {
                margin-right: 20px;
                &:last-child {
                  margin-right: 0;
                }
                a {
                  i {
                    display: flex;
                    height: 30px;
                    width: 30px;
                    font-size: 16px;
                    border: 2px solid $white;
                    border-radius: 100%;
                    color: $white;
                  }
                }
              }
            }
          }
          &:hover {
            img {
            }
            .overlay {
              transition: 0.6s;
              background-image: linear-gradient($light, $dark);
              top: 0;
              opacity: 0.7;
              height: 100%;
              width: 100%;
              position: absolute;
            }
            .social {
              transition: 0.6s;
              opacity: 1;
            }
          }
        }
        .employee {
          .e-name {
            text-transform: uppercase;
            margin-bottom: 10px;
            font-weight: 600;
          }
          .post {
            color: $primary;
            font-weight: 400;
            letter-spacing: 0.5px;
          }
        }
      }

      /*************************
      Popup css end
      *************************/

      /*************************
      trainer slider css start
      *************************/
      .gym, .yoga, .creative {
        .trainers-slider {
          &.owl-carousel {
            .owl-stage-outer {
              min-height: 405px !important;
              padding-top: 45px;
            }
            .owl-dots {
              .owl-dot {
                &.active, &:hover {
                  span {
                    background: $primary;
                  }
                }
              }
            }
            .owl-item {
              .trainers-info {
                display: none;
              }
            }
            .owl-item {
              &.active {
                &.center {
                  z-index: 1;
                  transition: 1s;
                  .trainers-info {
                    padding-top: 50px;
                  }
                  .item {
                    img {
                      transition: 1s;
                      transform: scale(1.2);
                      box-shadow: 0 0 10px 0 $black;
                      margin-bottom: 15px;
                    }
                    .trainers-info {
                      display: block;
                    }
                  }
                }
              }
            }
          }
          .socials-lists {
            ul {
              li {
                a {
                  i {
                    height: 30px;
                    width: 30px;
                    font-size: 10px;
                  }
                }
              }
            }
          }
        }
      }
      /*************************
      trainer slider css end
      *************************/

      /*************************
      breadcrumb-section css start
      *************************/
      .radius-0 {
        border-radius: 0;
      }
      .owl-theme .owl-nav{
        margin-top: 0;
      }
      .breadcrumb-section{

        &:not(.breadcrumb-left),&:not(.breadcrumb-right),&:not(.breadcrumb-slider){
          background: url("../images/inner-page/breadcrumb.jpg");
          background-position: center;
          background-size: cover;
          background-blend-mode: overlay;
        }
        &.breadcrumb-left,&.breadcrumb-right{
          background: url("../images/inner-page/breadcrumb-plain.jpg");
        }
        background-position: center;
        background-size: cover;
        background-blend-mode: overlay;
        padding: 230px 0;
        li{
          line-height: normal;
        }
        &.bredcrumb-color {
          background: $primary;
        }
        &.breadcrumb-gradient {
          background: linear-gradient(200deg, $light, $dark);
        }
        &.bg1 {
          background: url("../images/inner-page/breadcrumb-bg.jpg");
          background-position: center;
          background-size: cover;
          background-blend-mode: overlay;
          background-color: #00000094;
        }
        &.bredcrumb-color, &.breadcrumb-gradient {
          .breadcrumb-text {
            color: $white;
          }
          .breadcrumb {
            li {
              color: $white;
              a {
                color: $white;
                opacity: 0.7;
              }
              + li:before {
                color: $white;
              }
            }
          }
        }
        .breadcrumb-text {
          color: $black;
          font-size: calc(20px + (35 - 20) * ((100vw - 300px) / (1920 - 300)));
          font-weight: 500;
          text-transform: uppercase;
          margin-bottom: 20px;
        }
        .breadcrumb {
          padding: 0;
          background: transparent;
          justify-content: flex-end;
          margin: 0;
          display: flex;
          align-items: center;
          li {
            letter-spacing: 1px;
            text-transform: capitalize;
            color: $black;
            + li:before {
              padding: 8px;
              color: $black;
              content: "/\00a0";
            }
            a {
              color: $black;
              text-decoration: none;
              &:hover {
                color: $black;
                text-decoration: underline;
              }
            }
          }
        }
        &.bg-dark {
          &.set-bg-dark {
            background-image: linear-gradient($dark, $light) !important;
          }
          &.bg-unset {
            background: unset;
          }
          h2 {
            color: $white;
          }
          li {
            color: $white;
            a {
              color: $white;
              &:hover {
                color: $white;
                opacity: 0.8;
              }
            }
            &:before {
              color: $white !important;
            }
          }
          &.bg-fixed {
            background-attachment: fixed;
          }
        }
        &.big-type {
          h2 {
            font-size: calc(20px + (50 - 20) * ((100vw - 300px) / (1920 - 300)));
          }
        }
        &.breadcrumb-slider {
          position: relative;
          .owl-nav {
            .owl-prev, .owl-next {
              position: absolute;
              top: 50%;
              transform: translateX(-50%);
              opacity: 0;
              transition: 0.9s;
              background: transparent;
              &:hover {
                background: transparent;
                opacity: 0.7;
              }
              i {
                font-size: 20px;
              }
            }
            .owl-prev {
              left: -20%;
              transition: 0.9s;
            }
            .owl-next {
              right: -20%;
              transition: 0.9s;
            }
          }

          &:hover {
            .owl-nav {
              .owl-prev, .owl-next {
                opacity: 1;
                transition: 0.9s;
              }
              .owl-prev {
                left: 2%;
                transition: 0.9s;
              }
              .owl-next {
                right: 2%;
                transition: 0.9s;
              }
            }
          }
          .img-slider {
            .bg1 {
              background: url("../images/inner-page/breadcrumb-bg.jpg");
              height: 60vh;
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
              background-blend-mode: overlay;
              background-color: #00000094;
            }
            .bg2 {
              background: url("../images/inner-page/blogs/1.jpg");
              height: 60vh;
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
              background-blend-mode: overlay;
              background-color: #00000094;
            }
            .bg3 {
              background: url("../images/inner-page/breadcrumb-bg.jpg");
              height: 60vh;
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
              background-blend-mode: overlay;
              background-color: #00000094;
            }
            .bg4 {
              background: url("../images/inner-page/blogs/1.jpg");
              height: 60vh;
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
              background-blend-mode: overlay;
              background-color: #00000094;
            }
          }
          .set-over {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 5;
          }
        }
        &.video-sec {
          background: unset;
          background-color: unset !important;
          height: 70vh;
          .blocks {
            &:before {
              content: '';
              height: 100%;
              width: 100%;
              background-color: rgba(0, 0, 0, 0.38);
              position: absolute;
            }
          }
        }
      }

      /*************************
      breadcrumb-section css end
      *************************/

      //breadcrumb type css start

      .breadcrumb-back-none {
        background-image: none !important;
      }
      .breadcrumb-section-main{
        padding: 150px 0;
        background-color: $light-inner;
        .breadcrumb-contain {
          display:flex;
          align-items: center;
          >div {
            width: 30%;
            ul {
              li{
                line-height: normal;
                display: inline;
                margin-right: 15px;
                position: relative;
                a {
                  i {
                    margin-right: 15px;
                  }
                  &:hover {
                    color: $black;
                  }
                }
                &:last-child {
                  margin-right: 0;
                }
              }
            }
            .breadcrumb-txt {
              font-weight: 500;
              font-size: calc(20px + (30 - 20) * ((100vw - 300px) / (1920 - 300)));
              margin-top: 0;
              margin-bottom: 0;
            }
            &:last-child {
              text-align: right;
              width: 70%;
            }
          }
        }
        &.inner-1 {
          background-color: $light-inner;
          .breadcrumb-txt {
            font-size: calc(20px + (45 - 20) * ((100vw - 300px) / (1920 - 300)));
            margin-bottom: 0;
            font-weight: 500;
          }
        }
        &.inner-2 {
          background-color: $light-inner;
          .breadcrumb-contain{
            ul {
              li {
                a {
                  font-size: calc(14px + (20 - 14) * ((100vw - 300px) / (1920 - 300)));
                }
              }
            }
            .breadcrumb-txt {
              margin-bottom: 0;
            }
            .breadcrumb {
              text-align: right;
              height: 100%;
              flex: auto;
            }
          }
        }
        &.inner-3 {
          background: $light-inner;
          .breadcrumb-contain {
            ul {
              li {
                a {
                  font-size:calc(16px + (20 - 16) * ((100vw - 300px) / (1920 - 300)));
                }
              }
            }
          }
          .breadcrumb-text {
            font-size: calc(20px + (30 - 20) * ((100vw - 300px) / (1920 - 300)));
            font-weight: 500;
            margin-top: 0;
          }
        }
        &.inner-4 {
          .breadcrumb-contain {
            ul {
              li {
                a {
                  font-size: calc(14px + (20 - 14) * ((100vw - 300px) / (1920 - 300)));
                }
              }
            }
          }
        }
        &.breadcrumb-section-sm {
          padding: 50px 0;
          .breadcrumb-contain {
            .breadcrumb-txt {
              font-size: calc(18px + (20 - 18) * ((100vw - 300px) / (1920 - 300)));
            }
            ul {
              li {
                a {
                  font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1920 - 300)));
                }
              }
            }
          }
        }
      }

      /*************************
      blog-section css start
      *************************/
      .blog-sec {
        .blog-list .blog-agency .blog-contain {
          display: flex;
        }

        &.videos {
          .video {
            background: url("../images/agency/blog/21.jpg");
            background-blend-mode: overlay;
            background-color: #00000094;
            background-size: cover;
            background-repeat: no-repeat;
            padding: 200px 0;
          }
        }
        &.detail2 {
          .bg-blog-detail {
            background: url("../images/inner-page/blogs/1.jpg");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            height: calc(100vh - 90px);
            background-blend-mode: overlay;
            background-color: rgba(0, 0, 0, 0.65);
            .feature-text {
              font-size: calc(20px + (50 - 20) * ((100vw - 300px) / (1920 - 300)));
              font-weight: 500;
              text-transform: uppercase;
              margin-bottom: 15px;
            }
          }
        }
        .blog-detail-slider {
          .owl-nav {
            .owl-prev, .owl-next {
              position: absolute;
              top: 50%;
              transform: translate(0, -50%);
              background: transparent;
              img {
                transition: 1s;
              }
              &:hover {
                background: transparent;
                img {
                  filter: grayscale(0) brightness(0);
                  transition: 1s;
                }
              }
            }
            .owl-prev {
              left: 5px;
            }
            .owl-next {
              right: 5px;
            }
          }
        }
        &.blog {
          .isotopeContainer {
            .blog-agency {
              .blog-contain {
                display: block;
              }
            }
          }
          .container, .container-fluid {
            margin-bottom: -30px;
          }
          .blog-agency {
            margin-bottom: 30px;
            .blog-info{
              .btn-bottom{
                margin-bottom: -5px;
              }
            }
          }
        }
        &.portfolio-section {
          .isotopeSelector {
            &:hover {
              img {
                transform: unset;
              }
            }
          }
          .btn {
            border-radius: 50px;
          }
        }
      }
      .blog-sidebar {
        .blog-title {
          position: relative;
          text-transform: uppercase;
          color: $primary;
          margin-bottom: 30px;
          padding-bottom: 15px;
          letter-spacing: 1px;
          font-weight: 600;
          line-height: 1;
          &:before {
            content: '';
            border-bottom: 4px solid $primary;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 15%;
            transition: 0.5s;
          }
          &:hover {
            &:before {
              width: 25%;
              transition: 0.5s;
            }
          }
        }
        .sidebar-container {
          &.insta {
            margin-bottom: 0;
          }
          margin-bottom: 30px;
          display: inline-block;
          width: 100%;
          .sidebar-list {
            li {
              padding: 10px;
              border-bottom: 1px solid $white4;
              position: relative;
              a {
                color: $black;
                margin-left: 15px;
                transition: all 0.4s;
                &:hover{
                  margin-left: 25px;
                }
                i {
                  position: absolute;
                  left: 10px;
                  top: 12px;
                }
              }
            }
          }
          .newsletter {
            .form-group {
              position: relative;
              i {
                position: absolute;
                right: 4%;
                top: 50%;
                transform: translate(0, -50%);
                font-size: 15px;
              }
            }
          }
        }
        .post-container {
          margin-bottom: 20px;
          .post-head {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 10px;
          }
          .date {
            color: $black;
          }
          div {
            position: relative;
            &.w-35 {
              width: 35%;
              img {
                border-radius: 4px;
              }
            }
            .badge {
              background: $black;
              color: $white;
              padding: 6px 12px;
              border-radius: 50px;
              position: absolute;
              left: 0;
              transform: translate(0, -50%);
              top: 0;
              &.badge-red {
                background: $red3;
              }
              &.badge-blue {
                background: $blue2;
              }
              &.badge-yellow {
                background: $yellow2;
              }
            }
          }
        }
        input {
          padding: 16px 36px 16px 16px;
        }
        .blog-insta {
          display: flex;
          &:last-child {
            li {
              margin-bottom: 0;
            }
          }
          li {
            margin-right: 5px;
            margin-bottom: 5px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
        &.blog-split {
          .blog-list {
            &:nth-child(even) {
              text-align: right;
              .center-text {
                justify-content: flex-end;
              }
            }
          }
        }
      }
      /*************************
      breadcrumb-section css end
      *************************/

      /*************************
      error css start
      *************************/
      .error-sec {
        background: linear-gradient($dark, $light);
        background-position: center;
        height: 100vh;
        .error-title {
          margin-top: -100px;
          margin-bottom: -50px;
          line-height: normal;
          font-size: 260px;
          font-weight: 500;
          color: $white;
        }
        .error-msg {
          font-weight: 500;
        }
      }
      .spin-earth-on-hover {
        transition: ease 200s !important;
        transform: rotate(-3600deg) !important;
      }

      @keyframes rocket-movement {
        100% {
          transform: translate(120px, -60px);
        }
      }
      @keyframes spin-earth {
        100% {
          transform: rotate(-360deg);
          transition: transform 20s;
        }
      }
      @keyframes move-astronaut {
        100% {
          transform: translate(-10px, -10px);
        }
      }
      @keyframes rotate-astronaut {
        100% {
          transform: rotate(-720deg);
        }
      }
      @keyframes glow-star {
        40% {
          opacity: 0.3;
        }
        90%, 100% {
          opacity: 1;
          transform: scale(1.2);
          border-radius: 999999px;
        }
      }
      .bg-purple {
        background: linear-gradient($light, $dark);
        height: 100vh;
        overflow: hidden;
      }
      .section-404 {
        .btn-default {
          &:hover {
            box-shadow: 0 20px 20px rgba(0, 0, 0, 0.1);
          }
        }
        .text-404 {
          font-size: 15px;
          letter-spacing: 1px;
          margin: 10px 50px;
          font-weight: 300;
        }
        .central-body {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh;
          flex-direction: column;
        }
        .sub-text {
          margin-top: 50px;
          margin-bottom: 10px;
        }

        .objects img {
          z-index: 90;
          pointer-events: none;
        }

        .object_rocket {
          z-index: 95;
          position: absolute;
          transform: translateX(-50px);
          top: 75%;
          pointer-events: none;
          width: 150px;
          animation: rocket-movement 20s linear infinite both running;
        }

        .object_earth {
          position: absolute;
          top: 20%;
          left: 15%;
          z-index: 90;
          width: 100px;
        }

        .object_moon {
          position: absolute;
          top: 12%;
          left: 25%;
          width: 80px;
        }

        .object_astronaut {
          width: 170px;
          animation: rotate-astronaut 200s infinite linear both alternate;
        }

        .box_astronaut {
          z-index: 110 !important;
          position: absolute;
          top: 60%;
          right: 16%;
          will-change: transform;
          animation: move-astronaut 5s infinite linear both alternate;
        }

        .image-404 {
          position: relative;
          z-index: 100;
          pointer-events: none;
        }

        .stars {
          background: url("../images/404/overlay_stars.svg");
          background-repeat: repeat;
          background-size: contain;
          background-position: left top;
          height: 100vh;

        }

        .text-inner {
          font-size: calc(100px + (250 - 100) * ((100vw - 300px) / (1920 - 300)));
          font-weight: 700;
          margin-top: -62px;
          line-height: 1;
        }

        .glowing_stars .star {
          position: absolute;
          border-radius: 100%;
          background-color: $white;
          width: 3px;
          height: 3px;
          opacity: 0.3;
          will-change: opacity;
        }

        .glowing_stars .star:nth-child(1) {
          top: 80%;
          left: 25%;
          animation: glow-star 2s infinite ease-in-out alternate 1s;
        }

        .glowing_stars .star:nth-child(2) {
          top: 20%;
          left: 40%;
          animation: glow-star 2s infinite ease-in-out alternate 3s;
        }

        .glowing_stars .star:nth-child(3) {
          top: 25%;
          left: 25%;
          animation: glow-star 2s infinite ease-in-out alternate 5s;
        }

        .glowing_stars .star:nth-child(4) {
          top: 75%;
          left: 80%;
          animation: glow-star 2s infinite ease-in-out alternate 7s;
        }

        .glowing_stars .star:nth-child(5) {
          top: 90%;
          left: 50%;
          animation: glow-star 2s infinite ease-in-out alternate 9s;
        }
      }
      .coming-soon {
        min-height: 100vh;
        &.coming-soon-2 {
          background: url("../images/inner-page/maintain-soon/friend.jpg");
          background-position: center;
          background-size: cover;
          min-height: 100%;
          background-blend-mode: overlay;
          background-color: rgba(0, 0, 0, 0.60);
        }
        .bg-coming-soon {
          background: url("../images/inner-page/maintain-soon/bg-come-soon.jpg");
          background-position: center;
          background-size: cover;
          height: 100%;
        }
        .socials-lists {
          ul {
            li {
              padding: 0 10px;
            }
          }
        }
        .center-container {
          min-height: 100vh;
          display: flex;
          justify-content: center;
          flex-direction: column;
        }
        .our-web-text {
          font-weight: 500;
          letter-spacing: 1px;
        }
        .launch-text, .launch-text2 {
          text-transform: uppercase;
          color: $gray8;
        }
        .launch-text {
          margin-top: 20px;
          margin-bottom: 80px;
          letter-spacing: 1px;
        }
        .launch-text2 {
          margin-top: 30px;
          margin-bottom: 60px;
        }
        .coming-logo {
          margin-bottom: 45px;
        }
        .counters-content {
          display: inline-block;
        }
        .count {
          text-align: center;
          border-right: 1px solid $gray15;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &.no-border {
            border-right: none;
          }
          span {
            font-size: calc(13px + (16 - 13) * ((100vw - 320px) / (1920 - 320)));
            display: block;
            padding-top: 10px;
          }
          h2 {
            font-family: 'Work Sans', sans-serif;
            padding: 0 40px;
          }
          .days {
            font-weight: 300;
          }
          .days-text {
            font-size: calc(20px + (38 - 20) * ((100vw - 320px) / (1920 - 320)));
          }
        }
      }
      /*************************
      error css end
      *************************/
      /*************************
      Filter css start
      *************************/
      .filter-section {
        .filter-container {
          text-align: center;
          padding: 0 0 50px;
          ul {
            &.filter {
              > li {
                padding: 0 25px;
                > a {
                  color: $gray15;
                  font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
                  line-height: 2.2;
                  letter-spacing: 1px;
                  &:hover,
                  &:focus {
                    text-decoration: none;
                    color: $primary;
                    outline: none;
                  }
                }
                &.active {
                  background-color: $primary;
                  a {
                    color: $white;
                  }
                }
                span {
                  color: $border-white;
                  font-size: 20px;
                  display: inline-block;
                  margin: 0 5px
                }
              }
            }
          }
          .filter {
            > li {
              padding: 0;
              margin: 0;
            }
          }
        }
        .title1 {
          .title-inner1 {
            margin-top: -5px;
          }
        }
        h1 {
          text-align: center;
        }
      }
      /*************************
      Filter css ends
      *************************/

      /*************************
      Portfolio Start
      *************************/
      .portfolio-section {
        &.videos {
          .video {
            background: url('../images/agency/blog/21.jpg');
            background-blend-mode: overlay;
            background-color: #00000094;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            padding: 200px 0;
          }
        }
        &.resume, &.event, &.wedding, &.creative, &.portfolio-metro {
          .container, .container-fluid {
            margin-bottom: -30px;
            .isotopeSelector {
              margin-bottom: 30px;
            }
          }
        }
        .list-inline {
          display: block;
          li {
            display: inline-block;
            a {
              font-family: 'Work Sans', sans-serif;
              letter-spacing: 0;
            }
          }
        }
        .center_tag {
          display: flex;
          justify-content: center;
        }
        .article-title {
          position: absolute;
          width: 100%;
          left: 0;
          bottom: 0;
          color: white;
          transition: all 0.5s ease;
          text-align: center;
          float: left;
          font-size: 18px;
          line-height: 24px;
          a {
            color: white;
          }
        }
        .isotopeSelector {
          float: left;
          width: 100%;
          position: relative;
          .overlay {
            &:before {
              content: '';

            }
            opacity: 1;
            position: relative;
          }
          img {
            width: 100%;
            height: auto;
          }
        }
        .isotopeSelector {
          img {
            transition: all .4s ease;
          }
          .overlay-background {
            transform: scale(0);
            transition: all .4s ease;
            i {
              position: absolute;
              top: 50%;
              left: 50%;
              background-color: $white;
              transform: translate(-50%, -50%);
              color: $primary;
              font-size: 22px;
              display: none;
              transition: all 0.4s ease;
              width: 50px;
              height: 50px;
              align-items: center;
              justify-content: center;
            }
          }
          .overlay {
            &:hover {
              img {
                transform: scale(1.2) rotate(2deg);
                transition: all .4s ease;
              }
              .overlay-background {
                transform: scale(1);
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                background-color: rgba($black, 0.5);
                transition: all 0.4s ease;
                i {
                  display: flex;
                  transition: all 0.4s ease;
                }
                &.wedding-overlay {
                  background-color: unset;
                  background-image: linear-gradient(109deg, rgba($dark, 0.7) 0%, rgba($light, 0.7) 52%, rgba($dark, 0.7) 100%);
                }
              }
            }
          }
        }
        .border-portfolio {
          overflow: hidden;
        }
        #form2 {
          padding-bottom: 20px;
          .filter-button {
            width: unset !important;
          }
        }
        .filter-button {
          padding: 0 24px;
          font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
          line-height: 35px;
          border: none;
          background-color: white;
          cursor: pointer;
          &.active {
            background-color: $primary;
            color: $white;
          }
        }
        &.metro-section {
          .product-box {
            .product-detail {
              opacity: 0;
              position: absolute;
              background-color: $white;
              padding: 10px;
              transition: all 0.5s ease;
              width: 80%;
              left: 0;
              right: 0;
              margin: 0 auto;
              bottom: -10px;
              text-align: center;
              h6 {
                color: $gray15;
                padding-top: 0;
                line-height: normal;
                margin-top: 0;
              }
              h4 {
                font-weight: 400;
                color: $black;
              }
            }
            .cart-wrap {
              top: 30px;
              bottom: unset;
              right: 30px;
              button {
                border: 1px solid $white;
                background-color: rgba($white, 0.8);
                opacity: 1;
                i {
                  padding: 7px;
                  color: $gray15;
                }
              }
              a {
                i {
                  border: 1px solid $white;
                  margin: 5px 0;
                  background-color: rgba($white, 0.8);
                  padding: 7px;
                  color: $gray15;
                  opacity: 0;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }
            &:hover {
              .product-detail {
                opacity: 1;
                transition: all 0.5s ease;
                bottom: 15px;
              }
              .cart-wrap {
                button {
                  animation: none;
                }
                a {
                  i {
                    opacity: 1;
                  }
                  &:nth-child(2) {
                    i {
                      animation: fadeInDown 500ms ease-in-out;
                    }
                  }
                  &:nth-child(3) {
                    i {
                      animation: fadeInDown 700ms ease-in-out;
                    }
                  }
                  &:nth-child(4) {
                    i {
                      animation: fadeInDown 1000ms ease-in-out;
                    }
                  }
                }
              }
            }
          }
        }
        .portfolio-text {
          margin-top: 10px;
          .head-text {
            text-transform: capitalize;
            margin-bottom: 10px;
            line-height: 100px;
          }
          .head-sub-text {
            color: $gray;
          }
        }

        &.masonray-sec {
          .filter-section .filter-container {
            padding: 0 0 20px;
          }
          .border-portfolio {
            margin-top: 30px;
          }
          &.titles {
            .head-text {
              line-height: 2;
              margin-bottom: 0;
            }
          }
        }
      }
      .portfolio-creative {
        .isotopeSelector {
          overflow: hidden;
        }

        .head-text {
          font-size: calc(20px + (50 - 20) * ((100vw - 300px) / (1920 - 300)));
          font-weight: 600;
          text-transform: uppercase;
          margin-top: -20px;
          margin-bottom: 12px;
          position: relative;
          &:before {
            content: '';
            border-bottom: 3px solid;
            width: 60px;
            position: absolute;
            bottom: 0;
            transform: translate(-50%, 0);
            left: 50%;
          }
        }
        .head-sub-text {
          font-weight: 400;
          letter-spacing: 1px;
          margin-bottom: 20px;
          color: $gray;
          padding: 0 80px;
          margin-top: 15px;
        }
        &.creative2 {
          .head-text {
            font-size: calc(20px + (25 - 20) * ((100vw - 300px) / (1920 - 300)));
            line-height: 60px;
          }
        }
        &.creative3 {
          .portfolio-text {
            padding: 50px 15px;
            margin-top: 0;
            .head-text, .header-sub-text {
              display: inline-block;
            }
            .head-text {
              &:before {
                transform: unset;
                left: 0;
              }
            }
            .head-sub-text {
              padding: 0;
              margin: 0;
            }
          }
        }
      }
      .pagination_sec {
        margin-top: 50px;
        justify-content: center;
        display: flex;
        ul {
          li {
            height: 50px;
            width: 50px;
            margin: 0 10px;
            background: $white11;
            display: inline-block;
            .prev, .next {
              background: $light-white;
            }
            a {
              color: $primary;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
              transition: 0.5s;
              &.active, &:hover {
                transition: 0.5s;
                background: $primary;
                color: $white11;
              }
            }
          }
        }
      }
      .portfolio-detail {
        h5 {
          margin-top: -6px;
        }
        .detail-head {
          font-size: calc(18px + (30 - 18) * ((100vw - 300px) / (1920 - 300)));
          margin-bottom: 25px;
        }
        .detail-container {
          padding: 10px 0;
          border-bottom: 1px solid $white4;
          .portfolio-left {
            width: 25%;
            .text-left {
              font-weight: 500;
            }
          }
          .portfolio-right {
            width: 75%;
          }
        }
      }
      /*************************
      Portfolio Ends
      *************************/

      /*************************
      Single-blog Sec start
      *************************/
      .single_blog_item {
        .blog-text {
          h6 {
            margin-top: 15px;
            font-weight: 500;
            color: $gray15;
          }
          .blog-head {
            font-weight: 500;
            margin-bottom: 22px;
            margin-top: 10px;
          }
          .blog-description {
            border-bottom: 1px solid rgba(51, 51, 51, 0.25);
            padding-bottom: 20px;
            p {
              font-weight: 400;
              color: $gray15;
              margin-bottom: 25px;
              letter-spacing: 0;
            }
            h5 {
              display: inline-block;
              font-weight: 500;
              text-transform: uppercase;
              &.pull-right {
                display: inline-block;
                i {
                  color: $primary;
                }
              }
            }
          }
        }
      }
      .page-header-comment {
        margin-top: 25px;
        margin-bottom: 0;
        font-weight: 500;
      }
      .blog_comment {
        &.comment-list {
          .comment_sec {
            padding: 15px 0 0;
            .blog_center {
              display: flex;
              .thumbnail {
                display: block;
                padding: 4px;
                margin: 0;
                background-color: $white;
                border-radius: 5px;
                transition: all .2s ease-in-out;
                img {
                  border-radius: 50%;
                  width: 50px;
                }
              }
              .blog_user {
                margin-left: 15px;
                width: 100%;
                position: relative;
                .panel-body {
                  .link-btn {
                    color: $primary;
                    transition: 0.5s;
                    float: right;
                    &:hover {
                      color: $gray15;
                      transition: 0.5s;
                    }
                  }
                  .right {
                    float: right;
                    padding: 5px 10px 0;
                    color: $gray15;
                    text-transform: capitalize;
                    font-weight: 300;
                    margin-bottom: -3px;
                    &:hover {
                      color: $primary;
                    }
                  }
                  .text-left {
                    display: flex;
                    border-bottom: 1px solid rgba(51, 51, 51, 0.25);
                    padding: 10px 0;
                    position: relative;
                    .right_text {
                      position: absolute;
                      right: 0;
                      top: 0;
                      padding: 10px 20px;
                      background-color: $gray;
                      font-weight: 500;
                    }
                    .comment-user {
                      display: flex;
                      height: 100%;
                      align-items: center;
                      h6 {
                        margin: 0;
                        line-height: 0;
                        font-weight: 500;
                        color: $gray15;
                      }
                      i {
                        color: $primary;
                        margin-right: 5px;
                      }
                    }
                    .comment-date {
                      margin-left: 10px;
                      display: flex;
                      height: 100%;
                      align-items: center;
                      h6 {
                        margin: 0;
                        line-height: 0;
                        color: $gray15;
                        font-weight: 500;
                      }
                      i {
                        color: $primary;
                        margin-right: 5px;
                      }
                    }
                  }
                  .comment-post {
                    p {
                      margin-bottom: 0;
                      padding: 15px 0 0 0;
                      font-weight: 400;
                      color: $gray15;
                      letter-spacing: 0;
                    }
                  }
                }
              }
              &.re_comment {
                margin-top: 5px;
              }
            }
          }
        }
      }
      .comment_form {
        margin: 0 auto;
        position: relative;
        .title {
          color: $gray15;
          text-align: center;
          text-transform: uppercase;
          margin-top: 50px;
        }
        .comment-form {
          .form-field {
            position: relative;
            margin: 35px 0;
            padding: 0 35px 0 20px;
            textarea {
              resize: none;
            }
          }
          .input-text {
            display: block;
            width: 100%;
            height: 36px;
            border-width: 0 0 2px 0;
            border-color: $gray15;
            font-size: 14px;
            line-height: 26px;
            font-weight: 400;
            &:focus {
              outline: none;
            }
            &:focus,
            &.not-empty {
              + .label {
                transform: translateY(-24px);
              }
            }
          }
          .label {
            position: absolute;
            left: 20px;
            bottom: 11px;
            font-size: 14px;
            line-height: 26px;
            font-weight: 400;
            color: $gray;
            cursor: text;
            transition: transform .2s ease-in-out;
          }
          .submit-btn {
            display: inline-block;
            background-color: $black;
            color: $white;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-size: 16px;
            line-height: 24px;
            padding: 8px 16px;
            border: none;
            cursor: pointer;
          }
          .comment_btn {
            display: flex;
            justify-content: center;
          }
        }
        .note {
          position: absolute;
          left: 0;
          bottom: 10px;
          width: 100%;
          text-align: center;
          font-size: 16px;
          line-height: 21px;
          .link {
            color: $gray;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        &.map_form {
          .map_text {
            padding: 60px 30px;
            background-color: $gray;
            color: $white;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            .title {
              color: $white;
            }
            p {
              margin: 15px 0 35px 0;
              line-height: 1.4em;
              letter-spacing: 0.1em;
              font-weight: 500;
              color: rgba(68, 68, 68, 0.67);
            }
            .title {
              margin-top: 0;
              text-align: unset;
              color: $gray15;
              font-weight: 600;
            }
            .contect_info {
              .contect-title {
                h3 {
                  margin-bottom: 16px;
                  color: $gray15;
                }
              }
              .contant-text {
                ul {
                  color: $gray15;
                  li {
                    display: flex;
                    margin-bottom: 15px;
                    letter-spacing: 0.1em;
                    color: rgba(68, 68, 68, 0.67);
                    font-weight: 500;
                    &:nth-child(4) {
                      margin-bottom: 0;
                    }
                    i {
                      margin-right: 10px;
                    }
                  }
                }
              }
            }
          }
          .center {
            padding: 0;
            .contect_form {
              display: flex;
              align-items: center;
              width: 100%;
              height: 100%;
              .full_form {
                width: 100%;
                padding: 90px;
                background-color: white;
                h3 {
                  font-weight: 500;
                }
                .comment-form {
                  .form-field {
                    padding: 0 20px;
                    margin: 30px 0;
                  }
                }
                .comment-form {
                  .input-text {
                    background-color: white;
                  }
                  .comment_btn {
                    margin-top: 25px;
                    display: unset;
                    justify-content: unset;
                    .con_btn {
                      margin-top: 20px;
                    }
                  }
                }
              }
            }
          }
        }
        &.coming-soon {

          .comment-form {
            .input-text {
              background-color: transparent;
            }
            .form-field {
              margin: 20px 0;
            }
          }
        }
      }
      .compare-page {
        .table-wrapper {
          padding-bottom: 0;
          .table {
            border: 1px solid $lighten-white;
            text-transform: capitalize;
            color: $black;
            .product-name {
              width: 15%;
            }
            .featured-image {
              width: 185px;
            }
            td {
              border-top: none;
            }
            thead {
              .th-compare {
                td {
                  font-size: 14px;
                  font-weight: 700;
                  background: $light-inner;
                  border-right: 1px solid $white4;
                }
                th {
                  text-align: left;
                  border-bottom: 1px solid $lighten-white;
                  border-right: 1px solid $lighten-white;
                  .remove-compare {
                    float: left;
                    border: none;
                    background: transparent;
                    padding: 0;
                    font-size: 14px;
                    font-weight: 500;
                  }
                }
              }
            }
            tbody {
              tr {
                th {
                  background: $light-inner;
                }
                td {
                  border-right: 1px solid $white4;
                  position: relative;
                }
                th, td {
                  padding: 15px;
                  vertical-align: top;
                  border-bottom: 1px solid $white4;
                  border-top: 1px solid $white4;
                  border-right: 1px solid $white4;
                }
                .grid-link__title {
                  font-size: 15px;
                }
                .product_price {
                  margin: 15px 0;
                  span.money {
                    padding-left: 5px;
                  }
                }
                p {
                  color: $black;
                  font-size: 13px;
                  line-height: 20px;
                  margin: 0;
                }
                .add-to-cart, .select-option-cls {
                  padding: 6px 10px;
                }
              }
            }
          }
        }
      }

      /*************************
      compare start
      *************************/

      .compare-section {
        .item {
          position: relative;
          border: 1px solid $border-white;
          border-left: none;
          &:first-child {
            border-left: 1px solid $border-white;
          }
          .close-btn {
            background-color: transparent;
            border: none;
            font-size: 25px;
            position: absolute;
            top: 0;
            right: 0;
          }
          .img-secton {
            padding: 0 15px 15px;
            img {
              width: 50%;
              margin: 0 auto;
              padding-top: 25px;
            }
            a {
              h5 {
                margin-bottom: 0;
                text-transform: capitalize;
                margin-top: 10px;
              }
            }
            h5 {
              margin-bottom: 0;
              line-height: 1.2;
            }
          }
          .detail-part {
            .title-detail {
              background-color: $light-white;
              padding: 8px 10px;
              border-top: 1px solid $white2;
              border-bottom: 1px solid $white2;
              h5 {
                margin-bottom: 0;
                text-transform: uppercase;
                font-weight: 400;
                color: $black;
              }
            }
            .inner-detail {
              padding: 15px;
              p {
                margin-bottom: 0;
                line-height: 1.2;
                letter-spacing: 0.05em;
              }
            }
          }
          .btn-part {
            text-align: center;
            padding: 15px;
            border-top: 1px solid $border-white;
            .btn-solid, .btn-outline {
              padding: 5px 10px;
            }
          }
        }
        .slick-slide {
          > div {
            border: 1px solid $border-white;
            border-left: none;
          }
          &:first-child {
            border-left: 1px solid $border-white;
          }
        }
      }
      .cart-section, .wishlist-section {
        .btn-default {
          border-radius: 0;
        }
        .table {
          tbody {
            & + tbody {
              border-top: none;
            }
          }
        }
        .cart-buttons {
          padding-top: 35px;
          > div {
            &:last-child {
              text-align: right;
              padding-right: 59px;
            }
          }
        }
        .wishlist-buttons {
          padding-top: 35px;
          text-align: right;
          &:last-child {
            a {
              margin-left: 15px;
            }
          }
        }
        .cart-table {
          overflow: hidden;
          margin-bottom: 0;
          thead {
            th {
              border-bottom-width: 1px;
              font-weight: 700;
              color: $primary;
              text-transform: uppercase;
              font-size: 14px;
              border-top: 0;
              text-align: center;
              padding: 0 0.75rem 0.75rem 0.75rem;
            }
          }
        }
        tbody {
          h2 {
            margin-bottom: 0;
          }
          tr {
            td {
              min-width: 210px;
              vertical-align: middle;
              color: $gray;
              border-top: 0;
              border-bottom: 1px solid $lighten-white;
              text-align: center;
              a, p {
                color: $gray;
                font-weight: 400;
                font-size: 14px;
                text-transform: capitalize;
                margin-bottom: 0;
                letter-spacing: 0;
                img {
                  height: 90px;
                }
              }
              .td-color {
                color: $primary;
              }
              h2 {
                font-size: 24px;
                color: $primary;
                font-weight: 400;
              }
              .mobile-cart-content {
                display: none;
                justify-content: center;
                margin-top: 10px;
                .qty-box {
                  border-radius: 5px;
                  .input-group {
                    .form-control {
                      width: 48px;
                      padding: 6px;
                      border-radius: 0;
                    }
                  }
                }
                .col-xs-3 {
                  align-self: center;
                  margin-left: 10px;
                  margin-right: 10px;
                }
                h2 {
                  font-size: 20px;
                }
              }
            }
          }
        }
        tfoot {
          tr {
            th {
              padding-top: 35px;
              text-align: right;
            }
            td {
              padding-top: 35px;
              padding-bottom: 0;
              text-align: right;
              border: none;
              padding-right: 63px;
              h2 {
                font-size: 24px;
                margin-bottom: 0;
              }
              &:first-child {
                width: 85%;
                font-size: 15px;
                padding-right: 10px;
                text-transform: capitalize;
                font-weight: 700;
              }
            }
          }
        }
      }
      .collection {
        .container {
          margin-bottom: -30px;
        }
        .collection-block {
          text-align: center;
          margin-bottom: 30px;
          .collection-content {
            padding: 15px;
            text-align: center;
            h3 {
              margin-bottom: 20px;
              color: $primary;
              text-transform: uppercase;
              font-weight: 600;
              letter-spacing: 0.05em;
            }
            h4 {
              margin-bottom: 12px;
              font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1920 - 300)));
              color: $gray16;
            }
            p {
              margin-bottom: 10px;
              color: $gray9;
            }
            .btn-solid, .btn-outline {
              padding: 10px 25px;
            }
          }
        }
      }
      .checkout-page {
        .form-group {
          input {
            width: unset;
          }
        }
        .checkout-title {
          margin-bottom: 25px;
          h3 {
            color: $primary;
            font-weight: 700;
          }
        }
        .checkout-form {
          .checkout-details {
            position: relative;
            background-color: $light-white;
            border: 1px solid $border-white;
            padding: 40px;
          }
          .check-out {
            .form-group {
              &:last-child {
                margin-bottom: -5px;
                label {
                  margin-bottom: -5px;
                }
              }
            }
          }
          .form-group {
            position: relative;
            margin-bottom: 25px;
            h3 {
              color: $gray;
              font-weight: 700;
              margin-bottom: 30px;
              margin-top: 30px;
            }
            .field-label {
              line-height: 24px;
              text-transform: capitalize;
              color: $gray10;
              margin-bottom: 10px;
              font-weight: 700;
              span {
                font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1920 - 300)));
                color: $primary;
                font-weight: 600;
              }
            }
            label {
              color: $gray;
            }
          }
          select {
            cursor: pointer;
            -webkit-appearance: none;
            -ms-appearance: none;
            -o-appearance: none;
            -moz-appearance: none;
            background: url('../images/inner-page/dropdown.png') no-repeat 95%;
          }
          input {
            &[type="text"], &[type="email"], &[type="password"], &[type="tel"], &[type="number"], &[type="url"] {
              width: 100%;
              padding: 0 22px;
              height: 45px;
              border: 1px solid $border-white;
            }
          }
          select, textarea {
            width: 100%;
            padding: 0 22px;
            height: 45px;
            border: 1px solid $border-white;
          }
        }
        .check-box {
          line-height: 24px;
          font-size: 14px;
          font-weight: normal;
          padding-top: 5px;
          label {
            position: relative;
            top: -1px;
            font-weight: normal;
            padding: 0;
            font-size: 16px;
            cursor: pointer;
            color: $gray;
          }
        }
        .lower-content {
          margin-top: 30px;
          .order-column {
            margin-bottom: 40px;
          }
        }
      }
      .order-box {
        position: relative;
        margin-bottom: 50px;
        .title-box {
          position: relative;
          padding-bottom: 25px;
          color: $primary;
          font-weight: 600;
          font-size: calc(18px + (22 - 18) * ((100vw - 300px) / (1920 - 300)));;
          border-bottom: 1px solid $white11;
          margin-bottom: 20px;
          span {
            position: relative;
            width: 35%;
            float: right;
            line-height: 1.2em;
          }
        }
        .qty {
          position: relative;
          border-bottom: 1px solid $white11;
          margin-bottom: 30px;
          li {
            position: relative;
            display: block;
            font-size: calc(13px + (15 - 13) * ((100vw - 300px) / (1920 - 300)));;
            color: $gray;
            line-height: 20px;
            margin-bottom: 20px;
            span {
              float: right;
              font-size: calc(15px + (18 - 15) * ((100vw - 300px) / (1920 - 300)));;
              line-height: 20px;
              color: $black;
              font-weight: 400;
              width: 35%;
            }
          }
        }
        .sub-total {
          position: relative;
          border-bottom: 1px solid $border-white;
          margin-bottom: 30px;
          li {
            position: relative;
            display: inline-block;
            font-size: 16px;
            font-weight: 600;
            color: $gray10;
            line-height: 20px;
            margin-bottom: 20px;
            width: 100%;
            .count {
              position: relative;
              font-size: 18px;
              line-height: 20px;
              color: $primary;
              font-weight: 400;
              width: 35%;
              float: right;
            }
          }
          .shopping-option {
            label {
              position: relative;
              font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1920 - 300)));;
              line-height: 32px;
              padding-left: 10px;
              color: $gray;
              font-weight: 500;
            }
          }
          .shipping {
            width: 35%;
            float: right;
          }
        }
        .total {
          position: relative;
          margin-bottom: 40px;
          li {
            position: relative;
            display: block;
            font-weight: 400;
            color: $gray10;
            line-height: 20px;
            margin-bottom: 10px;
            font-size: calc(15px + (18 - 15) * ((100vw - 300px) / (1920 - 300)));;
            .count {
              position: relative;
              font-size: 18px;
              line-height: 20px;
              color: $primary;
              font-weight: 400;
            }
            span {
              float: right;
              font-size: 15px;
              line-height: 20px;
              color: $gray;
              font-weight: 400;
              width: 35%;
              display: block;
            }
          }
        }
      }
      .payment-box {
        position: relative;
        .upper-box {
          position: relative;
        }
        .payment-options {
          position: relative;
          margin-top: 20px;
          margin-bottom: 30px;
          li {
            display: flex;
            margin-bottom: 15px;
            .radio-option {
              position: relative;
              label {
                position: relative;
                padding-left: 30px;
                text-transform: capitalize;
                color: $gray;
                cursor: pointer;
                font-weight: 500;
                font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1920 - 300)));
                line-height: 20px;
                margin-bottom: 0;
              }
              input[type="radio"] {
                position: absolute;
                left: 0;
                top: 5px;
              }
              label {
                .small-text {
                  position: relative;
                  display: none;
                  font-size: 15px;
                  line-height: 25px;
                  font-weight: 300;
                  color: $gray16;
                  margin-top: 10px;
                }
                img {
                  position: relative;
                  display: block;
                  max-width: 100%;
                  margin-left: -30px;
                  margin-top: 5px;
                }
              }
            }
          }
        }
      }
      /*************************
      compare Ends
      *************************/

      /*************************
      category Start
      *************************/
      .collection-filter-block {
        margin-bottom: 30px;
        padding:30px;
        border: 1px solid lighten($black, 86.5%);
        background-color: $white;
        .product-service {
          padding: 0;
          .media {
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 1px solid $border-white;
            svg {
              height: 40px;
              width: 40px;
              path {
                fill: $primary;
              }
            }
            .media-body {
              padding-left: 10px;
              h4 {
                margin-bottom: 5px;
              }
              p {
                margin-bottom: 0;
                text-transform: capitalize;
                letter-spacing: 0;
                font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1920 - 300)));
              }
            }
            &:last-child {
              padding-bottom: 0;
            }
          }
        }
      }
      .collection-mobile-back {
        padding: 15px 0;
        border-bottom: 1px solid lighten($black, 86.5%);
        display: none;
        span {
          text-transform: uppercase;
          font-size: 14px;
          color: $black;
          cursor: pointer;
          i {
            font-size: 20px;
            font-weight: bold;
            margin-right: 5px;
          }
        }
      }
      .filter-main-btn {
        display: none;
        margin-bottom: 20px;
        cursor: pointer;
      }
      .collection-collapse-block {
        &+.collection-collapse-block{
          padding-top: 30px;
        }
        .collapse-block-title {
          position: relative;
          cursor: pointer;
          text-transform: uppercase;
          font-size: 16px;
          font-weight: 600;
          margin: 0;
          &:after {
            position: absolute;
            display: block;
            top: 0;
            right: -3px;
            content: "\f0d7";
            font-family: FontAwesome;
            color: lighten($black, 26.5%);
          }
        }
        .collection-collapse-block-content {
          .color-selector {
            margin-top: 20px;
          }
          .collection-brand-filter {
            .category-list {
              li {
                display: flex;
                font-size: 16px;
                text-transform: uppercase;
                line-height: 18px;
                a {
                  color: $gray;
                  padding-bottom: 10px;
                }
                &:last-child {
                  a {
                    padding-bottom: 0;
                  }
                }
                &:first-child {
                  margin-top: 20px;

                }
              }
            }
            .collection-filter-checkbox {
              margin-bottom: 12px;
              &:first-child {
                margin-top: 20px;
              }
              &:last-child {
                margin-bottom: 0;
              }
              label {
                text-transform: uppercase;
                cursor: pointer;
                font-size: 12px;
                color: lighten($black, 46.5%);
                font-weight: 600;
                letter-spacing: 0.5px;
                &:before {
                  top: 1px;
                  height: 15px;
                  width: 15px;
                  background: $white;
                  border: 1px solid lighten($black, 46.5%);
                }
                &:after {
                  top: 1px;
                  height: 15px;
                  width: 15px;
                  background-size: 65%;
                }
              }
              .custom-control-input {
                &:checked {
                  ~ .custom-control-label {
                    &:after {
                      background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
                    }
                  }
                }
              }
            }
          }
        }
      }
      .color-selector {
        ul {
          li {
            display: inline-block;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            margin-right: 5px;
            cursor: pointer;
            border: 1px solid $gray;
          }
          .color-1 {
            background-color: $white;
          }
          .color-2 {
            background-color: $color2;
          }
          .color-3 {
            background-color: $color3;
          }
          .color-4 {
            background-color: $color4;
          }
          .color-5 {
            background-color: $color5;
          }
          .color-6 {
            background-color: $color6;
          }
          .color-7 {
            background-color: $color7;
          }
          li.active {
            border: 1px solid $black;
            position: relative;
            &:after {
              content: "";
              background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
              top: 10px;
              right: 4px;
              height: 15px;
              width: 15px;
              background-size: 70%;
              background-repeat: no-repeat;
              position: absolute;
            }
          }
        }
      }
      .theme-card {
        position: relative;
        &.center-align {
          display: flex;
          height: 100%;
          align-items: center;
          .offer-slider {
            .product-box2 {
              .media {
                .media-body {
                  margin-top: 0;
                }
              }
            }
          }
        }
        &.card-border {
          border: 1px solid $border-white;
          h5 {
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 30px;
            margin-top: 0;
          }
          .slick-prev, .slick-next {
            top: -45px;
          }
          .slick-next {
            right: 30px;
          }
          .slick-prev {
            right: 50px;
          }
          .offer-slider {
            padding-top: 0;
            padding-bottom: 10px;
            img {
              padding: 15px 15px 15px 30px;
            }
            .media {
              .media-body {
                a {
                  h6 {
                    margin-right: 25px;
                  }
                }
              }
            }
          }
        }
        h5 {
          font-weight: 700;
          text-transform: uppercase;
          padding-bottom: 10px;
          margin-top: -5px;
          &.title-border {
            border-bottom: 1px solid $border-white;
          }
        }
        .slick-prev, .slick-next {
          top: -35px;
          &:before {
            font: normal normal normal 30px/1 FontAwesome;
            color: $black;
            opacity: 0.6;
          }
        }
        .slick-next {
          right: 0;
          &:before {
            content: "\f105";
          }
        }
        .slick-prev {
          left: unset;
          right: 25px;
          &:before {
            content: "\f104";
          }
        }
        .offer-slider {
          img {
            height: 160px;
            padding: 15px 15px 15px 0;
          }
          > div {
            .media {
              &:last-child {
                .media-body {
                  margin-top: 15px;
                }
                img {
                  margin-bottom: -15px;
                }
              }
            }
          }
          .media {
            .media-body {
              .rating {
                margin-top: 0;
                i {
                  padding-right: 5px;
                  &:nth-child(-n + 4) {
                    color: $yellow;
                  }
                  &:last-child {
                    color: $border-white;
                  }
                }
              }
              a {
                h6 {
                  margin-right: 61px;
                  line-height: 17px;
                  margin-top: 5px;
                  margin-bottom: 0;
                }
              }
              h4 {
                margin-top: 10px;
                font-weight: 700;
                color: $primary;
                margin-bottom: 0;
              }
            }
          }
        }
      }
      .collection-sidebar-banner {
        margin-top: 30px;
        img {
          width: 100%;
        }
      }
      .top-banner-wrapper {
        .top-banner-content {
          padding: 50px 0;
          h4 {
            font-weight: 600;
            margin-bottom: 15px;
          }
          h5 {
            font-weight: 600;
            color: lighten($black, 26.5%);
            letter-spacing: 1px;
            line-height: 1.6;
            margin-bottom: 16px;
          }
          p {
            line-height: 1.5;
            color: lighten($black, 46.5%);
            margin-bottom: 0;
          }
        }
      }
      .product-wrapper-grid.list-view {
        .product-wrap {
          .product-info {
            text-align: left;
            align-self: center;
            padding-left: 15px;
          }
        }
        .product-box {
          display: flex;
          padding-bottom: 0;
          .img-wrapper, .img-block {
            width: 25%;
          }
          .product-detail {
            padding-left: 15px;
            align-self: center;
            text-align: left !important;
            .rating {
              margin-top: 0;
            }
            p {
              display: block !important;
              margin-bottom: 5px;
              line-height: 18px;
            }
            .color-variant {
              padding-top: 10px;
            }
            h6 {
              font-weight: 700;
              padding-bottom: 15px;
            }
          }
        }
      }
      .product-accordion {
        .btn-link {
          color: $primary;
          &:hover {
            text-decoration: none;
          }
        }
        .card {
          border: none;
        }
        .card-body {
          width: 100%;
          height: auto;
        }
        .card-header {
          padding: 5px 8px;
          border-bottom: 1px dashed rgba(0, 0, 0, 0.125);
          background-color: rgba(127, 127, 127, 0.03);
          h5 {
            font-weight: 600;
          }
        }
      }
      .bundle {
        margin-top: 10px;
        .bundle_img {
          display: flex;
          .img-box {
            img {
              max-width: 115px;
              border: 1px solid #ddd;
              padding: 2px;
            }
          }
          .plus {
            display: flex;
            align-items: center;
            padding: 0 7px;
            font-size: 22px;
          }
        }
        .bundle_detail {
          margin-top: 15px;
          .price_product {
            color: $black;
            padding-left: 3px;
            font-weight: 700;
          }
          label {
            display: block;
          }
        }
        .theme_checkbox {
          label {
            display: block;
            position: relative;
            padding-left: 30px;
            margin-bottom: 12px;
            cursor: pointer;
            font-size: 14px;
            user-select: none;
            text-transform: capitalize;
            color: $primary;
            input {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              height: 0;
              width: 0;
              &:checked {
                ~ .checkmark {
                  &:after {
                    display: block;
                  }
                }
              }
            }
            .checkmark {
              position: absolute;
              top: 0;
              left: 0;
              height: 20px;
              width: 20px;
              background-color: $white;
              border: 1px solid $border-white;
              &:after {
                content: "";
                position: absolute;
                display: none;
                left: 7px;
                top: 3px;
                width: 5px;
                height: 10px;
                border: solid black;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
              }
            }
          }
        }
      }
      @keyframes fadeInDown {
        from {
          opacity: 0;
          -webkit-transform: translate3d(0, -100%, 0);
          transform: translate3d(0, -100%, 0);
        }

        to {
          opacity: 1;
          -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
        }
      }
      .collection-product-wrapper {
        .col-grid-box {
          .cart-info {
            &.cart-wrap {
              i {
                background: white;
                margin-bottom: 5px;
                border: 1px solid $border-white;
              }
            }
          }
        }
        .isotopeSelector {
          margin-bottom: 30px;
        }
        .product-top-filter {
          border-bottom: 1px solid lighten($black, 86.5%);
          border-top: 1px solid lighten($black, 86.5%);
          .product-filter-content, .popup-filter {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .search-count, .sidebar-popup {
              font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1920 - 300)));
              border-right: 1px solid lighten($black, 86.5%);
              padding: 20px 16px;
              text-transform: capitalize;
              width: 33%;
              h5 {
                margin-bottom: 0;
              }
            }
            .collection-view {
              padding: 20px;
              width: 12%;
              ul {
                li {
                  display: inline-block;
                  height: 100%;
                  vertical-align: middle;
                  cursor: pointer;
                  &:first-child {
                    margin-right: 14px;
                  }
                }
              }
            }
            .collection-grid-view {
              padding: 20px;
              width: 20%;
              border-right: 1px solid lighten($black, 86.5%);
              ul {
                display: flex;
                justify-content: flex-end;
                li {
                  margin-left: 10px;
                  img {
                    height: 18px;
                    cursor: pointer;
                  }
                }
              }
            }
            .product-page-per-view, .product-page-filter {
              position: relative;
              width: 20%;
              select {
                border: 0;
                padding: 24px;
                border-right: 1px solid lighten($black, 86.5%);

                width: 100%;
                -webkit-appearance: none;
                -moz-appearance: none;
                cursor: pointer;
                background: url('../images/inner-page/dropdown.png') no-repeat 95%;
                &:focus {
                  outline: unset;
                }
                option {
                  padding: 20px 0;
                }
              }
            }
            .product-page-filter {
              width: 15%;
              &:before {
                right: 10px;
              }
              select {
                border-right: none;
              }
            }
          }
          .popup-filter {
            justify-content: unset;
            ::-webkit-scrollbar {
              width: 3px;
            }
            ::-webkit-scrollbar-track {
              background: $border-white;
            }
            ::-webkit-scrollbar-thumb {
              background: $gray;
            }
            ::-webkit-scrollbar-thumb:hover {
              background: $gray;
            }
            .sidebar-popup {
              position: relative;
              cursor: pointer;
              width: 22%;
              background: url('../images/inner-page/dropdown.png') no-repeat 95%;
              a {
                font-size: 16px;
                color: $primary;
              }
              .collection-filter {
                background-color: $light-inner;
                width: 270px;
                height: 600px;
                overflow-y: scroll;
                .collection-filter-block {
                  border: none;
                }
                .theme-card {
                  padding: 0 30px;
                }
                .collection-sidebar-banner {
                  padding: 0 30px;
                  img {
                    width: 100%;
                  }
                }
              }
            }
            .open-popup {
              display: none;
              position: absolute;
              z-index: 9;
              top: 75px;
              left: 0;
              border: 1px solid $gray;
              box-shadow: 0 0 5px $border-white;
              min-width: 330px;
              &.open {
                display: block;
              }
              .collection-filter-block {
                margin-bottom: 0;
              }
            }
            .product-page-per-view, .product-page-filter {
              width: 20%;
              select {
                padding-right: 45px;
                font-size: 16px;
              }
              &:before {
                right: 35px;
              }
            }
            .collection-grid-view {
              padding: 20px;
            }
            .collection-view {
              width: 20%;
            }
            .product-page-filter {
              &:before {
                right: 25px !important;
              }
            }
          }
        }
        .product-wrapper-grid {
          .product-box {
            margin-top: 50px;
            .product-detail {
              p {
                display: none;
              }
            }
          }
          .product-five {
            flex: 0 0 20%;
            max-width: 20%;
            position: relative;
            width: 100%;
            min-height: 1px;
            padding-right: 15px;
            padding-left: 15px;
          }
          .col-lg-2 {
            .product-box {
              .product-detail {
                h6 {
                  font-size: 13px;
                }
                h4 {
                  font-size: 18px;
                }
                .rating {
                  i {
                    padding-right: 0;
                  }
                }
              }
              .img-wrapper {
                .lable-block {
                  .lable3 {
                    padding: 8px 4px;
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
      .filter-main-btn {
        display: none;
        margin-bottom: 20px;
        cursor: pointer;
        span {
          width: 40%;
          border-radius: 0;
          font-size: 20px;
        }
      }
      .product-box, .product-wrap {
        .badge{
          position: absolute;
          left: 10px;
          top: 10px;
          z-index: 1;
          padding: 5px 10px;
          font-weight: 400;
          font-size: 12px;
        }
        .product-detail{
          a{
            padding-top: 15px;
            display: block;
          }
          .rating{
            &~a{
              padding-top: 0;
            }
          }
        }
        .product-detail, .product-info {
          padding-left: 5px;
          .rating {
            margin-top: 15px;
            margin-bottom: 10px;
            i {
              padding-right: 5px;
              &:nth-child(-n + 4) {
                color: $yellow;
              }
              &:last-child {
                color: $border-white;
              }
            }
          }
          h6 {
            line-height: 1;
            margin-bottom: 0;
            padding-top: 2px;
            padding-bottom: 10px;
            transition: all 0.5s ease;
            font-size: 16px;
            font-weight: 400;
          }
          h4 {
            font-size: 18px;
            color: $primary;
            font-weight: 600;
            margin-bottom: 0;
            transition: all 0.5s ease;
          }
          .color-variant {
            padding-top: 15px;
            li {
              display: inline-block;
              height: 20px;
              width: 20px;
              border-radius: 100%;
              margin-right: 5px;
              transition: all 0.3s ease;
              cursor: pointer;
            }
          }
        }
      }
      .image-swatch {
        margin-bottom: 10px;
        li {
          img {
            width: 33px;
            height: 33px;
            padding: 2px;
            border: 1px solid $border-white;
            margin-right: 5px;
            transition: all 0.5s ease;
          }
          &:last-child {
            margin-right: 0;
          }
          &.active {
            img {
              border: 1px solid $primary;
            }
          }
          &:hover {
            img {
              border: 1px solid $primary;
              transition: all 0.5s ease;
            }
          }
        }
      }
      .image-scroll {
        div {
          > div {
            padding-bottom: 20px;
          }
        }
      }
      .vertical-tab {
        &.tab-product, .product-full-tab {
          .nav-material {
            &.nav-tabs {
              .nav-item {
                .nav-link {
                  padding: 15px 20px;
                }
              }
            }
          }
        }
      }
      .tab-product, .product-full-tab {
        padding-top: 30px;
        .nav-material {
          &.nav-tabs {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            .nav-item {
              .nav-link {
                color: $gray17;
                text-align: center;
                padding: 0 15px 20px 15px;
                text-transform: uppercase;
                border: 0;
              }
              .material-border {
                border-bottom: 2px solid $primary;
                opacity: 0;
              }
            }
            .nav-link {
              font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1920 - 300)));
              &.active {
                color: $primary;
                font-weight: 600;
                & ~ .material-border {
                  transition: all 0.3s ease;
                  opacity: 1;
                }
              }
            }
          }
        }
        .theme-form {
          input {
            border-color: $border-white;
            font-size: 15px;
            padding: 15px 25px;
            margin-bottom: 15px;
            height: inherit;
            text-align: left;
          }
          .btn-solid, .btn-outline {
            margin: 0 auto;
          }
          textarea {
            border-color: $border-white;
            font-size: 15px;
            padding: 17px 25px;
            margin-bottom: 15px;
            height: inherit;
          }
        }
        .tab-content.nav-material {
          p {
            padding: 20px;
            line-height: 2;
            letter-spacing: 0.05em;
          }
          .media {
            margin-top: 20px;
          }
        }
        .title {
          padding-right: 45px;
          color: $primary;
          padding-bottom: 20px;
        }
        .theme-slider {
          .slick-arrow {
            top: -45px;
            height: auto;
            :before {
              color: $black;
              font-size: 18px;
            }
          }
        }
        .product-box {
          position: relative;
          margin: 5px;
          &:hover {
            box-shadow: 0 0 12px 0 $border-white;
            .lbl-1 {
              opacity: 1;
              transition: all 0.3s ease;
            }
            .lbl-2 {
              opacity: 1;
              animation: flipInY 1000ms ease-in-out;
            }
            .color-variant li {
              opacity: 1 !important;
              animation: fadeInUp 500ms ease-in-out;
            }
          }
          .img-block {
            min-height: unset;
          }
          .cart-info {
            position: absolute;
            padding: 10px 0;
            top: 25%;
            right: 15px;
            width: 40px;
            margin-right: 0;
            i {
              padding-right: 0;
            }
            a, button {
              color: $primary;
              transition: all 0.3s ease;
              background-color: $white;
              height: 35px;
              width: 35px;
              margin: 7px 0;
              border-radius: 100%;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              border: 0;
              box-shadow: 0 0 12px 0 $border-white;
              :hover {
                transition: all 0.3s ease;
                color: $border-white;
              }
            }
          }
          .lbl-1 {
            background-color: $primary;
            padding: 2px 20px 2px 10px;
            display: inline-block;
            text-align: center;
            color: $white;
            position: absolute;
            left: 0;
            top: 15px;
            font-size: 14px;
            line-height: 1.5;
            opacity: 0;
            &:before {
              content: "";
              position: absolute;
              right: 0;
              top: 0;
              width: 0;
              height: 0;
              border-top: 12px solid $primary;
              border-bottom: 13px solid $primary;
              border-right: 7px solid $white;
            }
          }
          .lbl-2 {
            font-size: 14px;
            top: 15px;
            position: absolute;
            right: 10px;
            color: $gray10;
            font-weight: 600;
            text-transform: capitalize;
            opacity: 0;
          }
          a {
            color: $blue5;
            font-size: 15px;
            font-weight: 700;
            letter-spacing: 1px;
          }
          .color-variant {
            position: absolute;
            top: -35px;
            width: 100%;
          }
          .slick-slide img {
            display: block;
          }
          .product-details {
            position: relative;
            .color-variant {
              position: absolute;
              top: -35px;
              width: 100%;
              li {
                opacity: 0;
                display: inline-block;
                height: 15px;
                width: 15px;
                border-radius: 100%;
                margin: 0 3px;
                transition: all 0.3s ease;
                cursor: pointer;
              }
            }
            h6 {
              color: $gray10;
              font-weight: 600;
              letter-spacing: 1px;
              text-transform: capitalize;
            }
            .price {
              padding-bottom: 10px;
              font-size: 16px;
              color: $primary;
            }
          }
        }
      }
      .qty-box {
        .input-group {
          justify-content: center;
          span {
            button {
              background: $white !important;
              border: 1px solid $white5;
            }
          }
          .form-control {
            text-align: center;
            width: 80px;
            flex: unset;
          }
          button {
            background-color: transparent;
            border: 0;
            color: $gray;
            cursor: pointer;
            padding-left: 12px;
            font-size: 12px;
            font-weight: 600;
            line-height: 1;
            i {
              font-weight: 900;
              color: $primary;
            }
          }
          .icon {
            padding-right: 0;
          }
        }
      }
      .bg-light0 {
        background-color: $bg-light1;
      }
      .bg-light1 {
        background-color: $bg-light2;
      }
      .bg-light2 {
        background-color: $bg-light3;

      }
      .team-sec {
        .container {
          margin-bottom: -30px;
          .speker-container {
            margin-bottom: 30px;
            .e-name {
              margin-bottom: 15px !important;
            }
          }
        }
        &.team-grid {
          .team-img {
            flex: none;
            margin-bottom: 0 !important;
          }
          .employee {
            padding: 20px;
            text-align: left !important;
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
          .employee {
            background: white;
            box-shadow: 1px 0 15px 2px $border-blog;
          }
          .e-name {
            margin-bottom: 15px;
          }
          .team-para {
            margin-top: 25px;
            font-size: calc(13px + (15 - 13) * ((100vw - 300px) / (1920 - 300)));
            letter-spacing: 0;
          }
        }
      }
      .product-right {
        p {
          margin-bottom: 0;
          line-height: 1.5em;
          margin-top: unset;
        }
        .product-title {
          color: $primary;
          text-transform: capitalize;
          font-weight: 700;
          margin-top: 10px;
          margin-bottom: 10px;
        }
        .border-product {
          padding-top: 15px;
          padding-bottom: 20px;
          border-top: 1px dashed $border-white;
        }
        h2 {
          text-transform: uppercase;
          margin-bottom: 15px;
          font-size: 25px;
          line-height: 1.2em;
          margin-top: 0;
          font-weight: 600;
        }
        h3 {
          color: $primary;
          margin-bottom: 15px;
        }
        h4 {
          font-size: 16px;
          margin-bottom: 7px;
          del {
            color: $gray;
          }
          span {
            padding-left: 5px;
            color: $primary;
          }
        }
        .color-variant {
          margin-bottom: 10px;
          li {
            display: inline-block;
            border-radius: 100%;
            margin-right: 5px;
            -webkit-transition: all 0.3s ease;
            transition: all 0.3s ease;
            vertical-align: middle;
            height: 30px;
            width: 30px;
            cursor: pointer;
          }
        }
        .product-buttons {
          .btn-solid, .btn-outline {
            padding: 7px 25px;
          }
          a {
            &:last-child {
              margin-left: 10px;
            }
          }
        }
        .product-description {
          h6 {
            span {
              float: right;
            }
          }
          .qty-box {
            display: flex;
            align-items: center;
            margin-top: 10px;
            .input-group {
              justify-content: unset;
              width: unset;
              .form-control {
                border-right: none;
              }
            }
          }
        }
        .size-box {
          margin-top: 10px;
          margin-bottom: 10px;
          ul {
            li {
              display: inline-block;
              height: 35px;
              width: 35px;
              border-radius: 50%;
              margin-right: 10px;
              cursor: pointer;
              border: 1px solid $white6;
              text-align: center;
              a {
                color: $primary;
                font-size: 18px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
              }
              &.active {
                background-color: $white6;
              }
            }
          }
        }
        .product-icon {
          display: flex;
          .product-social {
            margin-top: 5px;
            li {
              padding-right: 30px;
              display: inline-block;
              a {
                color: $primary;
                transition: all 0.3s ease;
                i {
                  font-size: 18px;
                }
                &:hover {
                  color: $primary;
                }
              }
              &:last-child {
                padding-right: 0;
              }
            }
          }
          .wishlist-btn {
            background-color: transparent;
            border: none;
            i {
              border-left: 1px solid $border-white;
              font-size: 18px;
              padding-left: 10px;
              margin-left: 5px;
              transition: all 0.5s ease;
            }
            span {
              padding-left: 10px;
              font-size: 18px;
            }
            &:hover {
              i {
                color: $primary;
                transition: all 0.5s ease;
              }
            }
          }
        }
        .payment-card-bottom {
          margin-top: 10px;
          ul {
            li {
              padding-right: 10px;
            }
          }
        }
        &.product-form-box {
          text-align: center;
          border: 1px solid $border-white;
          padding: 20px;
          .product-description {
            .qty-box {
              margin-bottom: 5px;
              .input-group {
                justify-content: center;
                width: 100%;
              }
            }
          }
          .product-buttons {
            margin-bottom: 0;
          }
          .timer {
            margin-bottom: 10px;
            text-align: left;
          }
        }
      }
      .product-load-more {
        .col-grid-box {
          display: none;
        }
      }
      .load-more-sec {
        margin-top: 40px;
        text-align: center;
        a {
          font-size: 18px;
          text-transform: uppercase;
          display: block;
          padding: 10px 0;
          border-top: 1px solid $gray;
          border-bottom: 1px solid $gray;
          width: 100%;
          color: $primary;
        }
      }
      .product-box, .product-wrap {
        position: relative;
        transition: all 0.5s ease;
        vertical-align: middle;
        .img-block {
          background-color: $gray;
          position: relative;
          overflow: hidden;
          .front {
            opacity: 1;
            top: 0;
            left: 0;
            transition: all 0.5s ease;
            a {
              display: block;
              width: 100%;
            }
          }
          .back {
            opacity: 0;
            position: absolute;
            backface-visibility: hidden;
            top: 0;
            left: 0;
            transition: all 0.5s ease;
            transform: translateX(-100px);
            a {
              display: block;
              height: 500px;
              width: 100%;
            }
          }
          .lable-wrapper {
            margin: 0 auto;
            top: 40px;
            position: absolute;
            left: 0;
            right: 0;
            text-align: center;
            transition: all 0.5s ease;
            z-index: 2;
            .lable1, .lable2 {
              font-size: 14px;
              padding: 10px 14px 10px 20px;
              display: inline-block;
              text-transform: uppercase;
              text-align: center;
            }
            .lable1 {
              background-color: $primary;
              color: $white;
              border-bottom-left-radius: 25px;
              border-top-left-radius: 25px;
            }
            .lable2 {
              background-color: $white;
              color: $black;
              border-bottom-right-radius: 25px;
              border-top-right-radius: 25px;
            }
          }
        }
        .img-wrapper {
          position: relative;
          overflow: hidden;
          .front {
            opacity: 1;
            top: 0;
            left: 0;
            transition: all 0.5s ease;
          }
          .back {
            opacity: 0;
            position: absolute;
            backface-visibility: hidden;
            top: 0;
            left: 0;
            transition: all 0.5s ease;
            transform: translateX(-100px);
            width: 100%;
          }
          .cart-box {
            position: absolute;
            margin: 0 auto;
            display: inline-block;
            right: 0;
            left: 0;
            border-radius: 50px;
            width: max-content;
            padding: 12px 15px;
            box-shadow: 0 0 12px 0 $border-white;
            bottom: 30px;
            background-color: $white;
            opacity: 0;
            transition: all 0.2s ease;
            button {
              background: none;
              box-shadow: none;
              border: none;
              padding: 0;
            }
            i {
              color: $gray11;
              font-size: 18px;
              padding-left: 8px;
              padding-right: 8px;
              transition: all 0.2s ease;
              display: inline-block;
              &:hover {
                color: $primary;
                transition: all 0.2s ease;
              }
            }
          }
          .lable-block {
            .lable3 {
              border-radius: 100%;
              background-color: $primary;
              text-align: center;
              font-size: 14px;
              font-weight: 700;
              position: absolute;
              padding: 12px 6px;
              text-transform: uppercase;
              color: $white;
              top: 7px;
              left: 7px;
              z-index: 1;
            }
            .lable4 {
              position: absolute;
              font-size: 14px;
              font-weight: 700;
              text-transform: uppercase;
              writing-mode: vertical-rl;
              transform: rotate(-180deg);
              top: 7px;
              right: 7px;
              letter-spacing: 0.1em;
              z-index: 1;
            }
          }
        }
        .cart-info, .cart-wrap {
          position: absolute;
          bottom: 40px;
          text-align: center;
          margin: 0 auto;
          display: inline-block;
          right: 5px;
          left: 0;
          justify-content: center;
          background-color: #fff;
          margin-bottom: 10px;
          padding: 5px;
          opacity: 0;
          transition: all 0.5s ease;
          a {
            [class^="icon-"], [class*=" icon-"] {
              display: inline-block;
            }
            &:last-child {
              i {
                margin-bottom: 0;
              }
            }
          }
          &.cart-wrap {
            bottom: 0;
            text-align: right;
            left: unset;
            i {
              display: block;
              padding-bottom: 7px;
              padding-top: 7px;
            }
            &.cart-effect-left {
              left: 0;
              right: unset;
            }
          }
          button {
            background: none;
            box-shadow: none;
            border: none;
            padding: 0;
          }
          i {
            color: $gray11;
            font-size: 16px;
            padding-right: 7px;
            padding-left: 7px;
            &:hover {
              color: $primary;
            }
          }
        }
        .cart-detail {
          position: absolute;
          top: 15px;
          right: 20px;
          opacity: 0;
          i {
            color: $gray11;
            font-size: 18px;
            display: flex;
            padding-top: 8px;
            padding-bottom: 8px;
          }
          button {
            background: none;
            box-shadow: none;
            border: none;
            padding: 0;
          }
        }
        .product-detail, .product-info {
          padding-left: 5px;
          .rating {
            margin-top: 15px;
            i {
              padding-right: 5px;
              &:nth-child(-n + 4) {
                color: $yellow;
              }
              &:last-child {
                color: $border-white;
              }
            }
          }
          h6 {
            line-height: 1;
            margin-bottom: 0;
            padding-top: 2px;
            padding-bottom: 5px;
            transition: all 0.5s ease;
            font-size: 16px;
          }
          h4 {
            font-size: 18px;
            color: $primary;
            font-weight: 600;
            margin-bottom: 0;
            transition: all 0.5s ease;
          }
          .color-variant {
            padding-top: 15px;
            li {
              display: inline-block;
              height: 20px;
              width: 20px;
              border-radius: 100%;
              margin-right: 5px;
              transition: all 0.3s ease;
              cursor: pointer;
            }
          }
        }
        .product-info {
          padding: 0;
          text-align: center;
          position: relative;
          .add-btn {
            position: absolute;
            bottom: 110px;
            margin: 0 auto;
            left: 0;
            right: 0;
            opacity: 0;
            transition: all 0.2s ease;
            z-index: 1;
            i {
              color: $primary;
            }
            .btn-outline {
              transition: all 0.2s ease;
              color: $primary;
              &:hover {
                color: $white;
                i {
                  color: $white;
                }
              }
            }
          }
        }
        &.effect-center {
          .front {
            img {
              transition: all 0.5s ease;
            }
          }
          .img-wrapper {
            .cart-box {
              bottom: 20%;
              transition: all 0.5s ease;
            }
          }
        }
        &:hover {
          &.effect-center {
            .front {
              img {
                opacity: 0.3;
                transition: all 0.5s ease;
              }
            }
            .img-wrapper {
              .cart-box {
                bottom: 35%;
                transition: all 0.5s ease;
              }
            }
          }
          .img-block, .img-wrapper {
            .first {
              opacity: 0;
              transition: all 0.5s ease;
            }
            .back {
              opacity: 1;
              transition: all 0.5s ease;
              transform: translateX(0);
            }
          }
          .cart-info {
            opacity: 1;
            transition: all 0.5s ease;
            button {
              animation: fadeInUp 300ms ease-in-out;
            }
            a {
              &:nth-child(2) i {
                animation: fadeInUp 500ms ease-in-out;
              }
              &:nth-child(3) i {
                animation: fadeInUp 700ms ease-in-out;
              }
              &:nth-child(4) i {
                animation: fadeInUp 1000ms ease-in-out;
              }
            }
          }
          .cart-wrap {
            button {
              animation: fadeInRight 300ms ease-in-out;
            }
            a {
              &:nth-child(2) i {
                animation: fadeInRight 500ms ease-in-out;
              }
              &:nth-child(3) i {
                animation: fadeInRight 700ms ease-in-out;
              }
              &:nth-child(4) i {
                animation: fadeInRight 1000ms ease-in-out;
              }
            }
            &.cart-effect-left {
              button {
                animation: fadeInLeft 300ms ease-in-out;
              }
              a {
                &:nth-child(2) i {
                  animation: fadeInLeft 500ms ease-in-out;
                }
                &:nth-child(3) i {
                  animation: fadeInLeft 700ms ease-in-out;
                }
                &:nth-child(4) i {
                  animation: fadeInLeft 1000ms ease-in-out;
                }
              }
            }
          }
          .cart-detail {
            opacity: 1;
            transition: all 0.5s ease;
            button {
              animation: fadeInRight 300ms ease-in-out;
            }
            a {
              &:nth-child(2) i {
                animation: fadeInRight 500ms ease-in-out;
              }
              &:nth-child(3) i {
                animation: fadeInRight 700ms ease-in-out;
              }
              &:nth-child(4) i {
                animation: fadeInRight 1000ms ease-in-out;
              }
            }
          }
          .product-info {
            .add-btn {
              opacity: 1;
              transition: all 0.2s ease;
              animation: fadeInUp 500ms ease-in-out;
            }
          }
          .img-wrapper {
            .cart-box {
              opacity: 1;
              transition: all 0.2s ease;
              animation: fadeInUp 400ms ease-in-out;
            }
          }
        }
      }
      .rating {
        margin-top: 0;
        i {
          padding-right: 5px;
          &:nth-child(-n + 4) {
            color: $yellow;
          }
          &:last-child {
            color: $border-white;
          }
        }
        .three-star {
          padding-bottom: 5px;
          i {
            color: $gray12;
            &:nth-child(-n + 3) {
              color: $yellow2;
            }
          }
        }
      }
      .single-product-tables {
        display: flex;
        margin-top: 20px;
        table {
          width: 20%;
          tr {
            height: 35px;
            td:first-child {
              font-weight: 600;
            }
          }
        }
        &.detail-section {
          margin-top: 0;
          table {
            width: 55%;
          }
        }
      }
      /*************************
      category ends
      *************************/

      /*************************
      Typography Start
      *************************/

      .product-pagination {
        border-bottom: 1px solid lighten($black, 86.5%);
        border-top: 1px solid lighten($black, 86.5%);
        margin: 50px 0;
        .product-search-count-bottom {
          justify-content: flex-end;
          display: flex;
          align-items: center;
          height: 100%;
          border-left: 1px solid lighten($black, 86.5%);
          border-right: 1px solid lighten($black, 86.5%);
          padding-right: 15px;
          h5 {
            color: lighten($black, 46.5%);
            font-size: 14px;
            margin-bottom: 0;
            padding: 5px 0;
          }
        }
        .pagination {
          border-radius: 0;
          .page-item {
            border-radius: 0;
            a {
              padding: 18px;
              border-bottom: 0;
              border-top: 0;
              color: lighten($black, 46.5%);
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
            &:last-child {
              .page-link {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
              }
            }
          }
          .page-item.active {
            a {
              color: $white;
              background-color: $primary;
              border-color: lighten($black, 86.5%);
            }
          }
        }
      }
      .typography_section {
        .typography-box {
          margin-bottom: 30px;
          box-shadow: 0 1px 11px 0 rgba(0, 0, 0, 0.1);
          p {
            margin-bottom: 20px;
            letter-spacing: 0;
            line-height: 22px;
            font-size: 14px;
            color: $gray18;
          }
          dl {
            dt {
              margin-bottom: 6px;
            }
            dd {
              margin-bottom: 15px;
            }
          }
          .headings {
            border-bottom: 1px solid $lighten-white2;
            background-color: rgba($light-white, 0.5);
            padding: 20px;
            margin-bottom: 0;
            h3 {
              text-transform: capitalize;
              color: $gray19;
              margin-bottom: 0;
              font-weight: 400;
              font-size: 20px;
            }
            span {
              display: block;
              color: $gray;
              margin-top: 5px;
              h1{
                margin-top: 0;
              }
              code {
                color: $gray;
              }
            }
          }
          .typo-content {
            padding: 15px;

            h1{
              margin-top: 0;
            }
            &.lists {
              ul {
                margin-bottom: 25px;
              }
            }
            &.heading_content {
              h1,
              h2,
              h3,
              h4,
              h5,
              h6 {
                font-weight: 400;
                color: $black;
                text-transform: uppercase;
                line-height: 1;
                margin-bottom: 20px;
              }
            }
            ul, ol {
              li {
                margin-bottom: 8px;
              }
            }
            .sub-title {
              border-bottom: 1px solid rgba(0, 0, 0, 0.1);
              padding-bottom: 5px;
              margin-bottom: 15px;
              font-size: 18px;
              color: $primary;
              text-transform: capitalize;
            }
            &.product-pagination {
              border: none;
              margin: 0;
              .pagination {
                .page-item {
                  border-top: 1px solid $border-white;
                  border-bottom: 1px solid $border-white;
                  a {
                    padding: 10px 14px;
                  }
                }
              }
            }
            &.input_button {
              input {
                margin-bottom: 10px;
              }
              label {
                font-size: 16px;
                text-transform: capitalize;
                padding-left: 5px;
              }
            }
            &.loader-typo {
              width: 100%;
              justify-content: center;
              display: flex;
              .pre-loader {
                position: relative;
                width: 50px;
                height: 50px;
                background-color: $primary;
                border-radius: 50%;
                &:after {
                  content: '';
                  position: absolute;
                  border-radius: 50%;
                  top: 50%;
                  left: 50%;
                  border: 0 solid white;
                  transform: translate(-50%, -50%);
                  animation: loading 1000ms ease-out forwards infinite;
                  transition: all 0.3s ease;
                }
              }
            }
            .footer-social {
              margin-top: 0;
              justify-content: center;
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
        code {
          color: rgba($primary, 0.6);
          background-color: $light-white;
          padding: 3px;
          margin: 0 3px;
          border-radius: 2px;
        }
      }
      //login//
      .login-page {
        h3 {
          margin-bottom: 20px;
          text-transform: uppercase;
          color: $primary;
          font-weight: 700;
          margin-top: -5px;
        }
        .theme-card {
          padding: 30px;
          border: 1px solid $border-white;
          line-height: 1;
          .theme-form {
            label {
              text-transform: capitalize;
              color: $primary;
              font-size: 14px;
              font-weight: 600;
            }
            input {
              border-color: $border-white;
              font-size: 12px;
              padding: 17px 25px;
              margin-bottom: 30px;
              height: inherit;
            }
          }
        }
        .authentication-right {
          height: calc(100% - 48px);
          h6 {
            text-transform: uppercase;
            color: $primary;
            font-weight: 600;
            margin-bottom: 20px;
          }
          p {
            font-size: 14px;
            color: $gray;
            line-height: 2;
            margin-bottom: 1rem;
          }
        }
      }
      .bg-light-inner {
        background: $light-inner !important;
      }
      .bg-gradient-color {
        background: linear-gradient($dark, $light);
      }
      /*************************
      Typography ends
      *************************/

      /*************************
      register Start
      *************************/
      .register-page {
        .text-sub {
          margin-bottom: 15px;
        }
        h3 {
          margin-bottom: 20px;
          text-transform: uppercase;
          color: $primary;
          font-weight: 700;
          margin-top: -5px;
        }
        .theme-card {
          padding: 30px;
          border: 1px solid $border-white;
          line-height: 1;
          .theme-form {
            label {
              text-transform: capitalize;
              color: $primary;
              font-size: 14px;
              font-weight: 600;
            }
            input, textarea {
              border: 1px solid;
              border-color: $border-white;
              font-size: 12px;
              padding: 15px 25px;
              margin-bottom: 30px;
              height: inherit;
              background: $white;
              border-radius: 0;
            }
            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
              color: #495057;
            }
            &::-moz-placeholder { /* Firefox 19+ */
              color: #495057;
            }
            &:-ms-input-placeholder { /* IE 10+ */
              color: #495057;
            }
            &:-moz-placeholder { /* Firefox 18- */
              color: #495057;
            }
          }
        }
      }
      .alert-section{
        .card {
          box-shadow: 0 1px 11px 0 rgba(0, 0, 0, 0.1);
          margin-bottom: 30px;
          border: 0;
          .card-header {
            border-bottom: 1px solid rgba(0, 0, 0, 0.060);
            padding: 30px 20px;
            background: $white;
            .card-head {
              margin-top: 0;
            }
          }
          .card-body {
            .alert {
              &.outline {
                background-color: transparent !important;
                &.alert-primary {
                  border-color: $alert-primary;
                  color: $alert-primary;
                }
                &.alert-secondary {
                  border-color: $alert-secondary;
                  color: $alert-secondary;
                }
                &.alert-success {
                  border-color: $alert-success;
                  color: $alert-success;
                }
                &.alert-info {
                  border-color: $alert-info;
                  color: $alert-info;
                }
                &.alert-warning {
                  border-color: $alert-warning;
                  color: $alert-warning;
                }
                &.alert-danger {
                  border-color: $alert-danger;
                  color: $alert-danger;
                }
                &.alert-dark {
                  border-color: $alert-dark;
                  color: $alert-dark;
                }
              }
              a {
                letter-spacing: 0;
                text-transform: none;
                font-weight: 400;
              }
            }
          }
        }
      }
      .saas1 {
        &.according {
          .text-sub {
            margin-bottom: 20px;
          }
        }
        .client-box{
          .saas-brand{
            .owl-item img {
              max-width: 100px;
              margin: 0 auto;
            }
          }
        }
        &.client {
          padding: 50px 0;
          overflow: hidden;
        }
        .testimonial-slider {
          .owl-stage-outer {
            margin-right: 28px;
          }
        }
      }
      .shadow-section {
        .shadow-showcase {
          .shadow-font {
            font-weight: 500;
          }
        }
      }
      .btn-section {
        .margin-buttons {
          margin-bottom: -15px;
        }
        .btn-default {
          margin-right: 12px;
          margin-bottom: 15px;
          &.primary-btn {
            &.secondary {
              background: $secondary;
              &:hover {
                background: transparent;
                color: $secondary !important;
                border-color: $secondary;
              }
              &.btn-white {
                color: $secondary !important;
                border-color: $secondary !important;
                background: $white;
                &:hover {
                  color: $white !important;
                  background: $secondary;
                }
              }
            }
            &.success {
              background: $success;
              &:hover {
                background: transparent;
                color: $success !important;
                border-color: $success;
              }
              &.btn-white {
                color: $success !important;
                border-color: $success !important;
                background: $white;
                &:hover {
                  color: $white !important;
                  background: $success;
                }
              }
            }
            &.info {
              background: $blue6;
              &:hover {
                background: transparent;
                color: $blue6 !important;
                border-color: $blue6;
              }
              &.btn-white {
                color: $blue6 !important;
                border-color: $blue6 !important;
                background: $white;
                &:hover {
                  color: $white !important;
                  background: $blue6;
                }
              }
            }
            &.warning {
              background: $yellow4;
              &:hover {
                background: transparent;
                color: $yellow4 !important;
                border-color: $yellow4;
              }
              &.btn-white {
                color: $yellow4 !important;
                border-color: $yellow4 !important;
                background: $white;
                &:hover {
                  color: $white !important;
                  background: $yellow4;
                }
              }
            }
            &.danger {
              background: $red3;
              &:hover {
                background: transparent;
                color: $red3 !important;
                border-color: $red3;
              }
              &.btn-white {
                color: $red3 !important;
                border-color: $red3 !important;
                background: $white;
                &:hover {
                  color: $white !important;
                  background: $red3;
                }
              }
            }
            &.light {
              background: $yellow5;
              &:hover {
                background: transparent;
                color: $yellow5 !important;
                border-color: $yellow5;
              }
              &.btn-white {
                color: $yellow5 !important;
                border-color: $yellow5 !important;
                background: $white;
                &:hover {
                  color: $white !important;
                  background: $yellow5;
                }
              }
            }
          }
        }
      }
      .borders-before {
        span {
          &.white-border {
            &:before {
              border-color: $white !important;
            }
          }
        }
      }
      .title {
        &.title2 {
          &.title-inner {
            .borders {
              span {
                &.white-border {
                  &:before, &:after {
                    border-color: $white;
                  }
                }
                &:before, &:after {
                  border-color: $primary;
                  opacity: 0.6;
                }
              }
            }
          }
        }
      }
      .side-section {
        height: 100vh;
        position: fixed;
        left: 0;
        top: 0;
        width: 400px;
        background: $white;
        display: none;
        z-index: 608;
        box-shadow: 0 3px 15px 3px rgba(46, 97, 220, 0.35);
        overflow-Y: scroll;
      }
      .parallex-section {

        .img-1 {
          background: url("../images/portfolio/portfolio-new/1.jpg");
        }
        .img-2 {
          background: url("../images/portfolio/portfolio-new/4.jpg");
        }
        .img-3 {
          background: url("../images/portfolio/portfolio-new/2.jpg");
        }
        .img-4 {
          background: url("../images/portfolio/portfolio-new/5.jpg");
        }
        .img-5 {
          background: url("../images/portfolio/portfolio-new/3.jpg");
        }
        .parallex-container {
          background-position: center;
          background-size: cover;
          height: 100vh;
          background-repeat: no-repeat;
          background-attachment: fixed;
        }
      }
      .vertical-slider {
        .prtfolio-full-screen {
          img {
            width: 100vw;
            height: 100vh;

          }
        }
        .dot {
          position: absolute;
          top: 50%;
          right: 50px;
          transform: translateY(-50%);
        }

        .dot > li {
          width: 16px;
          height: 16px;
          margin-bottom: 10px;
          border: 2px solid $white;
          border-radius: 50%;
          background-color: transparent;
          box-sizing: border-box;
          list-style: none;
          cursor: pointer;
        }

        .dot > li.my-active-class,
        .dot > li:hover {
          background-color: $white;
        }
      }
      .multiple-carousel, .center-slide {
        height: 100vh;
        .img1 {
          background: url("../images/portfolio/multiple-carousel/5.jpg");
        }
        .img2 {
          background: url("../images/portfolio/multiple-carousel/4.jpg");
        }
        .img3 {
          background: url("../images/portfolio/multiple-carousel/3.jpg");
        }
        .img4 {
          background: url("../images/portfolio/multiple-carousel/2.jpg");
        }
        .img5 {
          background: url("../images/portfolio/multiple-carousel/1.jpg");
        }
        .imgs-carousel {
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          height: 100vh;
          &:before {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: rgba($black, 0.6);
            opacity: 0;
            transition: 0.5s;
          }
        }
        .text-container {
          .overlay-text, .right-side {
            opacity: 0;
            transition: 0.5s;
          }
          .overlay-text {
            font-size: 26px;
            margin-bottom: 10px;
            transform: translateX(-50%);
          }
          .right-side {
            padding: 0 20px;
            transform: translateX(50%);
            color: $gray8;
          }
        }
        .owl-item {
          overflow: hidden;
          &.center {
            .imgs-carousel {
              &:before {
                opacity: 1;
                transition: 0.5s;
              }
            }
            .text-container {
              .overlay-text, .right-side {
                transition: 0.5s;
                transform: unset;
                opacity: 1;
              }
            }
          }
        }
      }
      .multiple-carousel {
        .owl-item {
          &:hover {
            .imgs-carousel {
              &:before {
                opacity: 1;
                transition: 0.5s;
              }
            }
            .text-container {
              .overlay-text, .right-side {
                transition: 0.5s;
                transform: unset;
                opacity: 1;
              }
            }
          }
        }
      }
      .maintenance-sec {
        background: url("../images/inner-page/maintain-soon/bg-maintenance.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        overflow: hidden;
        height: 100vh;
        .maintenance-container {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
          h1, h2, h4 {
            color: $white;
          }
          h1 {
            font-size: 100px;
            font-weight: 500;
            margin-bottom: 80px;
            margin-top: 0;
          }
          .borders {
            border-bottom: 0.5px solid $white;
            margin: 0 auto;
            &.b1 {
              width: 220px;
            }
            &.b2 {
              width: 180px;
            }
            &.b3 {
              width: 150px;
            }
            &.b4 {
              width: 130px;
            }
          }
          h2 {
            margin-top: 10px;
          }
        }
      }
      .maintenance-sec, .coming-soon {
        .social {
          ul {
            li {
              display: inline-block;
              padding: 5px 8px;
              margin-left: 10px;
              background-color: $primary;
              height: 40px;
              width: 40px;
              border-radius: 100%;

              a {
                i {
                  color: $white;
                  font-size: 20px;
                  opacity: 0.7;
                  transition: 0.5s;
                }
              }
              &:hover {
                a {
                  i {
                    opacity: 1;
                    transition: 0.5s;

                  }
                }
              }
            }
          }
        }
        .center-content{
          .form-group{
            width: 60%;
            .d-flex{
              align-items: center;
            }
            input{
              border-radius: 25px;
              padding: 15px 25px;
            }
          }
          button{
            border-radius: 35px;
            margin-left: -50px;
          }

        }
      }
      .bg-background-fade {
        transition: all 500ms;
        background: $color-creative1;
      }
      .creative-content {
        background: url("../images/creative/1.png");
        background-size: cover;
        height: 100vh;
        background-repeat: no-repeat;
        background-position: bottom;
      }
      .creative {
        body, h1, h2, h3, h4, h5, h6, p, li, a {
          font-family: 'Work Sans', sans-serif;
        }
        h1 {
          font-weight: 500;
          margin-bottom: 15px;
          text-transform: uppercase;
        }
        hr {
          margin-bottom: 20px;
          background: $white;
        }
        h6 {
          margin: 0;
        }
        .center-header {
          font-size: calc(18px + (40 - 18) * ((100vw - 300px) / (1920 - 300)));
          margin-bottom: 20px;
        }
        .agency-para {
          padding: 0 50px;
          margin: 0 50px;
        }
        .btn-default {
          padding: 10px 36px;
        }
        .sub-texts {
          color: $white;
        }
        .title {
          &.title2 {
            .borders {
              span {
                &:before, &:after {
                  border-top: 1px solid #f5f5f8;
                  width: 40px;
                }
              }
            }
          }
        }
      }
      .portfolio-metro {
        &.bg {
          background: url("../images/portfolio/portfolio-new/font.png");
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
        body, h1, h2, h3, h4, h5, h6, p, li, a {
          font-family: 'Montserrat', sans-serif;
        }
        h1 {
          font-size: 80px;
          margin-bottom: 50px;
        }
        .portfolio_section {
          display: flex;
          align-items: center;
          height: 100%;
          h1 {
            margin-top: 0;
          }
          h6 {
            margin-bottom: -3px;
          }
        }
        .sub-header {
          text-transform: uppercase;
          font-weight: 500;
          letter-spacing: 1px;
          color: #777;
        }
        .center-content {
          height: auto;
        }
      }
      .music {
        ::-webkit-scrollbar {
          width: 10px;
        }
        ::-webkit-scrollbar-thumb {
          background-image: linear-gradient(rgba($music-light, 0.92) 0%, $music-dark 100%);
          border-radius: 25px;
        }
        &.side-section {
          .btn-sidebar {
            position: absolute;
            left: 0;
            top: 0;
            font-size: 20px;
            color: $white !important;
            opacity: 0.8;
            padding: 25px 18px;
          }
          .jp-type-playlist {
            .jp-gui {
              .gradient-block {
                background-image: linear-gradient(rgba($music-light, 0.92) 0%, $music-dark 100%);
                position: relative;
                overflow: hidden;
                height: 30vh;
                .animation-circle-inverse {
                  opacity: 0.25;
                  position: absolute;
                  left: 50%;
                  bottom: 0;
                  margin: 0 auto;
                  transform: translateY(-50%);
                  i {
                    background: $white;
                    position: absolute;
                    height: 55px;
                    width: 55px;
                    border-radius: 100%;
                    left: -28px;
                    bottom: -33px;
                    opacity: 0.3;
                    animation: ripple1 3s linear infinite;
                    &:nth-child(2) {
                      animation: ripple2 3s linear infinite;
                    }
                    &:nth-child(3) {
                      animation: ripple3 3s linear infinite;
                    }
                  }
                }
                .now-play {
                  font-size: calc(13px + (16 - 13) * (100vw - 330px) / (1920 - 330));
                  padding-top: 30px;
                  opacity: 0.8;
                }
              }
              .girls {
                border-radius: 100%;
                padding: 20px;
                background-color: $white;
                transform: translate(0, -50%);
                box-shadow: 0 6px 30px 1px rgba(37, 67, 138, 0.18);
              }
              .author-text {
                z-index: 3;
                position: relative;
                h4, h6 {
                  color: $black
                }
                .song-text {
                  margin-top: -50px;
                  margin-bottom: 3px;
                  color: $black;
                }
                .song-sub-text {
                  color: $gray;
                }
              }
            }
          }
          .jp-playlist {
            margin-top: 30px;
          }
          .jp-details,
          .jp-playlist {
            width: 100%;
            border-top: 1px solid $gray8;
          }
          .side-player {
            .author-text {
              display: flex;
              flex-direction: column;
              justify-content: center;
            }
            .jp-progress {
              margin-top: 35px;
            }
          }
          .jp-controls {
            .button-container {
              padding: 20px;
              button {
                height: 30px;
                width: 30px;
                color: $white;
                border-radius: 100%;
                background-color: #8080804f;
                &.jp-play {
                  height: 50px;
                  width: 50px;
                  background-image: linear-gradient($music-light 0%, $music-dark 100%);
                  background-color: white;
                  box-shadow: 0 3px 15px 3px rgba(46, 97, 220, 0.25);
                }
              }
            }
          }
        }
      }

      .animated-bg i {
        background: $border-white;
        bottom: 0;
        box-shadow: 0 15px 30px 0 $border-white;
        position: absolute;
        height: 100px;
        width: 100px;
        border-radius: 100%;
        opacity: 0.3;
        transform: scale(1.3);
        animation: ripple1 3s linear infinite;
        &:nth-child(2) {
          animation: ripple2 3s linear infinite;
        }
        &:nth-child(3) {
          animation: ripple3 3s linear infinite;
        }
      }
      @keyframes ripple1 {
        0% {
          transform: scale(5.5);
          opacity: 0.3;
        }
        100% {
          transform: scale(8.5);
          opacity: 0.0;
        }
      }
      @keyframes ripple2 {
        0% {
          transform: scale(3.5);
        }
        100% {
          transform: scale(5.5);
        }
      }
      @keyframes ripple3 {
        0% {
          transform: scale(1.5);
        }
        100% {
          transform: scale(3.5);
        }
      }


      header {
        z-index: 600;
        width: 100%;
        &.nav-lg{
          padding: 2rem 1rem;
          nav ul li a{
            font-size: 15px;
          }
        }
        &.resume{
          .e-btn i{
            color: $black !important;
          }
        }
        &.wedding{
          .responsive-btn i{
            color: $primary !important;
          }
        }
        &.ecommerce{
          position: fixed;
          .navbar{
            position: unset;
          }
          nav{
            justify-content: space-between;
            ul.main-menu{
              > li{
                > a{
                  color: $black;
                  padding-top: 30px;
                  padding-bottom: 30px;
                }
                &:hover{
                  > a{
                    color: #676767;
                  }
                }
              }
            }
            .top-header-right{
              ul{
                li{
                  &.cart{
                    .dropdown-menu{
                      border:0;
                      padding:0;
                      margin-top: 20px;
                    }
                  }
                  .shopping-cart{
                    width: 350px;
                    padding: 30px;
                    border: 1px solid #ddd;
                    right: 0;
                    li{
                      .total{
                        border-top: 1px solid gray;
                        padding-top: 10px;
                        margin-top: 20px;
                      }
                      .checkout{
                        float: right;
                      }
                      &+li{
                        margin-top: 15px;
                      }
                    }
                    .close-circle {
                      position: absolute;
                      right: 0;
                      top: 10px;
                      i{
                        color: $gray12;
                      }
                    }
                    a{
                      min-width: unset;
                      width: unset;
                      padding: 0;
                    }
                    img{
                      width: 70px;
                    }
                  }
                  &.search{
                    .dropdown-menu{
                      border: 0;
                      padding: 0;
                      .form-control-plaintext{
                        padding: 10px 20px;
                        margin-top: 20px;
                        border: 1px solid $round-border;
                        background-color: $white;
                      }
                    }
                  }
                  &.account{

                    .dropdown-menu{
                      padding: 10px;
                      margin-top: 20px;
                      border: 1px solid #ddd;
                      border-radius: 0;
                    }
                  }
                  a{
                    padding: 12px;
                  }
                  i{
                    color: $black;
                    font-size: 20px;
                    font-weight: 500;
                    &.icon-shopping-cart-full{
                      font-size: 22px;
                    }

                  }
                }
              }
            }
          }
        }
        &.nav-fix {
          position: fixed;
        }
        &.nav-abs {
          position: absolute;
        }
        .responsive-btn {
          display: none;
          i {
            font-size: calc(22px + (25 - 22) * ((100vw - 300px) / (1920 - 300)));
            color: $white !important;
          }
        }
        nav {
          position: relative;
          display: flex;
          align-items: center;
          margin: 0;
          border: 0;
          .navbar-container {
            padding: 10px 0;
          }
          ul {
            margin: 0;
            border: 0;
            line-height: 1;
            list-style-type: none;
            text-align: center;
            li {
              margin: 0;
              border: 0;
              line-height: 1;
              position: relative;
              display: inline-block;
              text-align: center;
              a {
                margin: 0;
                border: 0;
                line-height: 1;
                text-decoration: none;
                display: inline-block;
                padding: 18px;
                font-size: 14px;
                text-transform: uppercase;
                transition: all .4s ease-in-out;
                font-weight: 500;
                letter-spacing: 0;
              }
              > a {
                color: white;
                &:hover {
                  color: white;
                }
              }
            }
            .sub-menu {
              ul {
                box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
                z-index: 1;
              }
            }
            .sub-menu > a, .dropdown {
              &:before {
                position: absolute;
                font: normal normal normal 14px/1 FontAwesome;
                right: 18px;
                top: 50%;
                transform: translateY(-50%);
                content: "\f105";
              }
            }
            .dropdown {
              &:before {
                content: "\f0d7";
              }
            }

            ul {
              margin: 0;
              border: 0;
              line-height: 1;
              transition: all .4s ease-in-out;
              background: $white;
              padding: 15px 0;
              li {
                margin: 0;
                border: 0;
                line-height: 1;
                display: block;
                text-align: left;
                transition: all .4s ease-in-out;
                position: relative;
                a {
                  margin: 0;
                  border: 0;
                  text-transform: capitalize;
                  width: 100%;
                  min-width: 240px;
                  padding: 10px 18px;
                  white-space: nowrap;
                  color: #323232 !important;
                  font-weight: 400;
                  letter-spacing: 0;
                  font-size: 13px;
                  &:hover {
                    color: $primary !important;
                    transition: all 0.5s ease;
                  }
                }
                &:hover, &:focus, &:active {
                  > a {
                    color: $gray15;
                    transform: translateX(2%);
                  }
                }
              }
            }

            .mega-menu {
              position: unset;
              .mega-menu-container {
                transition: all 0.3s ease-in;
                min-width: 1020px;
                left: unset;
                right: 0;
                padding: 20px 0;
                background: $white;
                z-index: 9;
                overflow: hidden;
                box-shadow: 0 5px 40px rgba(0, 0, 0, 0.15);
                ul {
                  position: relative;
                  padding: 0 10px;
                }
              }
            }
          }
          .menu-head {
            margin-bottom: 10px;
            color: $primary;
            display: flex;
            padding: 10px 28px 10PX 10px;
            letter-spacing: 0;
            font-weight: 600;
            BORDER-BOTTOM: 1px solid #eee;
            text-transform: capitalize;
          }
          .menu-arrow {
            font-size: 10px;
          }
        }
        &.music{
          position: absolute;
        }
        &.resume{
          nav{
            ul{
              li{
                a:hover{
                  color: #262626;
                }
              }
            }
          }
        }
        &.wedding{
          .logo-abs{
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
          }
          &.header-rel{
            background-color: rgba($white,0.75);
            border-bottom: 1px dashed $primary;
          }
        }
        &.yoga{
          .logo-abs{
            position: absolute;
            top: 22%;
            left: 50%;
            transform: translateX(-50%);
          }
        }
        &.portfolio-metro{
          nav{
            .responsive-btn{
              a{
                &:hover{
                  color: $black;
                  opacity: 0.7;
                }
              }
            }
            ul{
              li{
                > a {
                  color: $black;
                  &:hover {
                    color: $black;
                  }
                }
              }
            }
          }
        }
        &.wedding{
          nav{
            ul{
              li{
                > a{
                  &:hover{
                    color: $primary;
                  }
                }
              }
            }
          }
        }
        &.dark{
          position: absolute;
          nav{
            .responsive-btn{
              a{
                &:hover{
                  color: $black;
                  opacity: 0.7;
                }
              }
            }
            ul{
              li{
                > a {
                  color: $black;
                  &:hover {
                    color: $black;
                  }
                }
              }
            }
          }
        }
      }


      /*************************
      Footer start
      *************************/
      footer{
        &.app2{
          h5{
            color: $white;
            padding-bottom:55px;
          }
        }
        &.gym{
          h5{
            color: $white;
            padding-bottom:55px;
          }
        }
        &.saas1{
          h5{
            color: $white;
            padding-bottom:55px;
            margin-bottom: unset;
          }
        }
      }
      .inner-pages-footer{
        &.resume{
          .link-horizontal{
            &.social-link{
              margin-bottom: -5px;
              margin-top: 20px;
            }
          }
        }
      }
      .blog-sidebar {
        .sidebar-container {
          .post-container {
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .form-control:focus{
        box-shadow: none;
        background-color: #f5f5f8;
      }

      .event{
        .event-btn{
          color: #FFFFFF!important;
          border: 1px solid white!important;
          background-color: #e3154f!important;
          &:hover{
            background-color: #fff!important;
            color: #e3154f!important;
            border: 1px solid #e3154f!important;
          }
        }
        &.event-gradient{
          &::before{
            background-image: linear-gradient(#321575, #e3154f);
          }
        }
      }
      .color-animated{
        animation: color-change 1s infinite;
        color: $primary;
        margin-left: 10px;
      }
      .menu-container{
        @keyframes color-change {
          0% { color: $primary; }
          50% { color: #e3154f; }
          100% { color: $light; }
        }
        .menu-icon{
          position: absolute;
          li{
            a{
              padding-left: 22px;
              &:before{
                display: none;
              }
              i{
                position: absolute;
                left: 0;
              }
            }
          }
        }
        li {
          a{
            &:before{
              content: "";
              position: absolute;
              width: 4px;
              height: 1px;
              background-color: black;
              left: 0;
              top: 50%;
            }
          }
        }
      }
      .breadcrumb-section{
        .breadcrumb{
          margin-bottom: -2px;
        }
      }

      .blog-contain{
        &:hover{
          box-shadow: 1px 1px 10px 1px #eee;
        }
      }
      // product page //
      .product-slick, .rtl-product-slick, .product-right-slick, .rtl-product-right-slick {
        .slick-prev, .slick-next {
          opacity: 0;
          transform: scale(1);
          transition: all 0.5s ease;
          margin-top: -10px;
          &:before {
            font: normal normal normal 14px/1 $font-awesome;
            opacity: 1;
            color: black;
            background-color: white;
            border-radius: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .slick-next {
          right:1px;
          &:before {
            content: "\f105";
          }
        }
        .slick-prev {
          left: 1px;
          z-index: 1;
          &:before {
            content: "\f104";
          }
        }
        .slick-slide {
          > div {
            display: flex;
            margin-bottom: 15px;
          }
        }
        &:hover {
          .slick-prev, .slick-next {
            opacity: 1;
            transform: scale(1.05);
            transition: all 0.5s ease;
            &:before {
              opacity: 1;
            }
          }
          .slick-next {
            right:20px;
          }
          .slick-prev {
            left: 20px;
          }
        }
      }
      .slider-nav, .rtl-slider-nav, .slider-right-nav, .rtl-slider-right-nav {
        .slick-slide {
          > div {
            display: flex;
            margin: 15px 15px 0 15px;
            border: 1px solid $round-border;
            transition: all 0.5s ease;
          }
          &.slick-current {
            > div {
              border-color: $font-grey;
              transition: all 0.5s ease;
            }
          }
          &.slick-active {
            transition: all 0.5s ease;
          }
        }
      }
      .slider-right-nav, .rtl-slider-right-nav {
        .slick-slide {
          &:first-child {
            > div {
              margin-top: 0;
            }
          }
        }
      }
      .pro_sticky_info {
        border: 1px solid $round-border;
        padding: 20px;
      }
      .is_stuck {
        margin-top: 80px;
      }
      .image-scroll {
        div {
          > div {
            padding-bottom: 20px;
            &:last-child {
              padding: 0;
            }
          }
        }
      }
      .product-right {
        p {
          margin-bottom: 0;
          line-height: 1.5em;
        }
        .product-title {
          color: $dark-font;
          text-transform: capitalize;
          font-weight: 700;
          margin-bottom: 0;
        }
        .border-product {
          padding-top: 15px;
          padding-bottom: 20px;
          border-top: 1px dashed $round-border;
        }
        h2 {
          text-transform: uppercase;
          margin-bottom: 15px;
          font-size: 25px;
          line-height: 1.2em;
        }
        h3 {
          font-size: 26px;
          color: $dark-font;
          margin-bottom: 15px;
        }
        h4 {
          font-size: 16px;
          margin-bottom: 7px;
          del {
            color: $grey;
          }
          span {
            padding-left: 5px;
            color: $theme-deafult;
          }
        }
        .color-variant {
          margin-bottom: 10px;
          li {
            height: 30px;
            width: 30px;
            cursor: pointer;
          }
        }
        .product-buttons {
          margin-bottom: 20px;
          .btn-solid, .btn-outline {
            padding: 7px 25px;
          }
          a {
            &:last-child {
              margin-left: 10px;
            }
          }
        }
        .product-description {
          h6 {
            span {
              float: right;
            }
          }
          .qty-box {
            display: flex;
            align-items: center;
            margin-top: 10px;
            .input-group {
              justify-content: unset;
              width: unset;
              .form-control {
                border-right: none;
              }
            }
          }
        }
        .size-box {
          margin-top: 10px;
          margin-bottom: 10px;
          ul {
            li {
              height: 35px;
              width: 35px;
              border-radius: 50%;
              margin-right: 10px;
              cursor: pointer;
              border: 1px solid $grey-about;
              text-align: center;
              a {
                color: $dark-font;
                font-size: 18px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
              }
              &.active {
                background-color: $grey-about;
              }
            }
          }
        }
        .product-icon {
          display: flex;
          .product-social {
            margin-top: 5px;
            li {
              padding-right: 30px;
              a {
                color: $font-color;
                transition: all 0.3s ease;
                i {
                  font-size: 18px;
                }
                &:hover {
                  color: $theme-deafult;
                }
              }
              &:last-child {
                padding-right: 0;
              }
            }
          }
          .wishlist-btn {
            background-color: transparent;
            border: none;
            i {
              border-left: 1px solid $round-border;
              font-size: 18px;
              padding-left: 10px;
              margin-left: 5px;
              transition: all 0.5s ease;
            }
            span {
              padding-left: 10px;
              font-size: 18px;
            }
            &:hover {
              i {
                color: $theme-deafult;
                transition: all 0.5s ease;
              }
            }
          }
        }
        .payment-card-bottom {
          margin-top: 10px;
          ul {
            li {
              padding-right: 10px;
            }
          }
        }
        .timer {
          p {
            color: $dark-font;
          }
          margin-top: 10px;
          background-color: $grey-about;
        }
        &.product-form-box {
          text-align: center;
          border: 1px solid $round-border;
          padding: 20px;
          .product-description {
            .qty-box {
              margin-bottom: 5px;
              .input-group {
                justify-content: center;
                width: 100%;
              }
            }
          }
          .product-buttons {
            margin-bottom: 0;
          }
          .timer {
            margin-bottom: 10px;
            text-align: left;
          }
        }
      }
      .single-product-tables {
        display: flex;
        margin-top: 20px;
        table {
          width: 20%;
          tr {
            height: 35px;
            td:first-child {
              font-weight: 600;
            }
          }
        }
        &.detail-section {
          margin-top: 0;
          table {
            width: 55%;
          }
        }
      }
      .product-related {
        h2 {
          color: $dark-font;
          padding-bottom: 20px;
          border-bottom: 1px solid #dddada;
          margin-bottom: 20px;
        }
      }
      .rating {
        margin-top: 0;
        i {
          padding-right: 5px;
          &:nth-child(-n + 4){
            color: $star-yellow;
          }
          &:last-child {
            color:$round-border;
          }
        }
        .three-star {
          padding-bottom: 5px;
          i {
            color: #acacac;
            &:nth-child(-n + 3) {
              color: #ffd200;
            }
          }
        }
      }
      .tab-border {
        border: 1px solid $round-border;
        margin: 0;
        padding: 8px 0;
        .nav-border {
          border-bottom: 0;
          border-right: 1px solid $round-border;
        }
      }
      .vertical-tab {
        &.tab-product, .product-full-tab {
          .nav-material {
            &.nav-tabs {
              .nav-item {
                .nav-link {
                  padding: 15px 20px;
                }
              }
            }
          }
        }
      }
      .tab-product, .product-full-tab {
        padding-top: 30px;
        .nav-material {
          &.nav-tabs {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            .nav-item {
              .nav-link {
                color: #212121;
                text-align: center;
                padding: 0 15px 20px 15px;
                text-transform: uppercase;
                border: 0;
              }
              .material-border {
                border-bottom: 2px solid $theme-deafult;
                opacity: 0;
              }
            }
            .nav-link.active {
              color: $theme-deafult;
              & ~ .material-border {
                transition: all 0.3s ease;
                opacity: 1;
              }
            }
          }
        }
        .theme-form {
          input{
            border-color: $round-border;
            font-size: 15px;
            padding: 15px 25px;
            margin-bottom: 15px;
            height: inherit;
            text-align: left;
          }
          .btn-solid, .btn-outline {
            margin: 0 auto;
          }
          textarea {
            border-color: $round-border;
            font-size: 15px;
            padding: 17px 25px;
            margin-bottom: 15px;
            height: inherit;
          }
        }
        .tab-content.nav-material {
          p {
            padding: 20px;
            line-height: 2;
            letter-spacing: 0.05em;
          }
          .media{
            margin-top: 20px;
          }
        }
        .title {
          padding-right: 45px;
          color: $theme-deafult;
          padding-bottom: 20px;
        }
        .theme-slider {
          .slick-arrow {
            top: -45px;
            height: auto;
            :before {
              color: $black;
              font-size: 18px;
            }
          }
        }
        .product-box {
          position: relative;
          margin: 5px;
          &:hover {
            box-shadow: 0 0 12px 0 #dddddd;
            .lbl-1 {
              opacity: 1;
              transition: all 0.3s ease;
            }
            .lbl-2 {
              opacity: 1;
              animation: flipInY 1000ms ease-in-out;
            }
            .color-variant li {
              opacity: 1 !important;
              animation: fadeInUp 500ms ease-in-out;
            }
          }
          .img-block{
            min-height: unset;
          }
          .cart-info {
            position: absolute;
            padding: 10px 0;
            top: 25%;
            right: 15px;
            width: 40px;
            margin-right: 0;
            i {
              padding-right: 0;
            }
            a, button {
              color: $font-color;
              transition: all 0.3s ease;
              background-color: $white;
              height: 35px;
              width: 35px;
              margin: 7px 0;
              border-radius: 100%;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              border: 0;
              box-shadow: 0 0 12px 0 $round-border;
              :hover {
                transition: all 0.3s ease;
                color: $theme-deafult;
              }
            }
          }
          .lbl-1 {
            background-color: $theme-deafult;
            padding: 2px 20px 2px 10px;
            display: inline-block;
            text-align: center;
            color: #ffffff;
            position: absolute;
            left: 0;
            top: 15px;
            font-size: 14px;
            line-height: 1.5;
            opacity: 0;
            &:before {
              content: "";
              position: absolute;
              right: 0;
              top: 0;
              width: 0;
              height: 0;
              border-top: 12px solid $theme-deafult;
              border-bottom: 13px solid $theme-deafult;
              border-right: 7px solid $white;
            }
          }
          .lbl-2 {
            font-size: 14px;
            top: 15px;
            position: absolute;
            right: 10px;
            color: #333333;
            font-weight: 600;
            text-transform: capitalize;
            opacity: 0;
          }
          a {
            color: #0072bb;
            font-size: 15px;
            font-weight: 700;
            letter-spacing: 1px;
          }
          .color-variant {
            position: absolute;
            top: -35px;
            width: 100%;
          }
          .slick-slide img {
            display: block;
          }
          .product-details {
            position: relative;
            .color-variant {
              position: absolute;
              top: -35px;
              width: 100%;
              li {
                opacity: 0;
                display: inline-block;
                height: 15px;
                width: 15px;
                border-radius: 100%;
                margin: 0 3px;
                transition: all 0.3s ease;
                cursor: pointer;
              }
            }

            h6 {
              color: #333333;
              font-weight: 600;
              letter-spacing: 1px;
              text-transform: capitalize;
            }
            .price {
              padding-bottom: 10px;
              font-size: 16px;
              color: $theme-deafult;
            }
          }
        }
      }
      .product-full-tab {
        padding-top: 70px;
      }
      .product-load-more {
        .col-grid-box {
          display: none;
        }
      }
      .load-more-sec {
        margin-top: 40px;
        text-align: center;
        a {
          font-size: 18px;
          text-transform: uppercase;
          display: block;
          padding: 10px 0;
          border-top:1px solid $round-border;
          border-bottom:1px solid $round-border;
          width: 100%;
          color: $theme-deafult;
        }
      }
      .product_image_4 {
        > div {
          &:nth-last-child(-n + 2) {
            margin-top: 25px;
          }
        }
      }
      .btn-app1-theme{
        border: transparent!important;
        position: relative!important;
        background-color: #4E56F3!important;
        background-image: linear-gradient(to right, #13B8EA, #4E56F3, #4E56F3, #13B8EA)!important;
        transition: all .4s ease-in-out!important;
        background-size: 300% 100%!important;
      }
      .saas1-btn{
        border: 2px solid #071828!important;
        color: #071828 !important;
        &:hover{
          background-color: #071828!important;
          color: #fff !important;
        }
      }
      .saas2-btn{
        border: transparent!important;
        position: relative!important;
        background-color: #5E57EA!important;
        background-image: linear-gradient(to right, #9647DB, #5E57EA, #5E57EA, #9647DB)!important;
        transition: all .4s ease-in-out!important;
        background-size: 300% 100%!important;
      }
      .copyright{
        &.inner-page{
          li{
            line-height: normal;
          }
        }
      }
      .app1{
        .title{
          .main-title{
            h2{
              line-height: 0.9;
            }
          }
        }
      }
      .bg-shadow-top-bottom{
        box-shadow: inset 0 70px 75px -15px rgba(0,0,0,0.8),
        inset 0 -70px 75px -15px rgba(0,0,0,0.8);
      }
      .bg-shadow-top{
        box-shadow: inset 0px -70px 90px -44px $black;
      }
      .bg-unset{
        background: unset!important;
      }
      .about-page{
        background-color: #f8f8f8;
        &.testimonial{
          background-color: #f8f8f8;
          img{
            filter: brightness(25%);
          }
          .avtar-img{
            filter: unset;
          }
          .quotes{
            .quote-text{
              h5{
                color: #0b0b0b;
              }
            }
          }
          .rating{
            .name{
              color: #000;
            }
            .stars{
              i{
                color: #ddd;
              }
            }
          }
        }
        &.counter{
          img{
            filter: brightness(25%);
          }
        }
      }
      /*************************
      Footer ends
      *************************/

      /*************************
      compare start
      *************************/

      //Ecommerce layout css start
      .layout-ecommerce{
        section {
          padding: 70px 0;
        }
        .ecommerce-footer{
          padding-top: 70px;
        }
        .blog-sec.blog .blog-agency{
          margin-bottom: 0;
        }
      }
      .ecommerce{
        &.collection{
          padding-top: 0;
          margin-top: -100px;
          margin-bottom: 30px;
        }
        &.feature-product{
          padding-top: 0;
          margin-bottom: -5px;
        }
        .banner-three{
          background-color: $white;
          border: 1px solid $lighten-white2;
          padding: 30px 15px;
          z-index: 1;
          position: relative;
          .main-wrapper{
            height: 200px;
            background-blend-mode: overlay;
            background-color: rgba(245, 245, 245, 0.2);
            filter: grayscale(1);
            .banner-contain{
              height: 100%;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              opacity: 0;
              transition: all 0.3s ease;
              transform: scale(0);
            }
            &:hover{
              filter: grayscale(0);
              .banner-contain {
                opacity: 1;
                transition: all 0.3s ease;
                transform: scale(1);
              }
            }
          }

        }
        .contain{
          height: 80vh;
          display: flex;
          align-items: center;
          h1{
            color: $gray10;
            margin-bottom: 30px;
            font-weight: 600;
          }
          .btn-default{
            margin-top: 20px;
          }
        }
        &.ecommerce-home{
          padding-top: 90px;
          padding-bottom: 0;
          .ecommerce-home-slider{
            .owl-item,.bg-size{
              height: 80vh;
            }
          }
        }
        .owl-nav{
          margin: 0;
          [class*=owl-]{
            position: absolute;
            top: 50%;
            margin-top: -45px;
            color: $gray10;
            font-size: 24px;
            padding: 12px 13px;
            background: $white;
            border-radius: 100%;
            line-height: 1;
            box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
            &:hover{
              background-color: $light-inner;
              color: $gray10;
            }
            &.owl-prev{
              left:15px;
            }
            &.owl-next{
              right:15px;
            }
          }
        }
        &.nav-fix{
          background-color: $white;
        }
        .brand-slider{
          .item{
            img{
              max-width: 150px;
              margin: 0 auto;
            }
          }
        }
      }
      .ecommerce-footer{
        background-color: $gray17;
        padding-bottom: 0;
        .sub-footer{
          padding-top: 30px;
          background-color: black;
          margin-top: 50px;
          padding-bottom: 20px;
          p{
            padding-top: 8px;
          }
        }
        .payment-card-bottom{
          text-align: right;
          ul{
            li{
              display: inline-block;
              &+li{
                padding-left: 10px;
              }
            }
          }
        }
        .footer-title{
          h4{
            color: $gray13;
          }
        }
        .footer-contant{
          >ul{
            li{
              &+li{
                margin-top: 15px;
              }
              a{
                color: $gray12;
              }
            }
          }
          .contact-list{
            li{
              color: $gray12;
              padding-left: 25px;
              position: relative;
              i{
                position: absolute;
                left: 0;
                top: 3px;
              }
            }
          }
        }
        .footer-social{
          ul{
            li{
              display: inline-block;
              height: 35px;
              width: 35px;
              background-color: $gray13;
              text-align: center;
              line-height: 2.6;
              margin-top: 20px;
              margin-right: 10px;
              border-radius: 100%;
            }
          }
        }
        .footer-logo,h4{
          margin-bottom: 30px;
        }
        p{
          color: $gray12;
        }
        .footer-mobile-title{
          display: none;
        }
      }
      .bg-decore{
        background-size: cover;
        background-attachment: fixed;
        height: 100%;
        width: 100%;
        padding: 0 15px;
        animation: 10s linear 0s normal none infinite animate;
      }
      .modal1{
        .modal-bg{
          height: 350px;
        }
        p{
          padding-top: 10px;
        }
        .form-group{
          input{
            padding: 12px 20px;
            margin-bottom: 15px;
            margin-top: 20px;
          }
        }
        .offer-content{
          display: flex;
          align-items: center;
          justify-content: center;
          padding-right: 30px;
          text-align: center;
          > div{
            padding: 30px;
          }
        }
        .close{
          position: absolute;
          top: 10px;
          right: 30px;
        }
      }
      @keyframes animate {
        from {
          background-position: 0 0;
        }
        to {
          background-position: 0 500px;
        }
      }
      .down-banner{
        padding-top: 0!important;
        >.container{
          position: relative;
        }
        .banner-contain{
          margin-left: 50px;
          .btn-default{
            padding: 8px 21px;
            font-size: 13px;
          }
        }
        .small-banner{
          height: 250px;
        }
        .banner-contain {
          position: absolute;
          height: 100%;
          max-width: 50%;
          top: 0;
          display: flex;
          align-items: center;
        }
      }
      .parallax-banner{
        padding: 0 !important;
        .full-banner{
          height: 500px;
          background-attachment: fixed;
        }
        .banner-contain{
          position: absolute;
          height: 100%;
          max-width: 25%;
          top: 0;
          display: flex;
          align-items: center;
          h2{
            margin-bottom: 30px;
          }
          .btn-default{
            padding: 8px 21px;
            font-size: 13px;
          }
        }
      }
      .ecommerce-tab{
        .isotopeContainer{
          .product-box{
            margin-bottom: 30px;
          }
          margin-bottom: -30px;
        }
        .filter-section {
          .filter-container {
            ul.filter {
              > li{
                display: inline-block;
                &.active{
                  background-color: $gray4;
                }
              }
            }
          }
        }
      }






//dark light panel //
.theme-pannel-main {
  background-color: $white;
  position: fixed;
  top: 30%;
  right: 0;
  z-index: 9;
  padding: 0 5px;
  box-shadow: 0 0 7px 1px rgba(158, 158, 158, 0.2);
  ul {
    li {
      span{
        padding: 0;
        text-transform: uppercase;
        color: #949494;
        font-weight: 700;
      }
      a{
        padding: 0;
      }
      display: flex;
      padding: 10px 12px;
      border-bottom: 1px solid #dadada;
      &:last-child{
        border-bottom: 0;
      }
    }
  }
}
