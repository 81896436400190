/**54. Rtl css start**/


.rtl {
  direction : rtl;
  text-align:right;
  .coming-soon{
    .count.no-border{
     border-right: 1px solid #777;
   }
   .set-count{
    .counters-content{
      &:first-child{
        .count{
          border-right: 0;
        }
      }
    }
  }
}
.header{
 .center-text{
  direction :rtl;
}
.bg{
  &.app2-header{
    .header-text{
      h1{
        &:before{
          right:0;
        }
      }
    }
  }
}
.wave-orange{
  &:after{
    display:none;
  }
}
}
header{
 nav{
  a{
    img{
                // filter:brightness(0) invert(1);
              }
            }
            ul{

             .sub-menu{
              &>a{
               &:before{
                left: 18px;
                right:unset;
              }
            }
          }
          .dropdown{
            &:before{
              left: 18px;
              right:unset;
            }
          }
          .mega-menu{
            .mega-menu-container{
              right: unset;
              left: 0;
            }
          }
          ul{
            li{
              text-align: right;
            }
          }
        }
      }
      .menu-container {
        .menu-icon{
          li{
            a{
              padding-right: 22px;
              padding-left : unset;
              i{
                right: 0;
                left : unset;
              }
            }
          }
        }
      }
      .color-animated{
        margin-right: 10px;
        margin-left : unset;
      }
      .center-text{
        direction  :unset;
      }
      .main-menu {
        &.mr-auto  {
          margin-left: auto !important;
          margin-right: unset !important;

        }
      }
      &.wedding{
        nav{
          a{
            img{
              filter:unset;
            }
          }
        }
        .responsive-btn{
          i{
            color: #c0882f !important;
          }
        }
      }
      &.resume{
        nav{
          a{
            img{
              filter:unset;
            }
          }
        }
      }
      &.dark{
        nav{
          a{
            img{
              filter:unset;
            }
          }
        }
      }
      &.agency{
        .responsive-btn{
          i{
            color: #10266b !important;
          }
        }
      }
      &.dark{
        nav{
          ul{
            li{
              >a{
                color: #000;

              }
            }
          }
          a{
            img{
              filter:unset;    
            }
          }
        }
      }
      &.yoga{
        .logo-abs{
          right: 50%;
          left:unset;
        }
      }
      &.ecommerce{
        nav{
          a{
            img{
              filter:unset;
            }
          }
        }
      }
    }
    .shadow-section{
      .offset-md-2{
        margin-left: 0;
        margin-right: 16.6666666667%;
      }
    }
    .owl-theme{
      .owl-nav{
        direction: ltr;
      }
    }
    .pull-right{
      float: left;
    }

    .center-content{
      direction :rtl;
    }
    section{
      &.header{
       direction : ltr;
     }
     &.app1, &.event{
      direction : unset;
    }
    &.app2{
      .center-text{
        direction : unset;
      }
    }
    &.saas1{
      &.build-bg{
        direction : ltr;
      }
      &.testimonial{
        .testi-profile{
          .media-body{
            flex: unset;
          }
        }
        .testimonial{
          margin-left: 40px;
          margin-right: 20px;
        }
      }
    }

  }
  .link-horizontal{
    ul{
      direction:rtl;
    }
  }
  .m-r-5{
    margin-left: 5px;
    margin-right:unset;
  }
  .text-left{
    text-align: right !important;
  }
  .m-r-auto{
    margin-left: auto !important;
    margin-right: unset !important;
    img{
      transform: scalex(-1);
    }
  }
  .m-l-auto {
    margin-right: auto;
    margin-left : unset;
  }
  .ml-auto{
    margin-right: auto !important;
    margin-left : unset !important;
  }
  .slick-slider{
    direction: ltr;
  }
  .text-as-shadow {
    right: 250px;
    left: unset;
  }
  .agency-content{
    text-align: right;
    .agency-header-center-container{
      text-align: right;
      .borders{
        margin-left: 30px;
        border-left: 2px solid;
        margin-right : unset;
        border-right:unset;
      }
    }
    .agency-para {
      text-align: right;
      margin-right: 30px;
    }
    .btn-default {
      margin-right: 30px;
    }
    .blue-img{
      right: -14%;
      left :unset;
      transform:scalex(-1);
    }
    .side-img {
      &.left-0 {
        right: 0;
        left  :unset;
        transform: scalex(-1);
      }
    }
  }
  .agency {
    .blog-agency{
      .blog-contain{
        .img-container{
          i{
            margin-left: 10px;
            margin-right:unset;
          }
        }
      }
    }
    .blog_comment{
      &.comment-list{
        .comment_sec{
          .offset-1 {
            margin-right: 8.3333333333%;
          }
          .blog_center{
            .blog_user{
              margin-right: 15px;
              .panel-body{
                .text-left{
                  .comment-user{
                    i{
                      margin-left: 5px;
                    }
                  }
                  .comment-date{
                    margin-right: 10px;
                    i{
                      margin-left: 5px;
                    }
                  }
                }
              }
              .panel-body {
                .link-btn{
                  float: left;
                  .pr-2{
                    padding-left: 0.5rem !important;
                  }
                  i{
                    &.fa-reply{
                      transform:scalex(-1);
                    } 
                  }
                }
              }

            }
          }
        }
      }
    }
    &.blog-sidebar{
      .blog-title{
        text-align  :right;
        &::before{
          right: 0;
        }
      }
      .post-container{

      }
      .sidebar-container{
        .newsletter{
          .form-group{
            i{
              left: 4%;
              right:unset;
            }
          }
        }
        .sidebar-list{
          li{
            a{
              margin-right: 15px;
              margin-left : unset;
              i{
                right: 10px;
                left:unset;
                transform: scalex(-1);
                &.m-r-15{
                  margin-left: 15px;
                  margin-right:unset;
                }
              }
            }
          }
        }
      }
      .post-container{
        div{
          .badge{
            right: 0;
            left  :unset;
          }
        }
      }
      &.blog-split {
        .blog-list{
          &:nth-child(even){
            .center-text{
              justify-content: flex-start;
            }
          }
        }
      }
    }

    &.testimonial-bg{
      direction : ltr;
      text-align : left;
    }
    .navbar{
      background-color: #11266b;
      border-radius: 15px;
    }
    .m-r-auto{
      img{
        filter: brightness(0) invert(1);

      }
    }

  }
  .collection-collapse-block{
    .collapse-block-title{
      &:after{
        left: -3px;
        right:unset;
      }
      .custom-control{
        padding-right: 1.5rem;
      }
    }
  }
  .event {
    .bg-about {
      &:before {
        transform: scalex(3)
      }
    }
  }
  .wedding-time{
    .count{
      padding-right: 15px;
      padding-left : unset;
      &:before{

        right: 0;
        left:unset;
      }
      .set-border-left{
        border-right: 5px solid #fff;
        border-left : unset;
        right: 0;
        left:unset;
      }
      .set-border-bottom{
        right: 5px;
        left:unset;
      }
      &:after{
        border-left: 5px solid #fff;
        border-right:unset;
        right: calc(100% - 20px);
        left: unset;
      }
    }
  }
  nav{
    .logo-erapper{
      transform:scalex(-1);
    }
  }
  .header {
    .rotate{
      left: 17%;
      right: unset;
    }
    .animation-circle-inverse{
      left: 100px;
      right:unset;
    }

  }
  .about{
    &.app1{
      .mobile {
        left: 10%;
        right:unset;

        .rectangle{
          left: -4%;
          right:unset;
          .r-2 {
            left: -2%;
            right:unset;
          }
        }
      }
      .counters {
        border-left: 1px solid #fff;
        border-right : unset;
        &:nth-child(even) {
          border-left: none;
        }
      }
      .mobile.galaxy {
        left: -15%;
        right:unset;
      }
    }
  }
  .center-img-content{
    &.m-l-15 {
      margin-right: 15px;
      margin-left : unset;
    }
  }
  .link-horizontal {
    ul {
      li {
        padding-left: 35px;
        padding-right:unset;
        &.borders-right {
          border-left: 1px solid #fff;
          border-right:unset;
          margin-left: 35px;
          margin-right:unset;
        }
        &:last-child{
          padding-left : 0;
          padding-right:unset;
        }
      }
    }
  }
  .videos {
    .video-description{
      text-align :right;
    }
  }
    /***App landing 2***/
    .app2{
      &.about{
        .set-height{
          .mobile1{
            left: 0;
            right:unset;
          }
          .j-img{
            left: 20%;
            right:unset;
          }
        }
      }
    }
    .quality {
      text-align: right;
    }
    .format{
      .about-icon {
        &:first-child{
          margin-right: 0;
          margin-left : unset;
          
        }
        &:last-child{
          margin-left: 0;
          margin-right:unset;

        }
      }
    }
    footer {
      &.subscribe {
        direction  :unset;
      }
      &.footer2 {
        .link-horizontal{
          ul {
            li{
              &.borders-right{
                border-left: 3px solid #fff;
                border-right:unset;
                margin-left: 15px;
                margin-right:unset;
              }
            }
          }
        }
        .float-right{
          float: left !important;

        }

      }
      .footer-contant {
        .form-group {
          input {
            padding-right: 0;
            margin-left  :unset;

          }
          .fa-paper-plane{
            left: 10px;
            right:unset;
          }
        }
      }
      .logo-sec{
        .footer-social{
          .footer-social-list{
            &:first-child {
              padding-right: 0;
              padding-left : unset;
            }
            &:last-child {
              padding-left: 0;
              padding-right:unset;
            }
          }
        }
      }
      .footer-lists{
        &.contacts{
          li {
            i{
              margin-left: 18px;
              margin-right:unset;

            }
          }
        }
      }
      &.contact{
        .plane2{
          left: 0;
          right : unset;
        }

        .p-r-0 {
          padding-left: 0;
        }
        .p-l-0 {
          padding-right: 0;
        }
        
      }
      .logo-sec{
       .footer-logo{
         transform: scalex(-1);
       }
     }
     &.yoga{
      &.copyright{
        direction:ltr;
      }
    }

  }
  .list{
    ul {
      li{
        padding-right: 0;
        padding-left: 60px;
        &:last-child {
          padding-left: 0 ;
        }
      }
    }
  }
  .swiper-container-3d{
    direction: ltr;
  }
    /***** event layout ***/
    .about{
      &.event{
        .announcer-img {
          transform: scalex(-1);
        }

      }
    }
    /***Gym Layout**/
    .gym {
      input {
        margin-left: 12px;
        margin-right : unset;
      }
      .calculate-bmi{
        tr{
          td{
            padding: 25px 60px 25px 0;
          }
        }
      }
    }
    /***MUSIC Layout**/
    .music {
      &.format{
        .watch-now{
          margin-right: 15px;
          margin-left : unset;
        }
      }
      &.bg-video{
       img{
         transform: scalex(-1);
       }
     }
   }
   .watch{
    .m-l-15{
      margin-right: 15px;
    }
  }
  .saas1 {
    &.faq {
      a{
        div{

          right:0;
          margin-right:unset;
        }
      }
    }
    .faq-img-block {
      .img-fluid {
        transform: scalex(-1);
      }
    }
    &.subscribe{
      .media{
        img{
          margin-left: 20px;
        }
      }
      input[type="submit"]{
        margin-right: -50px;
        margin-left : unset;
      }
      input[type="email"],input[type="submit"]{
        padding-right: 15px;
        padding-left: 15px;
      }
      .subscribe-input{
        h6{
          margin-left: 16px;
          margin-right:unset;
        }
      }
    }
  }
  .saas2 {
    &.feature{
      .center-content{
        .feature-content{
          margin-right: 25px;
        }
      }
    }
    &.quick-sol{
      .set-center-div{
        .side-img {
          left: -14%;
          right : unset;
          transform: scalex(-1);
          top:0;
        }
      }
    }
    .offers-container{
      .offers-content{
        .fa-check-circle{
          margin-left: 20px;
        }
      }
    }
  }
  .wedding{
    .about-img {
      transform: scalex(-1);
    }
    .decore{
      img{
        &:nth-child(2){
          transform: scalex(-1);
        }
      }
    }
    .m-r-10  {
      margin-left: 10px;
    }
    .wedding-time{
      .count{

       .set-border-left{
         border-right: 5px solid #BF862D;
         right: 0;
       }
       .set-border-bottom{
         right: 5px;
       }
       &:after{
         border-left: 5px solid #ECC878;
         right: calc(100% - 20px);
         left: unset;
       }
     }
   }
   .wedding-content{
    .logo-h1{
      margin-right: -170px;
      margin-left : unset;
    }
  }
}
.wedding-content {
  .girl{
    right: 18%;
    left : unset;
    transform: scalex(-1);
  }
}
    /***Yoga Layout**/
    .header{
      .yoga-content{
        .girl{
          transform: scalex(-1);
        }
      }
    }
    .yoga{
      .meter{
        .lorem{
          padding-right: 20px;
          padding-left : unset;
        }
        .percent{
          left: 20px;
          right:unset;
        }
      }
      &.format{

        .experience-container{
          .exp-img{
            transform: scalex(-1);
          }
        }
        .girl-img-container{
          .girl-yoga{
            transform: scalex(-1);
            right:22%;
            left:unset;
          }
        }
      }
      &.event{
        .animated-bg{
          i{
            right: unset;
            left: 0;
          }
        }
        .event-container{
          .event-info{
            margin-right: 20px;
          }
        }
      }
    }
    /*****Eccomerce layout**/
    .product-box {
      .product-detail{
        text-align: right;
        padding-right: 5px;
        .color-variant{
          li{
            margin-left: 5px;
            margin-right :unset;
          }
        }
      }
    }
    .layout-ecommerce{
      .ecommerce-footer{
        text-align: right;
        direction: rtl;
        .payment-card-bottom {
          text-align: left;
          ul{
            li+li{
              padding-right:10px;
              padding-left  :unset;
            }
          }
        }
        img{
          transform: scalex(-1);
        }
      }
    }
    .down-banner,.parallax-banner{
      .banner-contain{
       left: 10%;
     }
   }

   .product-pagination{
    .product-search-count-bottom{
      padding-left: 15px;
      padding-right:unset;
    }
  }
    /*****portfolio layout**/
    .collection-wrapper{
      .theme-card{
        .offer-slider{
          img {
            padding: 15px 0px 15px 15px;
          }
          .media{
            .media-body{
              &.ml-3{
                margin-right: 1rem !important;
              }
              a {
                h6{
                  margin-left: 61px;
                  margin-right : unset
                }
              }
              .rating{
                i{
                  padding-left: 5px;
                }
              }
            }
          }
        }
      }
      .image-swatch {
        li{
          img{
            margin-left: 5px;
          }
        }
      }
    }
    .portfolio-creative {
      .head-text{
        &:before {
          right: 50%;
          left  :unset;
        }
      }
      &.creative3{
        .portfolio-text{
          .head-text{
            &:before{
              right: 0;
              left : unset;
            }
          }
        }
      }
    }
    /*****Typography layout**/
    .typography_section{
      .typography-box{
        .typo-content{
          &.input_button{
            label {
              padding-right: 5px;
            }
          }
        }
      }
    }
    .mr-2{
      margin-left: 0.5rem !important;
    }
    .m-r-10 {
      margin-left: 10px;
    }
    .alert{
      .fa {
        &.m-r-10 {
          margin-left: 10px;
        }
      }
    }
    .copyright{
      .link-horizontal{
        &.social-link{
          ul{
            li{
              padding-left: 45px;
              padding-right:unset;
            }
          }
        }
        img{
          transform:scalex(-1);
        }
      }
    }
    .product-right{
      h4{
        span {
          padding-right: 5px;
        }
      }
      .color-variant{
        li {
          margin-left: 5px;
          margin-right : unset;
        }
      }
      .product-description{
        h6{
          span {
            float: left;
          }
        }
        .qty-box{
          .input-group{
            .form-control{
              border-left: none;

            }
            button {
              padding-right: 12px;

            }
          }
        }
      }
      .size-box{
        ul{
          li{
            margin-left: 10px;
            margin-right : unset;
          }
        }
      }
      .product-buttons{
        a{
          &:last-child{
            margin-right: 10px;
          }
        }
      }
      .product-icon{
        .wishlist-btn{
          i{
            margin-right: 5px;
            margin-left:unset;
            border-right: 1px solid #ddd;
            border-left:unset;
            padding-right: 10px;
            padding-left : unset;
          }
          span {
            padding-right: 10px;
            padding-left  :unset;
          }
        }
        .product-social{
          li{
            padding-right: 30px;

            &:last-child{
              padding-left: 0;
            }
          }
        }
      }
      .payment-card-bottom{
        ul {
          li{
            padding-left: 10px;
            padding-right : unset;
          }
        }
      }
    }
    .qty-box{
      .input-group{
        .input-group-prepend{
          margin-left : -1px;
          .btn{
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
    }
    .collection-filter-block{
      .product-service{
        .media{
          .media-body{
            padding-right: 10px;
            padding-left  :unset;
          }
        }
      }
    }
    .bundle{
      .theme_checkbox{
        label{
          padding-right: 30px;
          padding-left  :unset;
          .checkmark{
            right: 0;
            left : unset;
          }
        }
      }
      .bundle_detail{
        .price_product{
          padding-right: 3px;
          padding-left  :unset;
        }
      }
    }
    .cart-section {
      direction  :rtl;
      tfoot{
        tr{
          td{
            text-align: left;
            padding-left: 63px;
            &:first-child{
              padding-right: 10px;
            }
          }
        }
      }
      .cart-buttons{
        > div {
          &:last-child {
            text-align: left;
            padding-left: 59px;
          }
        }
      }
    }
    .payment-box {
      .payment-options{
        li {
          .radio-option{
            input[type="radio"] {
              right: 0;
              left  :unset;
            }
            label{
              padding-right: 30px;
              padding-left  :unset;
            }
          }
        }
      }
    }
    .owl-carousel {
      .owl-stage-outer{
        direction: ltr;
      }
    }
    /******* subscribe section : ***/
    .subscribe {
      .form-group {
        input{
          padding: 30px 35px 30px 35%;
        }
      }
      .button-primary{
        left: 4%;
        right : unset;
      }
      &.subscribe-2{
        .form-group {
          .icon {
            right: 50px;
            left:unset;
          }
          input{
            padding-right: 110px;
            padding-left : unset;
          }
        }
      }
      &.yoga{
        .button-primary{
          left: 28px;
          right:unset;
        }
      }
      &.wedding{
        .button-primary{
          button{
            margin-left: 10px;
            margin-right:unset;
          }
        }
      }
    }
    /******* Testimonial section : ***/
    .testimonial{
      .testimonial-box{
        &:before {
          right: 12%;
          left:unset;
        }
      }
      .testimonial-slider {
        .owl-theme{
          .owl-dots{
            left: 0;
            right :unset;
          }
        }
        .owl-carousel{
          .owl-item {
            img {
              transform: scalex(-1);
            }
          }
        }
      }
      .testi-profile {
        img{
          padding-right: 25px;
          padding-left : unset;
          margin-left: 20px;
          margin-right:unset;
        }
      }
      &.event,&.saas2{
        .testimonial{
          &.owl-theme{
            .owl-prev{
              right:0;
              left:unset;
            }
            .owl-next{
              left: 0;
              right:unset;
            }
          }
          .quotes{
            .right-quote{
              left: 80px;
              right:unset;
            }
            .left-quote{
              right: -33px;
              left:unset;
            }
          }
        }
        img{
          transform: scaleX(-1);
        }
        .rating{
          .stars{
            margin-left: 25px;
            margin-right:unset;
          }
        }
      }
    }
    .compare-page{
      .table-wrapper{
        .table {
          thead{
            .th-compare{
              th{
                .remove-compare{
                  float: right;
                }
              }
            }
          }
        }
      }
    }
    .checkout-page{
      .checkout-form {
        select{
          padding: 0 55px;
        }
      }
    }
    .compare-section{
      .item{
        .close-btn{
          left: 0;
          right:unset;
        }
      }
    }
    .coming-soon{
      direction  :rtl;
      .center-content{
        button{
          margin-right: -50px;
        }
      }
    }
    .icon-collection{
      direction  :rtl;
    }
    .text-right{
      text-align: left !important;
    }
    .m-l-5{
      margin-right: 5px;
    }
    .mr-3{
      margin-left: 1rem !important;
    }
    .event{
      &.contact{
        .iframe-container{
          border-radius: 0 15px 15px 0;
        }
        .bg-white{
          border-radius: 15px 0 0 15px;
        }
      }
    }
    .m-r-25{
      margin-left: 25px;
      margin-right:unset;
    }
    .blog{
      .img-container{
        .blog-info{
          direction: rtl;
        }
      }
    }
    .speaker{
      .team-img{
        .social{
          ul{
            li{
              margin-left : 20px;
              margin-right:unset;
              &:last-child{
                margin-left: 0;
                margin-right:unset;
              }
            }
          }
        }
      }
    }
    .schedule{
      .event-container{
        padding-left: 60px;
        padding-right:unset;
      }
    }
    .breadcrumb-section-main{
      .breadcrumb-contain{
        >div{
          &:last-child{
            text-align: left;
          }
          ul{
            li{
              margin-left: 15px;
              margin-right:unset;
              &:last-child{
                margin-left: 0;
                margin-right:unset;
              }
            }
          }
        }
      }
    }
    .collection-product-wrapper{
      .product-top-filter{
        .product-filter-content{
          .search-count,.sidebar-popup{
           border-left: 1px solid #ddd;
           border-right:unset
         }
       }
       .popup-filter{
         .search-count,.sidebar-popup{
           border-left: 1px solid #ddd;
           border-right:unset
         }
       }
     }
   }
   .collection-product-wrapper{
    .product-top-filter{
     .product-filter-content,.popup-filter{
      .product-page-per-view,.product-page-filter{
        select{
          border-left: 1px solid #ddd;
          border-right:unset;
        }
      }
    }

  }
}
.collection-product-wrapper{
  .product-top-filter{
    .product-filter-content,.popup-filter{
     .collection-grid-view{
       border-left: 1px solid #ddd;
       border-right:unset;
     }
   }
 }
}
.collection-product-wrapper{
  .product-top-filter{
    .product-filter-content,.popup-filter{
      .product-page-filter{
        select{
         border-left:none;
         border-right:unset;
       }
     }
   }
 }
}
.agency{
  &.testimonial{
    .testimonial-container{
      &.testimonial-container2{
        .d-flex{
          .m-r-25{
            margin-left : unset;
            margin-right: 25px !important;
          }
        }
      }
    }
  }
}
.owl-theme{
  .owl-nav{
    &.disabled{
      +.owl-dots{
        left: 0;
        margin-left:unset;
      }
    }
  }
}
    /* responsive*/
    @media only screen and (max-width: 1199px) and (min-width: 992px){
      header{
        &.agency{
          nav{
            ul{
              li{
                .dropdown{
                  color: #fff;
                }
              }
            }
          }
        }
      }
      section{
        &.services{
          .offset-lg-3{
            margin-right: 25%;
            margin-left : unset;
          }
        }

      }
    }
    @media only screen and (max-width: 991px) and (min-width: 576px){
      .resume{
        .counter-container{
          +.counter-container{
            border-right: 1px solid;
            border-left : unset;
            +.counter-container{
              border-right: 0;
              border-left : unset;
              +.counter-container{
                border-right: 1px solid;
                border-left : unset;
              }
            }
          }
        }
      }
    }
    @media only screen and (max-width: 1600px){
      .header{
        .wedding-content{
          .girl{
            right:16%;
            left : unset;
          }
        }
      }
      .about{
        &.app1{
          .mobile{
            left: 6%;
            right:unset;
          }
        }
      }
      .saas2{
        .set-center-div{
          .side-img{
            left: -14%;
            right:unset;
          }
        }
      }
    }
    @media only screen and (max-width: 480px){
      .subscribe{
        &.subscribe-2{
          .form-group{
            input{
              padding-right: 95px;
              padding-left : unset;
            }
          }
        }
      }
    }
    @media only screen and (max-width: 1430px){
      .collection-product-wrapper{
        .product-top-filter{
          .product-filter-content{
            .search-count{
              border-right: 1px solid #ddd;
              border-left:1px solid #ddd;
                    // border-left : unset;
                  }
                  .collection-view{
                    padding-left: 0 !important;
                    padding-right:unset !important;
                    border-right: 1px solid #ddd;
                    border-left:unset;
                  }
                  .collection-grid-view{
                    padding-right: 0 !important;
                    padding-left :unset !important;
                  }
                  .product-page-filter{
                    border-left: 1px solid #ddd;
                    border-right:unset;
                  }
                }
              }
            }
          }
          @media only screen and (max-width: 1366px){
            .header{
              .wedding-content{
                .girl{
                  right:11%;
                  left:unset;
                }
              }
              .rotate{
                left:15%;
                right:unset;
              }
              &.agency{
                .center-text{
                  margin-right: 50px;
                  margin-left : unset;
                }
              }
            }
            .agency{
              &.agency-content{
                .blue-img{
                  right: -18%;
                  left : unset;
                }
              }
            }
            .about{
              &.app1{
                .mobile.galaxy{
                  left: -12%;
                  right:unset;
                }
              }
            }
            .saas2{
              .set-center-div{
                .side-img{
                  left: 2%;
                  right:unset;
                }
              }
            }
            .collection-product-wrapper{
              .product-top-filter{
                .product-filter-content{
                  .search-count{
                    border-right: 1px solid #ddd;
                    
                  }
                }
              }
            }
          }
          @media only screen and (min-width: 1200px){
            .offset-xl-3 {
              margin-right: 25%;
              margin-left: unset;
            }
            .offset-xl-1 {
              margin-right: 8.3333333333%;
              margin-left : unset;
            }
            .offset-xl-2 {
              margin-right: 16.6666666667%;
              margin-left : unset;
            }
            section{
              &.wedding{
                .offset-xl-2{
                  margin-right: 16.6666666667%;
                  margin-left : unset;
                }
              }
            }
          }
          @media only screen and (max-width: 1199px){
            .agency{
              &.agency-content{
                .blue-img{
                  right: -20%;
                  left : unset;
                }
              }
            }
            .copyright{
              .link-horizontal{
                ul{
                  li{
                    padding-left : 20px;
                    padding-right: unset;
                  }
                }
              }
            }
            .header{
              .wedding-content{
                .girl{
                  right: 6%;
                  left:unset;
                }
              }
              .rotate{
                left : 12%;
                right:unset;
              }
            }
          }
          @media only screen and (min-width: 992px){
            .offset-lg-1 {
              margin-right: 8.3333333333%;
              margin-left:unset;
            }
            .offset-lg-2 {
              margin-right: 16.6666666667%;
              margin-left : unset;
            }
            .offset-lg-3 {
              margin-right: 25% !important;
              margin-left : unset;
            }
            .offset-lg-7 {
              margin-right: 58.3333333333%;
              margin-left : unset;
            }
            .offset-lg-0{
              margin-right: 0;
              margin-left : unset;
            }
            header{
              nav{
                ul{
                  .dropdown{
                    &:before{
                      left: 0;
                      right:unset;
                    }
                  }
                }
              }
            }
            section{
              &.app1{
                &.about{
                  .offset-lg-3{
                    margin-right: 25%;
                    margin-left : unset;
                  }
                }
              }
              &.saas2{
                &.testimonial{
                  .item{
                   .offset-lg-1{
                    margin-left: 8.3333333333%;
                  }
                }
              }
            }
          }
        }
        @media only screen and (max-width: 991px){

          .agency{
            &.header{
              .center-text{
                margin-right: 20px;
                margin-left : unset;
              }
            }
            &.agency-content{
              .blue-img{
                right:-25%;
                left :unset;
              }
            }
            &.blog{
              .m-r-25{
                margin-left: 15px;
                margin-right:unset;
              }
              .m-r-10{
                margin-left : 5px;
                margin-right:unset;
              }
            }
            &.footer2 {
              .link-horizontal{
                ul{
                  li{
                    padding-left: 5px;
                    padding-right:unset;
                  }
                }
              }
            }
          }
          header{
            .navbar{
              background-color: #fff !important;
            }
            nav{
              ul{
                li{
                  a{
                    text-align : right;
                  }
                }
              }
            }

            &.dark,&.resume{
              .responsive-btn{
                i{
                 color:#1f232c !important;
               }
             }
           }
           &.yoga{
            .logo-abs{
              right: 0;
              left:unset;
            }
          }
          &.ecommerce{
            .logo-erapper{
              ~ .responsive-btn{
                left: 0;
                right:unset;
              }
            }
            nav{
              .top-header-right{
                margin-left: 40px;
                margin-right:unset;
              }
            }
          }
        }
        .copyright{
          .link-horizontal{
            ul{
              li{
                padding-left: 10px;
                padding-right :unset ;
              }
            }
          }
        }
        .saas1{
          &.subscribe{
            .media{
              img{
                margin-left: auto;
              }
            }
          }
        }
        .header{
          .rotate{
            left:12%;
            right:unset;
          }
        }
        .schedule{
          .event-container{
            padding-left: 30px;
            padding-right:unset;
          }
        }
        .collection-product-wrapper{
          .product-top-filter{
            .product-filter-content{
              .product-page-per-view{
               select{
                 border-right:1px solid #ddd !important;
               }
             }
           }
         }
       }
       .collection-product-wrapper{
         .product-top-filter{
           .product-filter-content{
             .product-page-per-view,.product-page-filter{
               select{
                 border-right: 1px solid #ddd;
                 border-left:unset;
               }
             }
           }
         }
       }
       .gym{
        .calculate-bmi{
          tr{
            td{
              padding: 15px 40px 15px 0px
            }
          }
        }
      }
    }
    @media only screen and (min-width: 768px){
      .offset-md-3 {
        margin-right: 25%;
        margin-left:unset;
      }
      section{
        &.resume{
          .offset-md-2{
            margin-right: 16.6666666667%;
            margin-left : unset;
          }
        }
        &.gym{
          &.pricing{
            .offset-md-1{
              margin-right: 8.3333333333%;
              margin-left : unset;
            }
          }
          &.format{
            .offset-md-2{
              margin-right: 16.6666666667%;
              margin-left : unset;
            }
          }
        }
        &.music{

          .offset-md-1{
            margin-right: 8.3333333333%;
            margin-left : unset;
          }

          &.testimonial{
            .offset-md-2{
              margin-right: 16.6666666667%;
              margin-left : unset;
            }
          }
          &.header{
            .music-container{
              .offset-md-1 {
               margin-left: 8.3333333333%;
             }
           }
         }
       }
       &.saas2{
        .offset-md-1{
          margin-right: 8.3333333333%;
          margin-left:unset;
        }
      }
      &.wedding{
        .offset-md-2{
          margin-right: 16.6666666667%;
          margin-left : unset;
        }
        &.subscribe{
          .offset-md-2{
            margin-right: 25%;
            margin-left: unset;
          }
        }
      }
      &.yoga{
        .offset-md-2{
          margin-right: 16.6666666667%;
          margin-left : unset;
        }
        &.pricing{
          .offset-md-1{
            margin-right: 8.3333333333%;
            margin-left : unset;
          }
        }
      }
      &.app2{
        .offset-md-1{
          margin-right: 8.3333333333%;
          margin-left:unset;
        }
      }
      &.app1{
        .offset-md-2{
          margin-right: 16.6666666667%;
          margin-left : unset;
        }
        &.pricing,&.screenshot{
          .offset-md-2{
            margin-right: 25%;
            margin-left: unset;
          }
        }
      }
      &.event{
        .offset-md-1{
          margin-right: 8.3333333333%;
          margin-left : unset;
        }
        &.header{
          .offset-md-1{
            margin-right: 25%;
            margin-left: unset;
          }
        }
      }
      &.format{
        .offset-md-1{
          margin-right: 8.3333333333%;
          margin-left:unset;
        }
      }
      &.testimonial{
        .offset-md-2{
          margin-right: 16.6666666667%;
          margin-left :unset;
        }
      }
      &.ecommerce,&.ecommerce-tab,&.bg-light{
        .offset-md-2{
          margin-right: 16.6666666667%;
          margin-left :unset;
        }
      }
    }
    .offset-md-0{
      margin-right:0;
      margin-left:unset;
    }
  }
  @media only screen and (max-width: 767px){
    header{
      .responsive-btn{
        i{
          color: #fff !important;
        }
      }
      &.agency{
        .responsive-btn{
          i{
            color: #fff !important
          }
        }
      }
      &.ecommerce{
        .responsive-btn{
          i{
            color: #000 !important;
          }
        }
      }
    }
    .link-horizontal{
      ul{
        li{
          padding-left: 15px;
          padding-right:unset;
        }
      }
    }
    .counter-container{
      +.counter-container{
        border-right: 1px solid #ddd;
        border-left : unset ;
        + .counter-container{
          border-right: 0;
          border-left : unset;
          + .counter-container{
            border-right: 1px solid #ddd;
            border-left : unset;
          }
        }
      }

    }
    .event{
      &.testimonial{
        .testimonial{
          &.owl-theme{
            .owl-nav{
              .owl-prev{
                right: unset;
                margin-left: 20px;
                margin-right:unset;
              }
            }
          }
        }
      }
    }
    .ecommerce-footer{
      .col-md-12{
        text-align: right;
      }
    }
    footer {
      .footer-title{
        text-align: right;
        .according-menu{
          &:before{
            left: 2px;
            right:unset;
          }
        }
        &.active{
          .according-menu{
            &:before{
              left:2px;
              right:unset;
            }
          }
        }
      }

    }
    .agency{
      &.header{
        .center-text{
          margin-right:0;
          margin-left:unset;
        }
      }
      &.speaker{
        .team-img{
          .social{
            ul{
              li{
                margin-left: 10px;
                margin-right:unset;
              }
            }
          }
        }
      }
    }
    .schedule{
      .event-container{
        padding-left: 20px;
        padding-right:unset;
      }
    }
    .gym{
      .counter-container{
        +.counter-container{
                      // border-right: 1px solid #ddd;
                      border-left : unset;
                    }
                  }
                }
                .breadcrumb-section-main{
                  &.inner-2{
                    .breadcrumb-contain{
                      ul{
                        li{
                          a{
                            i{
                              margin-left: 10px;
                              margin-right:unset;
                            }
                          }
                        }
                      }
                    }
                  }                  
                  .breadcrumb-contain{
                    >div{
                      ul{
                        li{
                          a{
                            i{
                              margin-left: 10px;
                              margin-right:unset;
                            }
                          }
                        }
                      }
                    }
                  }
                }
                .yoga{
                  &.format{
                    .girl-img-container{
                      .girl-yoga{
                        right:unset;
                      }
                    }
                  }
                }
                .subscribe{
                  .button-primary{
                    justify-content: center;
                  }
                }
              }
              @media only screen and (max-width: 575px){
                .about{
                  &.app1{
                    .counters{
                      border-left: unset;
                    }
                  }
                }
                .event{
                  .wedding-time{
                    .row{
                      & >div{
                        &:nth-child(even){
                          .count{
                            margin-left: auto;
                            margin-right:unset;
                          }
                        }
                        &:nth-child(odd){
                          .count{
                            margin-right: auto;
                            margin-left : unset;
                          }
                        }
                      }
                    }
                  }
                }
                .copyright{
                  .link-horizontal{
                    ul{
                      li{
                        padding-left: 15px;
                        padding-right:unset;
                      }
                    }
                  }
                }
                .subscribe{
                  .form-group {
                    input{
                      padding-right: 15px;
                      padding-left:unset;
                    }
                  }
                }
                .collection-product-wrapper{
                  .product-top-filter{
                    .product-filter-content{
                      .product-page-per-view{
                       select{
                        border-left: 1px solid #ddd;
                        border-right:unset;

                      }
                    }
                  }
                }
              }
            }
            @media (min-width: 576px){
              section{
                &.wedding{
                  .wedding-time{
                    .offset-sm-2{
              // margin-right: 16.6666666667%;
              // margin-left : unset;
            }
          }
        }
      }
    }

  }

